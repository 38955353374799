import axios from 'axios';
//import * as SecureStore from 'expo-secure-store';
import { AsyncStorage } from 'AsyncStorage';
// import * as Notifications from 'expo-notifications';
// import * as Permissions from 'expo-permissions';
// import { Alert, Platform } from 'react-native';
// import Constants from 'expo-constants';
import BASE_URL from './apipath'
import { getToken, onMessageListener } from '../firebase';
// import { getAllBadge } from '../store/actions/home';
// import { HomeService } from '../services';
// import { connect } from 'react-redux';
// import store from '../../src/store/store';


import { User } from '../models';

const axiosInstance = axios.create({
    // baseURL: 'https://mespoke.cloud/api/'
    baseURL: BASE_URL
});
axiosInstance.interceptors.request.use((config) => {
    let partner = localStorage.getItem("partner")
    if(partner != null && partner != "web"){
        config.url = config.url + "?partner=" + partner+"_db"
        // redirect to 404 if title does not exist
        //config.url = config.url + "?partner=" + partner+"_db"
    }
    return config
 });

export default class AuthService {

    static username = null;
    static fullname = null;
    static password = null;
    static accessToken = null;
    static generatedCode = null;
    static partnerName = null;
    
    static async isLocalDomainPartnerEqual(){        
        let responce = false
        let getPartner = window.location.hostname;
        let partner = getPartner.split('.');
            var index = partner.indexOf("www");
            if (index > -1) {
                partner.splice(index, 1);
            }
        let storepartner = localStorage.getItem("partner")
        if(storepartner != null && storepartner == partner){
            responce = true
        }
        return responce;
    }
    static async isDomainPartnerEqual(selectedpartner){
        let responce = false
        let getPartner = window.location.hostname;
        let partner = getPartner.split('.');
            var index = partner.indexOf("www");
            if (index > -1) {
                partner.splice(index, 1);
            }       
        if(selectedpartner == partner){
            responce = true
        }
        return responce;
    }

    static async loadConfigurations(partnerName) {
        this.partnerName = partnerName;
     
        let fd = new FormData();
        fd.append("Name", partnerName);
        return axiosInstance.post("/partneradmin/getConfigs.php", fd);
    }
    static async checkuserpartner(userid){
        const axiosins = axios.create({            
            baseURL: BASE_URL
        });
       return  axiosins.get('login/checkuser.php', {
            params: {
                userid: userid                
            },
        });        
    }
    static async getAllPartners(){
        const axiosins = axios.create({            
            baseURL: BASE_URL
        });
       return  axiosins.get('partneradmin/getAllPartners.php');        
    }
    static async login(username, password) {        
        let partnerResponse = await this.checkuserpartner(username);
        debugger
        let userPartner = partnerResponse.data[0].partner 
        if(userPartner != undefined && userPartner != null && userPartner != ""){
            let partner = localStorage.getItem("partner") 
            if(userPartner != partner){
                localStorage.setItem("partner", userPartner);
            }                      
            let responce = axiosInstance.get('/login/index_updated.php', {
                params: {
                    Username: username,
                    Password: password
                },
            });       
           return responce
        }
        else{
            return false;
        }
        
    }

    static async forgotPassword(username) {
        this.username = username;
        this.generatedCode = this._getRandomIntInclusive(1000, 9999);

        let fd = new FormData();
        fd.append("8937", username);
        fd.append("8999", this.generatedCode);

        return axiosInstance.post('/login/verifyUpdated.php', fd);
    }

    static async resetPassword(newPassword) {
        return axiosInstance.get(`/login/reset/index.php`, {
            params: {
                new: newPassword,
                phone: this.username
            }
        });
    }

    static async verifyRegister(username, fullname, password) {
        this.username = username;
        this.fullname = fullname;
        this.password = password;
        this.generatedCode = this._getRandomIntInclusive(1000, 9999);

        let fd = new FormData();
        fd.append("8937", username);
        fd.append("8999", this.generatedCode);

        const axiosins = axios.create({            
            baseURL: BASE_URL
        });       
        return axiosins.post('/login/verifyRegisterUpdated.php', fd);
    }

    static async register(partner) {
        const axiosins = axios.create({            
            baseURL: BASE_URL
        });
        return axiosins.get('/login/register/index_register.php', {
            params: {
                phone: this.username,
                pass: this.password,
                fname: this.fullname,
                partner: partner
            }
        });
    }

    static validateClientCode(code) {
          
        return code === this.generatedCode;
    }

    static async setUser(accessToken, ProfileId, username, fullname, photoURL) {               
        const device_token = await this.setDeviceToken(ProfileId);       
        await AsyncStorage.setItem('user', JSON.stringify(new User(ProfileId, username, fullname, photoURL, device_token)));
        return AsyncStorage.setItem("accessToken", accessToken);
    }

    static async customLogger(message) {
        let fd = new FormData();
        fd.append("message", message);
        return axiosInstance.post('/admin/logger.php', fd);
    }
    // will be implemented at a later time
    // static addNotificationListeners() {

    //     Notifications.setNotificationHandler({
    //         handleNotification: async () => ({
    //           shouldShowAlert: true,
    //           shouldPlaySound: false,
    //           shouldSetBadge: false,
    //         }),
    //       });

         

    //     // This listener is fired whenever a notification is received while the app is foregrounded
    //     Notifications.addNotificationReceivedListener(notification => {
    //         let badgeNo = Notifications.getBadgeCountAsync();
    //         // setNotification(notification);
    //     });

    //     // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    //     // Notifications.addNotificationResponseReceivedListener(response => {
    //     //     // console.log(response);
    //     // });
    // }

    static async setDeviceToken(ProfileId) {
        try {
            // await this.customLogger('Title: First Step, Message: Started');
            const device_token = await this.registerForPushNotificationsAsync()           
            if(!device_token){
                 return
            }
            let os_type = "web"
             //this.addNotificationListeners()
             //await this.customLogger(`Title: Device Token, Message: ${device_token}`)            
             //await this.customLogger(`Title: OS TYPE, OS: ${os_type}`)
            let fd = new FormData();
            fd.append("user_id", ProfileId);
            fd.append("device_token", device_token);
            fd.append("os_type", os_type);
            let response = await axiosInstance.post('/notifications/setdevicetoken.php', fd);
            return device_token;
        } catch (e) {
            // await this.customLogger(`Title: Catch One, Error: ${JSON.stringify(e)}`)
            console.log(e);

            //Retry logic here
            return false
        }
    }

    static async registerForPushNotificationsAsync() {
        try{
            let token;
            token =  getToken()
            return token;

        } catch (e) {
            // await this.customLogger(`Title: Catch Two, Error: ${JSON.stringify(e)}`)
            //Retry logic here
            return false
        }
      }

    static async unsetDeviceToken() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let fd = new FormData();
            fd.append("user_id", user.ProfileId);
            fd.append("device_token", user.device_token);
            let response = await axiosInstance.post('/notifications/unsetdevicetoken.php', fd);
            return response;
        } catch (e) {
            console.log(e);

            //Retry logic here
            return false
        }
    }

    static async isUserSet() {
        try {        
            const user = await AsyncStorage.getItem('user')
            return user !== (null || undefined);
        } catch (e) {
            return false
        }
    }

    static async logout() {
          
        await this.unsetDeviceToken();
        //await AsyncStorage.removeItem("user");
        //return //AsyncStorage.removeItem("accessToken");
        localStorage.removeItem("@AsyncStorage:user")
        localStorage.removeItem("partner")
        localStorage.removeItem("PartnerID")
        return localStorage.removeItem("@AsyncStorage:accessToken")
    }

    //Helper Functions
    static _getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive 
    }

}
