import React, { Component } from 'react'
import UserService from "../services/user";

export class RewardScreen extends Component {
    constructor() {
        super();
        this.state = {
            points: '',
            userName: '',
            validUpto: 'December 31, 2028',
           };
    }
   async componentDidMount(){
        this._loadUserProfile();
       
    }

    _loadUserProfile = () => {
        UserService.getUserProfile().then(response => {
            if (response.status === 200 && !response.data.error) {
                this.setState({...this.state,
                  userName: response.data[0].ProfileName,
                  points: response.data[0].Points,
                });
               // console.log("response is--", response)
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    render() {
        return (
           
            <div className ="container">                        
                <div className="row">
                   <div className="column" style={{color:"grey", fontSize:25}}>
                    Scan and Redeem
                   </div>
                </div>
                <div className="row">
                   <div className="column">
                   <img className="qrImage" 
                 src={`${process.env.PUBLIC_URL}/images/qrThumbnail.png`} 
                  alt="logo"
                 style={{width:"48%", height:"100%"}}/>
                   </div>
                </div>
                <div className="row">
                   <div className="column" style={{fontSize:30, fontWeight:"bold", color:"black"}}>
                   {this.state.points}
                   </div>
                </div>
                <div className="row">
                   <div className="column" style={{color:"grey"}}>
                   Points
                   </div>
                </div>
                <div className="row justify-content-md-start" style={{color:"black", marginLeft:20}} >
                     Name
                   </div>
                   <div className="row justify-content-md-start" style ={{color:"grey", marginLeft:20}}>
                     {this.state.userName}
                   </div>
                   <div className="row justify-content-md-start" style={{marginTop:"2%" ,color:"black", marginLeft:20}} >
                     Valid Until
                   </div>
                   <div className="row justify-content-md-start" style ={{color:"grey", marginLeft:20}}>
                     {this.state.validUpto}
                   </div>
            </div>
          
        )
    }
}

export default RewardScreen

