import axios from 'axios';
import BASE_URL from './apipath'
import { AsyncStorage }  from 'AsyncStorage';


const axiosInstance = axios.create({
    baseURL: BASE_URL
});

const axiosInstance2 = axios.create({
    baseURL: 'https://autocomplete.clearbit.com/v1'
});
axiosInstance.interceptors.request.use((config) => {
    let partner = localStorage.getItem("partner")
    if(partner != null && partner != "web"){
        config.url = config.url + "?partner=" + partner+"_db"
        // redirect to 404 if title does not exist
        //config.url = config.url + "?partner=" + partner+"_db"
    }
    return config
 });
 
export default class DiscoverService {

    static async getPostList(pageNo) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/index_updated.php", {
                params: {
                    requestingProfileId: user.ProfileId,
                    page: pageNo
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    static async getMainCategoryList() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/bybrandmaincat.php", {
                params: {
                    search: "",
                    requestingProfileId: user.ProfileId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getSubCategoryList(mainTypeId, searchText) {
        try {
              
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/bybrandcat.php", {
                params: {
                    search: searchText,
                    requestingProfileId: user.ProfileId,
                    type: 2,
                    maintype: mainTypeId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getBrandList(searchText) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/bybrandcat_updated.php", {
                params: {
                    search: searchText,
                    requestingProfileId: user.ProfileId,
                    type: 1,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getBrandLogo(brandName) {
        try {
            return axiosInstance2.get("/companies/suggest", {
                params: {
                    query: brandName.replace(" ", "%20"),
                    size: 200,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getBrandLogoAsync(brandName) {
        try {
            let brandLogosCache = {};
            const brandLogoesCacheString = await AsyncStorage.getItem('brandLogosCache');
            if (brandLogoesCacheString) {
                brandLogosCache = JSON.parse(brandLogoesCacheString);
            }
            const BN = brandName
            brandName = brandName.toLowerCase().replace(" ", "%20");

            if (brandLogosCache[brandName]) {
                return brandLogosCache[brandName];
            }

            const response = await axiosInstance.get("/brands/getclearbitbrand.php", {
                params: {
                    brand_name: BN
                }
            });
            if(response.data.length > 0) {
                brandLogosCache[brandName] = response.data[0].logo;
                await AsyncStorage.setItem('brandLogosCache', JSON.stringify(brandLogosCache));
            } else {
                brandLogosCache[brandName] = "/images/defaultImage.jpg";
            }


            return brandLogosCache[brandName];
            
        } catch (e) {
            console.log(e);
        }
    }

    static async getBrandProfilePosts(idBrand, pageNo) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/closet/filter/noId.php", {
                params: {
                    page: pageNo,
                    ratingProfileId: user.ProfileId,
                    idCB: idBrand,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getBrandProfileDetails(idBrand) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("brands/getId.php", {
                params: {
                    page: 1,
                    ratingProfileId: user.ProfileId,
                    idCB: idBrand,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async toggleBrandFollowingStatus(idBrand) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/brands/follower/index.php", {
                params: {
                    page: 1,
                    requestingProfileId: user.ProfileId,
                    brandId: idBrand,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    
    static async getTopUsers() {
        try {
          

            return axiosInstance.get("/admin/gettopusers.php", {
            
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getTrendingBrands() {	
        try {	
          	
            return axiosInstance.get("/admin/gettrendingbrands.php", {	
             	
            })	
        } catch (e) {	
            console.log(e);	
        }	
    }

}