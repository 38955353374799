import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
class testpage extends React.Component {
    constructor() {
        super();
        this.state = {
          user:"",
          userpost:"",
          brandsImages:[]
        };
    }
    async componentDidMount() {
    
    }
    
    render() {
        const responsive = {
            desktop: {
              breakpoint: { max: 2048, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
          }
        return (
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              customButtonGroup={<CustomButtonGroup />}
              containerClass="carousel-container"
              arrows={false}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              >
             <div style={{marginTop:"20px", marginBottom:"60px"}}>   
              <img className="onBoard" 
                 src={`${process.env.PUBLIC_URL}/images/onBoarding1.png`} 
                  alt="..."
                 style={{width:"30%", height:"520px"}}/>
              </div>
             <div style={{marginTop:"20px"}}> 
              <img className="onBoard" 
                 src={`${process.env.PUBLIC_URL}/images/onBoarding2.png`} 
                  alt="..."
                 style={{width:"30%", height:"520px"}}/>
              </div>
              <div style={{marginTop:"20px"}}><img className="onBoard" 
                 src={`${process.env.PUBLIC_URL}/images/onBoarding3.png`} 
                  alt="..."
                 style={{width:"30%", height:"520px"}}/>
              </div>
            </Carousel>

        )
    }
}

const CustomButtonGroup = ({ next, previous, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group" style={{  position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent:"center",
    right:0}} >
      <div style={{marginRight:"20px"}}>
      <button className="btn" onClick={() => previous()} style={{backgroundColor:"lightgrey"}}>Previous slide</button>
      </div>
      <div>
      <button className="btn" onClick={() => next()} style={{backgroundColor:"lightgrey"}} >Next slide</button>
      </div>
    </div>
  );
};


export default testpage