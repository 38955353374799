import axios from 'axios';
import { AsyncStorage }  from 'AsyncStorage';
import BASE_URL from './apipath'

const axiosInstance = axios.create({
    baseURL: BASE_URL
});
axiosInstance.interceptors.request.use((config) => {
    let partner = localStorage.getItem("partner")
    if(partner != null && partner != "web"){
        config.url = config.url + "?partner=" + partner+"_db"
        // redirect to 404 if title does not exist
        //config.url = config.url + "?partner=" + partner+"_db"
    }
    return config
 });
export default class NotificationService {

    static async getNotifications() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/notifications/index_updated.php", {
                params: {
                    "891": user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    
}