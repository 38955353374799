import React from 'react'
import UserService from '../../services/user'
import Loader from "react-loader-spinner";
import FlatList from 'flatlist-react';

class CategoriesScreen extends React.Component {
    constructor() {
        super();
        this.state = {          
            categoryList: []            
        }
    }
    _loadListOfCategory = () => {
        UserService.getTaggedCategoryList().then(response => {
            if (response.status === 200 && !response.data.error) {
                let taggedCategoryList = response.data.map(category => {
                    return {
                        id: category.Id,
                        categoryName: category.name,
                    };
                });
                this.setState({ ...this.state, categoryList:taggedCategoryList });   
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    handleCategoryClick = (category) => {
           this.props.closeModal()
            const { history } = this.props;
            history.push({
                pathname: '/categorydetail',
                state: { category: category.categoryName, categoryId: category.id }
            })
    }
    renderCategory = (category, idx) => {            
        return (            
            <div onClick={() => this.handleCategoryClick(category)} key={idx} className="border-bottom changeBackgroundColorOnHover align-self-center" style={{ cursor: "pointer" }}><div className="card-body">{category.categoryName}</div></div>
        );
    }

    renderCategories = (navigation) => {
        if(this.state.categoryList.length == 0){
            return ( 
                <div className="d-flex justify-content-center">
            <Loader type="Puff"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ marginTop: "10px" }}
        />
                </div>
                           )
        }
        else
        {
            return (               
                <FlatList
                    list={this.state.categoryList}
                    renderItem={this.renderCategory}
                    renderWhenEmpty={() => <Loader type="Puff" color="#00BFFF" height={50} width={50} className="d-flex justify-content-center" />}
                />
            )
    }
    }

    componentDidMount() {
        this._loadListOfCategory();        
    }

    render() {
        return (
            <div>                
                <div>{this.renderCategories()}</div>
            </div>            
        );
    }
}

export default CategoriesScreen