import React, { Component } from 'react';
import './Stylesheets/Login.css'
import './Stylesheets/common.css'
import { Nav, Tab, } from 'react-bootstrap';
import PhoneNoComponent from '../Components/PhoneNoComponent'
import EmailComponent from '../Components/EmailComponent'
import materialTheme from '../theme/Theme'
import AuthService from '../services/auth'
import BASE_URL from "../services/apipath";
import { Link,Redirect } from "react-router-dom";
import HomeService from '../services/home'
import store from '../store/store';
import { getAllBadge } from '../store/actions/home';
class Signup extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            email: "",
            phoneNumber: "",
            password: "",
            isFormSubmitting: false,
            imageurl:""
        }
        //this.handle
        //Change = this.handleChange.bind(this);
    }    
    async componentDidMount() {
        let partner = localStorage.getItem("partner");
        AuthService.loadConfigurations(partner).then((response) => {
        ;
        if (response.data && response.data[0] && response.data[0].error) {
        } else {
        var config = response.data[0].Configuration;
        config = JSON.parse(config);
        var imgPath = `${BASE_URL}partneradmin/${config.logo}.png`;
        localStorage.setItem("color", config.color);
        localStorage.setItem("logo", imgPath);
        this.setState({ ...this.state, imageurl: imgPath })
        }
        });
        }
    //will be implemented at a later time
    // addNotificationListeners = () => {

    //     Notifications.setNotificationHandler({
    //         handleNotification: async () => ({
    //           shouldShowAlert: true,
    //           shouldPlaySound: false,
    //           shouldSetBadge: false,
    //         }),
    //       });
    //       Notifications.addNotificationReceivedListener(notification => {
    //         const finalStore = store.getStore();
    //         finalStore.dispatch(incrementBadgeNo())
    //     });
    //   }

    loggedInUser = () => {                                   
        AuthService.isLocalDomainPartnerEqual().then(result => {
            if(result){         
                const promise = new Promise((resolutionFunc, rejectionFunc) => {
                   resolutionFunc(HomeService.getNotificationBadge());
           });
            promise.then((response) => {
               if (response.status === 200) {
                let badgeCount = response.data.count_badge;
                const finalStore = store.getStore();
                finalStore.dispatch(getAllBadge(badgeCount))
                let check =this.props;             
                this.props.isLoggedIn(true)
   
              }        
           });
           }
          else
          {
            let storepartner = localStorage.getItem("partner");
           window.location = "https://"+storepartner + ".mespoke.com"
          }
        });
        
        
    }
    onPressSendCode = (event) => {
        event.preventDefault();
        // console.log(this.state.email)
        // console.log(this.state.fullName)
        // console.log(this.state.password)
        if (this.state.selectedIndex === 0 && !this._validateEmail(this.state.email)) {
            //this._showAlert("Hey!", "Enter a valid email address first.")
            window.triggerInputValidation("emailaddress", "Enter a valid email address first.")
            return;
        }
        else {
            window.triggerInputValidationSuccess("emailaddress")
        }

        if (this.state.selectedIndex === 1 && this.state.phoneNumber.localeCompare('') === 0) {
            //this._showAlert("Hey!", "Enter a valid phone number first.")
            window.triggerInputValidationPhone("Enter a valid phone number first.")
            return;
        }
        else {
            window.ttriggerInputValidationSuccessPhone()
        }
        if (this.state.password.localeCompare('') === 0) {
            //this._showAlert("Hey!", "Enter password first.")
            window.triggerInputValidation("password", "Enter password first.")
            return;
        }
        if (this.state.password.trim().length < 6) {
            //this._showAlert("Hey!", "A password must be at least 6 characters long.")
            window.triggerInputValidation("password", "A password must be at least 6 characters long.")
            return;
        }
        else {
            window.triggerInputValidationSuccess("password")
        }
        this.setState({ ...this.state, isFormSubmitting: true }, () => {
            const username = this.state.selectedIndex === 0 ? this.state.email : this.state.phoneNumber;            
            AuthService.login(username, this.state.password).then(response => {                     
                if (response.data && response.data[0] && response.data[0].error) {                    
                    this.setState({ ...this.state, isFormSubmitting: false })
                    setTimeout(() => {
                        this._showAlert("Error", response.data[0].error);
                    }, 200);
                } else if (response.status !== 200) {                    
                    this.setState({ ...this.state, isFormSubmitting: false })
                    setTimeout(() => {
                        this._showAlert("Error", "Login failed. Please try again.");
                    }, 200);
                } else if (response.status === 200 && response.data[0] && response.data[0].AccessToken) {                    
                    const user = response.data[0]
                    AuthService.setUser(user.AccessToken, user.ProfileId, username, user.ProfileName, user.PhotoURL)
                        .then(_ => this.loggedInUser())//.finally(_ => this.setState({ ...this.state, isFormSubmitting: false }));
                }
            }).catch(error => console.log(error));
        });
    }
    handleIndexChange = index => {
        this.setState({
            ...this.state,
            selectedIndex: Number(index)
        });
    };
    onEmailTextChange = (emailText) => {
        this.setState({
            ...this.state,
            email: emailText.target.value
        });
    }
    onPhoneTextChange = (value, data, event) => {        
        if (event.type == "click") {
            window.hidePhoneValidation()
        }
        if (value === data.dialCode && event.type != "click") {
            window.triggerInputValidationPhone("Enter a valid phone number first.")
            return
        }
        else {
            this.setState({
                ...this.state,
                //phoneNumber: dialCode.slice(1, dialCode.length) + unmaskedPhoneNumber
                phoneNumber: value
            });
        }

    }
    onPasswordTextChange = (passwordText) => {
        this.setState({
            ...this.state,
            password: passwordText.target.value
        });
    }
    _renderComponent = () => {
        if (this.state.selectedIndex == 0)
            return <EmailComponent onInputChange={this.onEmailTextChange} />
        else
            return <PhoneNoComponent onInputChange={this.onPhoneTextChange} />
    }
    _validateEmail = (inputText) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailformat)) {
            return true;
        }
        else {
            return false;
        }
    }
    _showAlert = (title, message) => {
        //alert(title + "-" + message);        
        window.showAlert(title, message)
    }
    render() {
        return (
            <div>
                <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="logindiv" className="contact">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 mx-auto" style={{ maxWidth: "500px" }}>
                                <img src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" +localStorage.getItem('logo')} className="img-fluid" alt="" style={{ marginBottom: "30px",marginTop:"20px",maxHeight: 40 }}></img>
                                <form className="php-email-form needs-validation" noValidate="" onSubmit={this.onPressSendCode} id="RegistrationForm" autoComplete="off" style={{paddingBottom:"20px"}}>
                                    <div className="section-title">
                                        <h3>Login</h3>
                                    </div>
                                    <div className="row" id="loginButtons" style={{ marginBottom: "25px", marginLeft: "50px" ,marginTop:10}}>
                                        <Tab.Container id="" defaultActiveKey={this.state.selectedIndex} style={{ marginBottom: "10px" }} onSelect={this.handleIndexChange}>
                                            <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="0">Using Email</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1">Using Phone Number</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                    {this._renderComponent()}
                                    <div className="form-group">
                                        <input type="password" className="form-control" name="password" id="password" placeholder="Password" onChange={this.onPasswordTextChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>
                                    <div className="text-center">                                       
                                        <div style={{padding:"5px", marginTop:"20px"}}>
                                            <button style={{ background: materialTheme.COLORS.StrikeColor, paddingLeft:"50px", paddingRight:"50px" }} type="submit">Login</button>
                                        </div>
                                        <div style={{ padding: "10px",marginTop:"20px" }}>
                                        <Link style={{ color: "#013d56",marginRight:"20px" }}  to="/signup">Create An Account</Link>
                                        <Link style={{ color: "#AAAAAA" }}  to="/forgotpassword">Forgot Password?</Link>
                                            {/* <a style={{ color: "#AAAAAA" }} id="TermsConditions" href="https://mespoke.cloud/api/termsConditions" target="blank"></a> */}
                                        </div>
                                    </div>
                                </form>
                                <div className="row" style={{ marginBottom: "10px", marginTop: "30px" }}>
                                    <div className="col-lg-4 col-md-4 mx-auto">
                                        Get the app.
                                        </div>
                                </div>
                                <div className="row" style={{marginLeft:"40px", marginRight:"40px"}}>
                                    <div className="col-md-6" style={{paddingRight:0, paddingLeft:0}}>
                                        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.mespoke.app" ><img src={"/images/PlayStore.png"} className="img-fluid getApp" alt="" style={{ marginBottom: "10px" }}></img></a>
                                    </div>
                                    <div className="col-md-6" style={{paddingLeft:0, paddingRight:0}}>
                                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/mespoke/id1133070227" ><img src={"/images/AppStore.png"} className="img-fluid getApp" alt="" style={{ marginBottom: "10px" }}></img></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- End Contact Section --> */}
                </main>
            </div>
        );
    }
}
export default Signup;