import React, { Component } from "react";
import { Link } from 'react-router-dom';
import materialTheme from "../../theme/Theme";
import UserService from "../../services/user";
import DiscoverService from "../../services/discover";
import { connect } from "react-redux";
import Gallery from "react-photo-gallery";
import SinglePost from '../SinglePostScreenModal'
import UsersFollowScreen from './UsersFollowScreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faUserCog } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import { Redirect } from 'react-router-dom';
import $ from 'jquery'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
    addUserPosts,
    addUserProfile,
    addUserFollowedBrands,
    resetBrands,
    addBrandList,
    addPageSize,
} from "../../store/actions/profile";
import Header from "../../Components/Header";
import Modal from "react-bootstrap/Modal";
const innerHeight = window.innerHeight
var width = 441;
var height = 956
if (window.innerWidth < width) {
    width = window.innerWidth;
    height = window.innerHeight;

}
class ProfileScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            user: {
                Followers: "-",
                Location: null,
                PhotoThumbnailURL:
                    "/images/defaultImage.jpg",
                Points: "-",
                ProfileId: "-",
                ProfileName: "-",
                Views: "-",
                followStatus: "-",
                saved: "-",
                shopped: "-",
            },
            currentImage: 0,
            lightboxIsOpen: false,
            post: [],
            posts: [],
            loadingPost: false,
            loadingBrands: false,
            brandsImages: [],
            pageSize: 21,
            showCollectionModel: false,
            showUserModal: false,
            loggedIn: false,
            refreshBottom: false,
            noMoreItems: false
        };
    }
    componentWillUpdate(prevProps, nextProps) {
        if (prevProps.location.state.loggedIn == true && nextProps.loggedIn == false) {
            this.setState({
                ...this.state,
                loggedIn: true,
            });
            this._loadLoggedInUser()
            var elmnt = document.getElementById("flatList");
            elmnt.scrollTo(0, 0)
        }
        // if(prevProps.location.state.from == "userfollowing"){
        // this._loadUserById()
        // }
    }
    async componentDidMount() {
        if (this.props.location.state == undefined) {
            return
        }
        this.setState({
            ...this.state,
            loadingBrands: true,
            loadingPost: true,
        });
        this._loadData();
        document.getElementById("flatList").addEventListener("scroll", () => this.scrollControll())
    }
    componentWillUnmount() {
        if (document.getElementById("flatList") != null && document.getElementById("flatList") != undefined) {
            document.getElementById("flatList").removeEventListener('scroll', () => this.scrollControll());
        }
    }
    scrollControll = () => {
        if (this.state.refreshBottom == false && this.state.noMoreItems == false) {// 

            if ($("#flatList")[0] != undefined) {
                if ($("#flatList").scrollTop() + $("#flatList").innerHeight() >= $("#flatList")[0].scrollHeight) {
                    this._loadMorePosts()
                }
            }
        }
    }
    updateProfile = () => {
        this.setState({
            ...this.state,
            loadingBrands: true,
            loadingPost: true,
        });
        this._loadData();
    }

    _loadData = () => {

        if (this.props.location.state.loggedIn) {
            this.setState({
                ...this.state,
                loggedIn: true,
            });
            this._loadLoggedInUser();
        } else {
            this._loadUserById();
        }
    };

    _resetState = () => {
        const tempUser = {
            Followers: "-",
            Location: null,
            PhotoThumbnailURL:
                "/images/defaultImage.jpg",
            Points: "-",
            ProfileId: "-",
            ProfileName: "-",
            Views: "-",
            followStatus: "-",
            saved: "-",
            shopped: "-",
        };
        this.setState({
            ...this.state,
            user: tempUser,
            posts: [],
            brandsImages: [],
            showUserModal: false,
            loadingPost: true,
        });
    };

    getLogo = (brandName, brandID) => {

        return new Promise((resolve) => {
            DiscoverService.getBrandLogoAsync(brandName)
                .then((logo) => {
                    resolve({ logo: logo, name: brandName, id: brandID });
                })
                .catch(console.log);
        });
    };

    _loadLoggedInUserPosts = () => {

        UserService.getLoggedInUserPosts(this.props.pageSize)
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data[0] &&
                    response.data[0].error
                ) {
                    this.setState({
                        ...this.state,
                        loadingPost: false,
                    });
                    console.log(response.data[0].error);
                } else if (response.status === 200 && response.data.length > 0) {

                    this.props.addPosts(response.data);
                    let noMoreItems = false
                    if (this.props.posts.length == response.data.length) {
                        noMoreItems = true
                    }
                    this.setState({
                        ...this.state,
                        loadingPost: false,
                        noMoreItems: noMoreItems,
                        refreshBottom: false
                    });

                } else {

                    this.props.addPosts([]);
                    this.setState({
                        ...this.state,
                        loadingPost: false,
                    });

                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    _loadLoggedInUserProfile = () => {

        UserService.getUserProfile()
            .then((response) => {
                if (response.status === 200 && !response.data[0].error) {
                    this.props.addUser(response.data[0]);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    _loadLoggedInUserBrands = () => {
        UserService.getFavoriteBrands()
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data[0] &&
                    response.data[0].error
                ) {
                    this.setState({
                        ...this.state,
                        loadingBrands: false,
                    });
                    console.log(response.data[0].error);
                } else if (response.status === 200 && response.data.length > 0) {

                    let brandsList = [];
                    response.data.forEach((elem) => {
                        brandsList.push(this.getLogo(elem.Content, elem.Id));
                    });
                    Promise.all(brandsList).then((result) => {
                        this.props.addBrands(result);
                        this.setState({
                            ...this.state,
                            loadingBrands: false,
                        });
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    _loadUserProfileByID = (profileId) => {

        UserService.getUserProfileById(profileId)
            .then((response) => {
                if (response.status === 200 && !response.data[0].error) {
                    this.setState({
                        ...this.state,
                        user: response.data[0],
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    _loadUserPostsByID = (profileId) => {

        UserService.getPostsById(profileId, this.state.pageSize)
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data[0] &&
                    response.data[0].error
                ) {
                } else if (response.status === 200 && response.data.length > 0) {
                    let noMoreItems = false
                    if (this.state.posts.length == response.data.length) {
                        noMoreItems = true
                    }
                    this.setState({
                        ...this.state,
                        posts: response.data,
                        loadingPost: false,
                        noMoreItems: noMoreItems,
                        refreshBottom: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        posts: [],
                        loadingPost: false,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    _loadUserBrandsByID = (profileId) => {

        UserService.getFavoriteBrandOfUser(profileId)
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data[0] &&
                    response.data[0].error
                ) {
                    console.log(response.data[0].error);
                } else if (response.status === 200 && response.data.length > 0) {

                    let brandsList = [];
                    response.data.forEach((elem) => {
                        brandsList.push(this.getLogo(elem.Content, elem.Id));
                    });
                    Promise.all(brandsList).then((result) => {
                        this.setState({
                            ...this.state,
                            brandsImages: result,
                            loadingBrands: false,
                        });

                    });
                }
                else
                {
                    this.setState({
                        ...this.state,                       
                        loadingBrands: false,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    _loadLoggedInUser = () => {

        if (this.props.posts.length == 0 || this.props.location.state.newPost == true) {
            this._loadLoggedInUserProfile();
            this._loadLoggedInUserPosts();
            this._loadLoggedInUserBrands();
        }
    };

    _loadUserById = () => {

        const profileId = this.props.location.state.profileId
        this._loadUserProfileByID(profileId);
        this._loadUserPostsByID(profileId);
        this._loadUserBrandsByID(profileId);
        this.setState({ userID: profileId });
    };

    _loadUserByIdFromFollow = (profileId) => {
        this._resetState()
        this.props.location.state.profileId = profileId
        this.props.location.state.loggedIn = false
        this._loadUserProfileByID(profileId);
        this._loadUserPostsByID(profileId);
        this._loadUserBrandsByID(profileId);
        this.setState({ userID: profileId });
    };

    onPressFollowToggle = () => {
        const profileId = this.props.location.state.profileId
        UserService.toggleFollowUser(profileId)
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data[0] &&
                    response.data[0].error
                ) {
                    console.log(response.data[0].error);
                } else if (response.status === 200 && response.data.length > 0) {
                    let user = { ...this.state.user };
                    user.followStatus = response.data[0].followStatus;
                    user.shopped = +user.shopped + (user.followStatus === 0 ? -1 : 1);
                    this.props.user.Followers = Number(this.props.user.Followers) + 1
                    this.setState({ ...this.state, user });

                }
            })
            .catch(console.log);
    };
    openLightbox = (event, obj) => {

        this.setState({
            ...this.setState,
            currentImage: obj.index,
            post: obj,
            lightboxIsOpen: true,

        });
    }
    closeLightbox = () => {
        this.setState({
            ...this.setState,
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    _showLoader = () => {
        if (this.state.refreshBottom) {
            return (
                <div id="refreshBottom" style={{ height: "100px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
            )
        }
    }
    renderPostImages = () => {

        let posts = [];
        if (this.props.location.state.loggedIn) {
            posts = this.props.posts;
        } else {
            posts = this.state.posts;
        }
        let postList = posts.map(el => {
            return { PostId: el.PostId, src: el.ImageThumbnailUrl, videoUrl: el.videoUrl }
        });
        const imageRenderer = (
            ({ index, left, top, key, photo }) => (
                photo.videoUrl == null ? (<img
                    alt="..."
                    onError={(e) => this.imageErrorHandler(e)}
                    {...photo}
                    index={index}
                    key={key}
                    style={{ margin: 3 }}
                    className="galleryImages"
                    onClick={(e) => this.openLightbox(e, { index: index, photo: photo })}
                />) : (
                    <div style={{ position: "relative" }}>
                        <img
                            alt="..."
                            onError={(e) => this.imageErrorHandler(e)}
                            {...photo}
                            index={index}
                            key={key}
                            style={{ margin: 3, display: "block" }}
                            className="galleryImages"
                            onClick={(e) => this.openLightbox(e, { index: index, photo: photo })}
                        />
                        <FontAwesomeIcon icon={faPlay} style={{ position: "absolute", color: "white", marginTop: "11px", marginLeft: '12px', bottom: 10, left: 0, }} />
                    </div>
                )
            ));
        return (
            <div>
                <Gallery photos={postList} renderImage={imageRenderer} />
                {this._showLoader()}
            </div>
        )
    };

    _renderPostContents = () => {

        if (this.state.loadingPost) {
            return (
                <Loader type="Puff"
                    color="#00BFFF"
                    height={50}
                    width={50}
                    style={{ marginTop: "50px" }}
                />
            );
        } else {
            const { route } = this.props;
            let posts = this.state.posts;

            if (this.props.location.state.loggedIn) {
                posts = this.props.posts;
            }

            if (posts.length > 0) {
                return (
                    this.renderPostImages()
                );
            } else {
                return (
                    <Link to="postimage"><img
                        src={"/images/EmptyProfileScreen.png"}
                        alt="Empty Profile"
                        style={{ marginTop: "30px", width: width - 20, height: "auto" }}
                    /></Link>
                );
            }
        }
    };
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg"
    }
    imageErrorHandlerpost(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultpost.jpg"
    }
    _renderBrandsImages = () => {

        let brandsImages = [];

        if (this.props.location.state.loggedIn) {
            brandsImages = this.props.brandsImages;
        } else {
            brandsImages = this.state.brandsImages;
        }

        return brandsImages.map((brand, index) => {
            return (
                <img onError={(e) => this.imageErrorHandler(e)} onClick={
                    () => {
                        const { history } = this.props;
                        history.push({
                            pathname: '/branddetail',
                            state: { brandName: brand.name, brandId: brand.id }
                        })
                    }
                } key={index} src={brand.logo} alt="" style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 55, minHeight: 55, width: 55, height: 55, borderRadius: 30, backgroundColor: 'white', margin: 3, objectFit: "contain" }} />
            );
        });
    };
    handleModalShowHideNames() {
        this.setState({ showUserModal: !this.state.showUserModal })
    }
    _renderBrandContents = () => {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 17,
                slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 8,
                slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 5,
                slidesToSlide: 1 // optional, default to 1.
            }
        }
        if (this.state.loadingBrands) {
            return (
                <div className="d-inline-flex flex-row justify-content-center border-top border-bottom">
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ margin: "10px" }}
                    />
                </div>
            );
        } else {
            let brandsImages = [];

            if (this.props.location.state.loggedIn) {
                brandsImages = this.props.brandsImages;
            } else {
                brandsImages = this.state.brandsImages;
            }

            if (brandsImages.length > 0) {
                return (
                    <div className="border-top border-bottom" style={{ paddingLeft: "8%", paddingRight: "8%" }} id="brandsDiv">
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            ssr={false} // means to render carousel on server-side.
                            infinite={false}
                            autoPlay={false}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"

                        >{this._renderBrandsImages()}</Carousel>
                    </div>

                );
            } else {
                if (this.props.location.state.loggedIn) {
                    return (
                        <div>
                            <p style={{ fontSize: "15px", color: materialTheme.COLORS.PrimaryDarkLightColor }}>
                                You are not following any brands
              </p>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <p style={{ fontSize: "15px", color: materialTheme.COLORS.PrimaryDarkLightColor }}>
                                This user is not following any brands
              </p>
                        </div>
                    );
                }
            }
        }
    };

    _renderUserPoints = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <div>
                    <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.props.user.Points))}</p>
                </div>
            );
        } else {
            return (
                <div><p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.state.user.Points))}</p>
                </div>
            );
        }
    };

    _renderUserFollowers = () => {
        if (this.props.location.state.loggedIn) {
            return (
                <div><p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.props.user.shopped))}</p>
                </div>
            );
        } else {
            return (
                <div><p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.state.user.shopped))}</p>
                </div>
            );
        }
    };
    openFollowing = (followers) => {
        // if condition to not to open when 0 followers 
        this.handleModalShowHideNames()
    }

    _renderUserFollowing = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <div onClick={() => this.openFollowing(this.props.user.Followers)} style={{ cursor: "pointer" }}>
                    <div>
                        <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.props.user.Followers))}</p>
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor }}>Following</p>

                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div onClick={() => this.openFollowing(this.state.user.Followers)} style={{ cursor: "pointer" }}>
                        <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.state.user.Followers))}</p>
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor }}>Following</p>
                    </div>
                </div>
            );
        }
    };
    _renderUserFollowingMobile = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <div onClick={() => this.openFollowing(this.props.user.Followers)} style={{ cursor: "pointer" }}>
                    <div>
                        <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.props.user.Followers))}</p>
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor, fontSize: 13 }}>Following</p>

                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div onClick={() => this.openFollowing(this.state.user.Followers)} style={{ cursor: "pointer" }}>
                        <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.nFormatter(Number(this.state.user.Followers))}</p>
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor, fontSize: 13 }}>Following</p>
                    </div>
                </div>
            );
        }
    };
    _renderUserProfileImage = () => {

        if (this.props.location.state.loggedIn) {
            debugger
            return (
                <img
                onError={(e) => this.imageErrorHandler(e)}
                    src={this.props.user.PhotoThumbnailURL}
                    alt="Thumbnail"
                    style={{
                        borderWidth: "1px",
                        width: "95px",
                        height: "95px",
                        borderRadius: "105px",
                        borderColor: materialTheme.COLORS.StrikeColor,
                        marginTop: 5,
                        objectFit: "cover"
                    }}
                />
            );
        } else {
            debugger
            return (
                <img
                onError={(e) => this.imageErrorHandler(e)}
                    src={this.state.user.PhotoThumbnailURL}
                    alt="Thumbnail"
                    style={{
                        borderWidth: "1px",
                        width: "95px",
                        height: "95px",
                        borderRadius: "105px",
                        borderColor: materialTheme.COLORS.StrikeColor,
                        marginTop: 5,
                        objectFit: "cover"
                    }}
                />
            );
        }
    };

    _renderUserSaved = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.props.user.saved}</p>
            );
        } else {
            return (
                <p className="profileSetting" style={{ fontSize: '17px', color: materialTheme.COLORS.PrimaryDarkTextColor }}>{this.state.user.saved}</p>
            );
        }
    };

    nFormatter = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
        }
        if (num < 1000) {
            return num;
        }
        return "0";
    };

    _renderUserFollowUnFollowCollectionBtn = () => {

        let user = this.state.user;

        if (this.props.location.state.loggedIn) {
            user = this.props.user;
        }

        if (user.followStatus == "0") {
            return (
                <button onClick={() => this.onPressFollowToggle()} className="btn btn-primary" style={{ backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor, borderColor: materialTheme.COLORS.PrimaryDarkLightColor }}>
                    Follow
                </button>
            );
        } else if (user.followStatus == "1") {
            return (
                <button onClick={() => this.onPressFollowToggle()} className="btn btn-danger" style={{ backgroundColor: materialTheme.COLORS.ERROR, borderColor: materialTheme.COLORS.ERROR }}>
                    Following
                </button>
            );
        } else if (user.followStatus == "2") {
            return (
                <button className="btn btn-danger" style={{ backgroundColor: materialTheme.COLORS.ERROR, borderColor: materialTheme.COLORS.ERROR }}>
                    <Link style={{ textDecoration: "none", color: materialTheme.COLORS.WHITE }} to={{ pathname: "/ProfileMyCollection", state: {} }}>Collection</Link>
                </button>
            );
        } else {
            return (
                <Loader type="Puff"
                    color="#00BFFF"
                    height={33}
                    width={33}
                    style={{ margin: "10px" }}
                />
            );
        }
    };

    _renderUserName = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: materialTheme.COLORS.BLACK,
                        textAlign: "center",
                        marginBottom: 6
                    }}
                >
                    {this.props.user.ProfileName}
                </p>
            );
        } else {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: materialTheme.COLORS.BLACK,
                        textAlign: "center",
                        marginBottom: 6
                    }}
                >
                    {this.state.user.ProfileName}
                </p>
            );
        }
    };
    _renderUserNameMobile = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: materialTheme.COLORS.BLACK,
                        textAlign: "left",
                        marginBottom: 6,
                        paddingLeft: "3%",
                        whiteSpace:"nowrap"
                    }}
                >
                    {this.props.user.ProfileName}
                </p>
            );
        } else {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: materialTheme.COLORS.BLACK,
                        textAlign: "left",
                        marginBottom: 6,
                        paddingLeft: "3%",
                        whiteSpace:"nowrap"
                    }}
                >
                    {this.state.user.ProfileName}
                </p>
            );
        }
    };

    _renderUserLocation = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <p style={{ marginTop: 30, marginBottom: 0 }}>{this.props.user.Location}</p>
            );
        } else {
            return (
                <p style={{ marginTop: 30, marginBottom: 0 }}>{this.state.user.Location}</p>
            );
        }
    };

    _renderUserLocationMobile = () => {

        if (this.props.location.state.loggedIn) {
            return (
                <p style={{ paddingLeft: "3%",textAlign: "left",whiteSpace:"nowrap" }}>{this.props.user.Location}</p>
            );
        } else {
            return (
                <p style={{ paddingLeft: "3%",textAlign: "left",whiteSpace:"nowrap" }}>{this.state.user.Location}</p>
            );
        }
    };

    _loadMorePosts = (e) => {
        if (this.props.location.state.loggedIn) {
            this.setState({
                ...this.state,
                refreshBottom: true
            })
            this.props.updatePageSize(this.props.pageSize + 21)
            this._loadLoggedInUserPosts()

        } else {
            let pageSize = this.state.pageSize + 21
            this.setState({
                ...this.state,
                pageSize: pageSize,
                refreshBottom: true
            })
            const profileId = this.props.location.state.profileId
            setTimeout(() => {
                this._loadUserPostsByID(profileId)
            }, 200);
        }
    };

    _renderCollectionModel = () => {
        return "";
    };

    _renderFollowingUserModal = () => {
        return "";
    };

    _renderFollowersList = () => {

    }
    toSetting = () => {
        const { history } = this.props;
        history.push({
            pathname: '/profilesetting'
        })
    }
    renderSetting = () => {
        if (this.props.location.state.loggedIn) {
            return (
                <div style={{ float: "right" }}> <FontAwesomeIcon onClick={() => this.toSetting()} id="setting" icon={faUserCog} className="fa-2x" style={{ color: "#062D3F", marginTop: 10, cursor: "pointer" }} /></div>
            );
        }
    }
    render() {
        if (this.props.location.state == undefined) {
            return <Redirect to='/' />;
        }
        else {
            return (
                <div>
                    <Header pageName="profile" {...this.props} updateProfile={this.updateProfile} />
                    <main>
                        <section id="logindiv" className="contact">
                            <div className="row profileMarginTop" style={{ marginTop: "45px", backgroundColor: "#fafafa" }}>
                                <div id="Profile" >
                                    <div className="row align-items-center" style={{ paddingRight: 0 }}>
                                        <div className="col-md-3">
                                            {/* {this.renderSetting()} */}
                                            <div>
                                                {this._renderUserProfileImage()}
                                                {this._renderUserName()}
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {this._renderUserPoints()}
                                                    <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor }}>Points</p>
                                                </div>
                                                <div className="col-md-3" onClick={this._renderFollowersList}>
                                                    {this._renderUserFollowers()}<p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor }}>Followers</p>
                                                    {this._renderUserLocation()}
                                                </div>
                                                <div className="col-md-3">
                                                    {this._renderUserFollowing()}
                                                </div>
                                                <div className="col-md-3">
                                                    {this._renderUserSaved()}<p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor }} > Saved </p>
                                                    {this._renderUserFollowUnFollowCollectionBtn()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div id="boardertop" style={{ borderTop: "2px solid #062D3F ", marginLeft: 20, marginRight: 20, marginTop: 3, width: "94%" }}></div> */}
                                <div id="mobileProfile" style={{justifyContent: "flex-start"}}>
                                <div style={{ paddingLeft:"2%",width:"25%" }}>
                                            {this._renderUserProfileImage()}
                                        </div>
                                    <div  style={{ display:"flex",justifyContent: "space-around", alignItems: "center",width:"72%",paddingLeft:"3%", paddingRight:"3%", borderBottom:"1px solid #062D3F",borderTop:"1px solid #062D3F", marginTop:5 }}>
                                       
                                        <div style={{ }}>
                                            {this._renderUserPoints()}
                                            <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor, fontSize: 13 }}>Points</p>
                                        </div>
                                        <div onClick={this._renderFollowersList} style={{}}>
                                            {this._renderUserFollowers()}<p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor, fontSize: 13 }}>Followers</p>
                                        </div>
                                        <div style={{}}>
                                            {this._renderUserFollowingMobile()}
                                        </div>
                                        <div style={{}}>
                                            {this._renderUserSaved()}<p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor, fontSize: 13 }} > Saved </p>
                                        </div>
                                    </div>
                                    {/* <div id="boarderbottom" style={{ borderTop: "2px solid #062D3F ", marginLeft: 20, marginRight: 20, width: "94%" }}></div> */}
                                </div>                                
                                <div id="mobileProfile2" style={{ justifyContent: "space-between" }}>
                                    <div style={{ paddingLeft: 3 }}>
                                        {this._renderUserNameMobile()}
                                        {this._renderUserLocationMobile()}
                                    </div>
                                    <div style={{ paddingRight: "10px", paddingTop: 10 }}>{this._renderUserFollowUnFollowCollectionBtn()}</div>
                                </div>
                                {this._renderBrandContents()}
                                <div id="flatList" className="col-md-12 mx-auto" style={{ height: (innerHeight - 322), overflowY: "scroll", width: "98%", marginTop: 20 }}>
                                    {this._renderPostContents()}
                                </div>
                                <Modal show={this.state.lightboxIsOpen} id="singlePostModal">
                                    <SinglePost postData={this.state.post} closeLightbox={this.closeLightbox} />
                                </Modal>
                                <Modal show={this.state.showUserModal}>
                                    <Modal.Header onClick={() => this.handleModalShowHideNames()}>
                                        <Modal.Title>Following</Modal.Title>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </Modal.Header>
                                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                        <UsersFollowScreen history={this.props.history} loggedIn={this.props.location.state.loggedIn} updateProfile={this._loadUserByIdFromFollow} userID={this.props.location.state.profileId} />
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </section>
                    </main>

                </div>
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        posts: state.profileReducer.userPosts,
        user: state.profileReducer.user,
        brandsImages: state.profileReducer.brandsImages,
        pageSize: state.profileReducer.pageSize,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addPosts: (userPosts) => dispatch(addUserPosts(userPosts)),
        addUser: (user) => dispatch(addUserProfile(user)),
        addBrands: (brandImage) => dispatch(addUserFollowedBrands(brandImage)),
        resetBrandList: () => dispatch(resetBrands([])),
        addBrands: (brandList) => dispatch(addBrandList(brandList)),
        updatePageSize: (pageSize) => dispatch(addPageSize(pageSize)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
