import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import { Nav, Tab, } from 'react-bootstrap';
import materialTheme from '../theme/Theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlay, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import '../Pages/Stylesheets/common.css'
import Header from '../Components/Header'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import NewImage from './ImageNewPostScreen';
import NewVideo from './VideoNewPostScreen';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            selectedIndex: "0",
            colorofIconImage :"white",
            colorofIconVideo :"#062D3F"
        };
    }
    async componentDidMount() {

        
    }   
    
    handleIndexChange = index => {
        debugger
        if(index == "1"){
            this.setState({
                ...this.state,
                selectedIndex: index,
                colorofIconImage:  "#062D3F",
                colorofIconVideo:  "white",
            });        
        }
        else{
            this.setState({
                ...this.state,
                selectedIndex: index,
                colorofIconImage:  "white",
                colorofIconVideo:  "#062D3F"
            });        
        }
        
    }
    
    _renderSegmentedControl = () => {

        return (
            <section style={{ backgroundColor: "#fafafa" }} className="breadcrumbs">
                <div className="me-auto">
                    <Tab.Container id="homesegment" defaultActiveKey={this.state.selectedIndex} onSelect={this.handleIndexChange}>
                        <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                            <Nav.Item>
                                <Nav.Link eventKey="0">Use Image <Icon icon="carbon:image-search-alt" width="25" height="25" style={{color: this.state.colorofIconImage, marginLeft:0,marginBottom: 0}}  /> </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="1">Use Video <Icon icon="mdi:video" width="25" height="25" style={{color:this.state.colorofIconVideo, marginLeft:0,marginBottom: 0}}  /></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Tab.Container>
                </div>
            </section>
        );
    }
    _renderNewPostType = () => {
        debugger;
        if(this.state.selectedIndex == "0"){
           return  <NewImage {...this.props}/>
        }
        else
        {
          return  <NewVideo {...this.props}/>
        }
    }
    render() {
        return (
            <div>
                <Header pageName="newpost" {...this.props} />
                {this._renderSegmentedControl()}
                {this._renderNewPostType()}
         </div>
        );
    }
}
export default Home;