import * as React from 'react';
import Notifications from '../Components/Notifications';
import NotificationService from '../services/notification'
import Header from '../Components/Header'
import Loader from "react-loader-spinner";
import materialTheme from '../theme/Theme'
import { Modal, Button } from 'react-bootstrap';
import SinglePost from '../Pages/SinglePostScreenModal'
import { Nav, Tab, } from 'react-bootstrap';

class NotificationScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            notificationList: [],
            notficationPostOpen : false           
        };
    }
    async componentDidMount() {
        this._getNotifications()
    }
    _getNotifications = () => {
        this.setState({ ...this.state, refreshing: true }, () => {
            NotificationService.getNotifications().then(response => {
                if (response.data && response.data[0] && response.data[0].error) {
                  //  console.log("Error", response.data[0].error);
                } else if (response.status !== 200) {
                  //  console.log("Error", "Some error occurred at the server.");
                } else if (response.status === 200 && response.data.length > 0) {
                    let notificationList = response.data.map(notif => {
                        return {
                            key: notif.idnotifications,
                            name: notif.Name,
                            uri: notif.PhotoThumbnailURL,
                            date: notif.PostingDate,
                            type: notif.type,
                            postId: notif.idpost,
                        }
                    });
                    this.setState({ ...this.state, notificationList, refreshing: false });
                } else {
                    this.setState({ ...this.state, notificationList: [], refreshing: false });
                }
            }).catch(console.log);

        })
    }
    closeNotificationPost = () => {
        this.setState({
            ...this.setState,
            currentImage: 0,
            notficationPostOpen: false,
        });
    }
    openNotificationPost = (PostId) => {
        let obj = { photo: { PostId: PostId } };
        this.setState({
            ...this.setState,
            notificationPost: obj,
            notficationPostOpen: true,
        });
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg"
    }
    renderPosts = () => {


        // <li className="changeBackgroundColorOnHover">
        // {/* <div className="border-bottom changeBackgroundColorOnHover" key={index} style={{cursor: "pointer" }}>                   
        //     <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={notification.uri} className="border border-dark rounded-circle" key={index}
        //         style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 70, height: 70, borderRadius: 25 }} />
        //     <label style={{ marginLeft: "20px" }}>{notification.name + " " + notification.type} {notification.date} </label>
        // </div> */}
        // {index}


        return (
            this.state.notificationList.map((notification, index) => {                
                return (
                    <li className="changeBackgroundColorOnHover">
                        <div onClick={()=>this.openNotificationPost(notification.postId)} className="d-flex border-bottom changeBackgroundColorOnHover" key={index} style={{ cursor: "pointer",marginTop:5,paddingBottom:5,marginLeft:5 }}>
                            <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={notification.uri} className="border border-dark rounded-circle" key={index}
                                style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 42, height: 42, borderRadius: 20 }} />
                            <label style={{marginTop:10,marginLeft:5,fontSize:11}}>{notification.name + " " + notification.type} {notification.date} </label>
                        </div>
                    </li>
                )
            })

        )
    }
    _renderNotifications = () =>{
        if (this.state.notificationList.length == 0) {
            return (
                <ul id="" style={{ opacity: 1, visibility: "visible",listStyleType:"none", paddingLeft:0 }}>
                    <li className="changeBackgroundColorOnHover">  <Loader type="Puff"
                        color="#00BFFF"
                        height={44}
                        width={44}
                        style={{ }}
                    /></li>
                </ul>
            )
        }
        else {
            return (
                <ul id="" style={{ opacity: 1, visibility: "visible",paddingTop:0,height:"auto",width:"auto",listStyleType:"none",paddingLeft:5 }}>
                    {this.renderPosts()}
                </ul>
            )

        }
    }
    render() {
        return (
            <div>
                <Header pageName="notifications" {...this.props} />
                <section style={{ backgroundColor: "#fafafa" }} className="breadcrumbs">
                <div className="me-auto">
                    <h2 style={{color:"#062D3F"}}>Notifications</h2>
                </div>
            </section>
                <div>
                {this._renderNotifications()}
                </div>               
               <Modal show={this.state.notficationPostOpen} id="singlePostModal">
                    <SinglePost postData={this.state.notificationPost} closeLightbox={this.closeNotificationPost} />
                </Modal>               
            </div>
        );
    }
}
export default NotificationScreen;