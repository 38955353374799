import { DISCOVER_ADD_POST_LIST, DISCOVER_PageCount} from './types';

export const addAllDiscoverPosts = (posts) => (
  {
    type: DISCOVER_ADD_POST_LIST,
    data: posts
  }
  
);

export const discoverPageCount = (pageCount) => (
  {
    type: DISCOVER_PageCount,
    data: pageCount
  }
  
);