import './App.css';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {getAllBadge}  from '../src/store/actions/home';
import AuthService from './services/auth';
import HomeService from './services/home';
import { Provider } from 'react-redux';
import store from './store/store';
import rootReducer from './store/rootReducer';
import { createStore, combineReducers } from 'redux';
import Footer from './Components/Footer'
import NoMatch from './Pages/NoMatch'
import Home from './Pages/Home'
import Login from './Pages/Login'
import VerifyCodeScreen from './Pages/VerifyCodeScreen';
import ForgotPasswordScreen from './Pages/ForgotPasswordScreen'
import ResetPassword from './Pages/ResetPassword'
import RegistrationScreen from './Pages/RegistrationScreen';
import testpage from './Pages/testpage';
import EmptyHomePlaceholder from './Pages/EmptyHomePlaceholder';
import DiscoverScreen from './Pages/DiscoverScreen';
import BrandSearchScreen from './Components/BrandSearchScreen';
import BrandDetailScreen from './Pages/BrandDetailScreen';
// import ImageNewPostScreen from './Pages/ImageNewPostScreen'
import NewPostScreen from './Pages/NewPost'
import VideoNewPostScreen from './Pages/VideoNewPostScreen'
import CategoriesDetailScreen from './Pages/CategoriesDetailScreen'
import Profile from './Pages/Profile/Profile'
import ProfileMyCollection from './Pages/Profile/ProfileMyCollection'
import SinglePostScreen from './Pages/SinglePostScreen';
import ProfileSetting from './Pages/Profile/ProfileSettingScreen';
import NotificationScreen from './Pages/NotificationsScreen'
const configureStore = () => createStore(rootReducer);

store.init(configureStore);
const finalStore = store.getStore();
// import {Phone} from './Pages/Phone'
const registerPushListener = pushNotification =>
  navigator.serviceWorker.addEventListener("message", ({ data }) =>{
    finalStore.dispatch(getAllBadge((Number(finalStore.getState().homeReducer.badgeCount) + 1)))
    //console.log(data)
    //alert(JSON.stringify(data.body))
    window.showtoast(data.notification.body)
  }
  );

export default class App extends React.Component {
    state = {
        appIsReady: false,
        isLoggedIn: false,
      
    };
    async componentDidMount() {        
        this.prepareResources();
        registerPushListener()
    }
    
    notLoggedIn = () => {
        setTimeout(() => {
            this.setState({ appIsReady: true, isLoggedIn: false }, async () => {
            })
        }, 1000);
    }
    prepareResources = async () => {
        try {
            AuthService.isUserSet().then(isSet => isSet ? this.isLoggedIn() : this.notLoggedIn())
        } catch (e) {
            console.warn(e);
        }
    };
    isLoggedIn = () => {
        const promise = new Promise((resolutionFunc, rejectionFunc) => {
            resolutionFunc(HomeService.getNotificationBadge());
        });
        promise.then((response) => {
            try {
                if (response.status === 200) {
                    let badgeCount = response.data.count_badge;                    
                    finalStore.dispatch(getAllBadge(badgeCount))
                }
            } catch (e) {
                console.warn(e);
            } finally {
                this.setState({ appIsReady: true, isLoggedIn: true })
                // this.addNotificationListeners()
                // this.setState({ appIsReady: true, isLoggedIn:true }, async () => {
                //   await SplashScreen.hideAsync();
                // })
            }
        });
    }
    
    handleLogin = (isLoggedIn) => this.setState({isLoggedIn})
    render() {
        const { isLoggedIn } = this.state;       
        if (!this.state.appIsReady) {
            return null;
        }
        return (
            <div className="App">
                <Provider store={finalStore}>                    
                    <Switch>
                        {
                            !isLoggedIn ?
                                <Route exact path='/' render={() => <Login isLoggedIn={this.handleLogin} />} />
                                :
                                <Route exact path='/' render={() => <Home isLoggedIn={this.handleLogin} />} />
                        }
                        {
                            !isLoggedIn ?
                                <Route exact path='/home' render={() => <Login isLoggedIn={this.handleLogin} />} />
                                :
                                <Route exact path='/home' render={() => <Home isLoggedIn={this.handleLogin} />} />
                        }
                        {
                            !isLoggedIn ?
                                <Route exact path='/discover' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/discover' component={DiscoverScreen}></Route>
                        }
                        {
                            !isLoggedIn ?
                                <Route exact path='/branddetail' render={() => <Redirect to="/" /> }/>
                                :
                                <Route exact path='/branddetail' component={BrandDetailScreen}></Route>
                        }
                        {/* {
                            !isLoggedIn ?
                                <Route exact path='/postImage' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/postImage' component={ImageNewPostScreen} />
                        } */}
                        {
                            !isLoggedIn ?
                                <Route exact path='/categorydetail' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/categorydetail' component={CategoriesDetailScreen} />
                        }
                        {
                            !isLoggedIn ?
                                <Route exact path='/profile' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/profile' component={Profile}/>
                        }
                        {
                            !isLoggedIn ?
                                <Route exact path='/ProfileMyCollection' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/ProfileMyCollection' component={ProfileMyCollection} />
                        }
                        {/* {
                            !isLoggedIn ?
                                <Route exact path='/postVideo' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/postVideo' component={VideoNewPostScreen} />
                        } */}
                          {
                            !isLoggedIn ?
                                <Route exact path='/newPost' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/newPost' component={NewPostScreen} />
                        }
                        {
                            !isLoggedIn ?
                                <Route exact path='/profilesetting' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/profilesetting' component={ProfileSetting} />
                        }         
                        {
                            !isLoggedIn ?
                                <Route exact path='/notifications' render={() => <Redirect to="/" />} />
                                :
                                <Route exact path='/notifications' component={NotificationScreen} />
                        }         
                                                           
                        <Route exact path='/verifycode' component={VerifyCodeScreen}></Route>
                        <Route exact path='/resetpassword' component={ResetPassword}></Route>   
                        <Route exact path='/signup' component={RegistrationScreen}></Route>     
                        <Route exact path='/forgotpassword' component={ForgotPasswordScreen}></Route>                        
                        <Route exact path='/testpage' component={testpage}></Route>
                        <Route path='*' component={NoMatch} />                        
                        {/* <Route exact path='/login' component={Login}></Route> */}
                        {/* <Route exact path='/testpage' component={testpage}></Route>     */}
                        {/* <Route exact path='/EmptyHomePlaceholder' component={EmptyHomePlaceholder}></Route>    */}
                        {/* <Route exact path='/brandssearch' component={BrandSearchScreen}></Route>      */}
                        {/* <Route exact path='/post' component={SinglePostScreen} />   */}
                    </Switch>
                    {/* <Footer /> */}
                </Provider>
                <div id="toastcontainer" className="toast-container" style={{position:"absolute", top:90, left:10}} >
                </div>
            </div>
        );
    }
}
