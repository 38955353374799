import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2'
import '../Pages/Stylesheets/style.css'
class PhoneNoComponent extends Component {
    render() {
        return (
            <div className="form-group">
                <PhoneInput
                    id="phone"
                    name="phone"
                    country={'us'}                   
                    onChange={this.props.onInputChange}
                    enableAreaCode={true}
                    enableAreaCodeStretch
                    placeholder={"Phone Number"}
                    enableSearch={true}
                    autoComplete="none" 
                />
                <div id="invalid-feedback" className="invalid-feedback">
                </div>
            </div>
        )
    }
}
export default PhoneNoComponent;