import { HOME_ADD_BADGE_COUNT, INCREMENT_BADGE_NO } from './types';

export const getAllBadge = (badgeCount) => (
  {
    type: HOME_ADD_BADGE_COUNT,
    data: badgeCount
  }
);

export const incrementBadgeNo = () => (
  {
    type: INCREMENT_BADGE_NO,
  }
);