import axios from 'axios';
import { AsyncStorage }  from 'AsyncStorage';
import BASE_URL from './apipath'


const axiosInstance = axios.create({
    baseURL: BASE_URL
});
axiosInstance.interceptors.request.use((config) => {
    let partner = localStorage.getItem("partner")
    if(partner != null && partner != "web"){
        debugger;
        if(config.url.indexOf('?') > -1){   config.url = config.url + "&partner=" + partner+"_db" } else{ config.url = config.url + "?partner=" + partner+"_db"}
        // redirect to 404 if title does not exist
        //config.url = config.url + "?partner=" + partner+"_db"
    }
    return config
 });
export default class UserService {

    static async getUserProfile() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/profile/index_updated.php", {
                params: {
                    requestingProfileId: user.ProfileId,
                    page: 1,
                    from: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getCurrentUserProfile() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            return user;
        } catch (e) {
            console.log(e);
        }
    }


    static async getUserProfileById(profileId) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/profile/index_updated.php", {
                params: {
                    requestingProfileId: profileId,
                    page: 1,
                    from: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getUserProfileSettings() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/profile/settings/get/index.php", {
                params: {
                    requestingProfileId: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    
    static async saveProfileSettingsWithOutPicture(name, location, gender, ) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let fd = new FormData();
            fd.append("name", name);
            fd.append("location", location);
            fd.append("gender", gender);         
            console.log(fd);
            return axiosInstance.post(`/users/profile/settings/saveSetting.php?requestingProfileId=${encodeURIComponent(user.ProfileId)}`, fd);
        } catch (e) {
            console.log(e);
        }
    }
    static async saveProfileSettings(name, location, gender, uploaded_file) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let fd = new FormData();
            fd.append("name", name);
            fd.append("location", location);
            fd.append("gender", gender);
            fd.append("uploaded_file", uploaded_file);
            console.log(fd);
            return axiosInstance.post(`/users/profile/settings/savePic.php?requestingProfileId=${encodeURIComponent(user.ProfileId)}`, fd);
        } catch (e) {
            console.log(e);
        }
    }

    static async deleteAccount() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/profile/settings/delete/index.php", {
                params: {
                    requestingProfileId: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async searchUsers(ProfileName) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/byuser_updated.php", {
                params: {
                    requestingProfileId: user.ProfileId,
                    search: ProfileName
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getFollowingUsers() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/byuser_updated.php", {
                params: {
                    requestingProfileId: "",
                    search: '',
                    searchFor: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getFollowingUsersByID(profileID) {
        try {
            return axiosInstance.get("/discover/byuser_updated.php", {
                params: {
                    requestingProfileId: "",
                    search: "",
                    searchFor: profileID
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getPostsById(profileId, pageSize) {
        try {
            return axiosInstance.get("/users/posts/index_updated.php", {
                params: {
                    ratingProfileId: profileId,
                    page_no: 1,
                    page_size: pageSize
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getLoggedInUserPosts(pageSize) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/posts/index_updated.php", {
                params: {
                    ratingProfileId: user.ProfileId,
                    page_no: 1,
                    page_size: pageSize,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getFavoriteBrands() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/brands/filter.php", {
                params: {
                    ratingProfileId: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getFavoriteBrandOfUser(profileId) {
        try {
            return axiosInstance.get("/brands/filter.php", {
                params: {
                    ratingProfileId: profileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async toggleFollowUser(profileId) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/follower/index.php", {
                params: {
                    requestingProfileId: profileId,
                    from: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getSinglePost(postId) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/posts/openPicture_updated.php", {
                params: {
                    ratingProfileId: user.ProfileId,
                    page: 1,
                    idpost: postId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    static async increaseViewCount(postId) {
        try {           
            let fd = new FormData();
            fd.append("idPost", postId);            
            console.log(fd);
            return axiosInstance.post(`posts/postViewed.php`, fd);
        } catch (e) {
            console.log(e);
        }
    }
    static async getCollections() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/closet/index.php", {
                params: {
                    ratingProfileId: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getCollectionStats() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/closet/dna.php", {
                params: {
                    ratingProfileId: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async deletePost(PostId) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/users/posts/delete.php", {
                params: {
                    requestingProfileId: user.ProfileId,
                    idPost: PostId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getTaggedCategoryList() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get('/closet/category/index.php', {
                params: {
                    page: 1,
                    ratingProfileId: user.ProfileId,
                    itemsPerPage: 5,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getTaggedBrandList() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get('/closet/brand/index.php', {
                params: {
                    page: 1,
                    ratingProfileId: user.ProfileId,
                    itemsPerPage: 5,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

}