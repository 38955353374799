import React from 'react'
import materialTheme from '../../theme/Theme';
import UserService from "../../services/user";
import DiscoverService from "../../services/discover";
import Loader from "react-loader-spinner";
class BrandSearchScreen extends React.Component {
    _isMounted = false;
    constructor() {
        super();
        this.state = {           
            taggedBrandList: [],           
            brandDefaultLogo: "/images/defaultImage.jpg",
        };
    }
    async componentDidMount() {
        this._isMounted = true;
        this._loadListOfBrands()       
    }

    handleBrandTapped = (brand) => {   
        this.props.closeModal()
        if(this.props.searchFrom == "branddetail") 
        {
            this.props.updateBandDetails(brand)
        }
        else
        {
        const { history } = this.props;
            history.push({
                pathname: '/branddetail',
                state: { brandName: brand.name, brandId: brand.id }
            })
        }
    }
    renderBrands = () => {
        if(this.state.taggedBrandList.length == 0){
            return ( 
                <div className="d-flex justify-content-center">
            <Loader type="Puff"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ marginTop: "10px" }}
        />
                </div>
                           )
        }
        else
        {
        return this.state.taggedBrandList.map((brand, index) => {
            return (
                <div className="border-bottom changeBackgroundColorOnHover" onClick={() => this.handleBrandTapped(brand)} key={index} style={{ borderBottomColor: 'gray', borderBottomWidth: 1, width: "100%", height: 90, marginTop: 5, cursor: "pointer" }}>
                    <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={brand.urlLogo} className="border border-dark rounded-circle" key={index}
                        style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 70, height: 70, borderRadius: 25 }} />
                    <label style={{ marginLeft: "20px" }}>{brand.name}</label>
                </div>
            )
        })
    }
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    _loadListOfBrands = () => {
        UserService.getTaggedBrandList().then(response => {
            if (response.status === 200 && !response.data.error) {
                let taggedBrandList = response.data.map(brand => {
                    return {
                        id: brand.Id,
                        name: brand.name,
                        urlLogo: this.setState.brandDefaultLogo,
                    };
                });
                this.setState({ ...this.state, taggedBrandList }); 
                this._loadBrandLogos();  
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    _loadBrandLogos = () => {
        for (let index = 0; index < this.state.taggedBrandList.length; index++) {
            DiscoverService.getBrandLogoAsync(this.state.taggedBrandList[index].name).then(logo => {
                let tempArray = [...this.state.taggedBrandList];
                tempArray[index] = { ...tempArray[index], urlLogo: logo };
                this.setState({ taggedBrandList: tempArray });
            }).catch(console.log);
        }
    }
    render() {
        return (
            <div>               
                {this.renderBrands()}
            </div>
        );
    }
}
export default BrandSearchScreen