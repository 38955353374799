import React, { Component } from 'react';
import materialTheme from '../theme/Theme'
import AuthService from '../services/auth'
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            isFormSubmitting: false
        }
        //this.handleChange = this.handleChange.bind(this);
    }
    onNewPasswordChange = pass => {
        this.setState({
            ...this.state,
            newPassword: pass.target.value,
            isFormSubmitting: false
        });
    }

    onPressDone = (event) => {
        event.preventDefault(); 
        const {history} = this.props;  
        if (this.state.newPassword.localeCompare('') === 0) {
            //this._showAlert("Hey!", "Enter password first.")
            window.triggerInputValidation("password", "Enter password first.")
            return;
        }
        else
        {
            window.triggerInputValidationSuccess("password")
        }

        this.setState({ ...this.state, isFormSubmitting: true }, () => {
            AuthService.resetPassword(this.state.newPassword).then(response => {
                if (response.status === 200 && response.data[0] && response.data[0].AccessToken) {
                    this.setState({ ...this.state, isFormSubmitting: false });
                    setTimeout(() => {
                        this._showAlert("Success", "Password changed successfully")
                    }, 100);
                    history.push({
                        pathname: '/'                                                
                    });
                } else if (response.data[0] && response.data[0].error) {
                    this._showAlert("Error", response.data[0].error);
                }
            }).catch(_ => this._showAlert("Error", "Some error occured, please try again later."))
                .finally(_ => {
                    this.setState({ ...this.state, isFormSubmitting: false });
                })
        });
    }
    _showAlert = (title, message) => {
        //alert(title + "-" + message);        
        window.showAlert(title, message)
    }
    render() {
        return (
            <div>
                <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="logindiv" className="contact">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 mx-auto" style={{ maxWidth: "500px" }}>
                                <img src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" + localStorage.getItem('logo')} className="img-fluid" alt="" style={{ marginBottom: "10px" }}></img>
                                <form className="php-email-form needs-validation" noValidate="" onSubmit={this.onPressDone} id="RegistrationForm">
                                    <div className="section-title">
                                        <h3>Reset Password</h3>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" name="password" id="password" placeholder="Enter your new password" onChange={this.onNewPasswordChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button style={{ background: materialTheme.COLORS.StrikeColor }} type="submit">DONE</button></div>
                                </form>
                            </div>
                        </div>
                    </section>
                    {/* <!-- End Contact Section --> */}
                </main>
            </div>
        );
    }
}
export default Signup;