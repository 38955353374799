import React, { Component } from 'react';
class EmailComponent extends Component {
    render() {
        return (
            <div className="form-group">                
                    <input autoComplete="none"  type="email" className="form-control" name="emailaddress" id="emailaddress" placeholder="Email Address" onChange={this.props.onInputChange}></input>                
                <div className="invalid-feedback">
                </div>
            </div>
        )
    }
}
export default EmailComponent;