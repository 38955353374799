import firebase from 'firebase';
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyCEmb33pjYtIa3-XaktluHhVhlOl1m90Lo",
  authDomain: "mespoke-react.firebaseapp.com",
  databaseURL: "https://mespoke-react.firebaseio.com",
  projectId: "mespoke-react",
  storageBucket: "mespoke-react.appspot.com",
  messagingSenderId: "579742289649",
  appId: "1:579742289649:web:5eb40622798761dabde350",
  measurementId: "G-NGGXX6CS4L"
};

let messaging = null;
firebase.initializeApp(firebaseConfig);
if(firebase.messaging.isSupported()){  
messaging = firebase.messaging();
}

export const getToken = () => {
  return messaging.getToken({vapidKey: 'BB2B0UMB9_vtGQyQU4qJHTi0q5cbcvxIcY6g8oF4npoTyUgBD_D3oSN77LJ4c5zScAn-n-RNOGOrudi5h1_jU7k'}).then((currentToken) => {
    if (currentToken) {
    //console.log('current token for client: ', currentToken);
     return currentToken
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      //console.log('No registration token available. Request permission to generate one.');
      return false
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    //console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {  
      resolve(payload);
    });
});