import { HOME_ADD_POST_LIST, HOME_ADD_BADGE_COUNT, INCREMENT_BADGE_NO } from '../actions/types';

const initialState = {
    badgeCount: 0,
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {  
      case HOME_ADD_BADGE_COUNT:
        return {
          ...state,
          badgeCount: action.data,
        }
        case INCREMENT_BADGE_NO:
        return {
          ...state,
          badgeCount: Number(state.badgeCount) + 1
        }
      default:
        return state;
    }
  }
  
  export default homeReducer;