import React from 'react'
import DiscoverService from '../services/discover'
import Header from '../Components/Header'
import Search from '../Components/Search'
import Loader from "react-loader-spinner";
import materialTheme from '../theme/Theme';
import { Modal,Button } from 'react-bootstrap';
import SinglePost from '../Pages/SinglePostScreenModal'
import Gallery from "react-photo-gallery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from 'react-router-dom';
import $ from 'jquery'
const innerHeight = window.innerHeight
export default class CategoriesDetailScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            brandLogo: "/images/defaultImage.jpg",
            profilePostList: [],
            pageNo: 1,
            showPostDetails: false,            
            currentImage: 0, 
            lightboxIsOpen: false,
            post: [],
            refreshBottom: false,
            isLoading: false,
            noMoreItems: false 
        }
    }
    openLightbox = (event, obj) => {
         this.setState({
             ...this.setState,
            currentImage: obj.index,
             post: obj,
             lightboxIsOpen: true,
        });
     }
     closeLightbox = () => {
         this.setState({
             ...this.setState,
             currentImage: 0,
             lightboxIsOpen: false,
         });
     }
    async componentDidMount(){    
        if(this.props.location.state == undefined){
            return
        }  
        this.setState({ ...this.state, isLoading: true, pageNo: 1 })
        let categoryId = this.props.location.state.categoryId        
        this.getCategoryProfilePosts(categoryId);
        document.getElementById("flatList").addEventListener("scroll", () => this.scrollControll())        
    }
    componentWillUnmount() {
        if(document.getElementById("flatList") != null && document.getElementById("flatList") !=undefined){
        document.getElementById("flatList").removeEventListener('scroll', () => this.scrollControll());
        }
    }
    scrollControll = () => {
        if (this.state.refreshBottom == false && this.state.noMoreItems == false) {
            if($("#flatList")[0] != undefined){
                if($("#flatList").scrollTop() + $("#flatList").innerHeight() >= $("#flatList")[0].scrollHeight) {
                    this._loadMorePosts()     
                    //alert("reached end")
                }
            }    
        }
    }
    getCategoryProfilePosts = (categoryId) => {
        DiscoverService.getBrandProfilePosts(categoryId,this.state.pageNo).then(response => {
            if (response.status === 200 && !response.data.error) {
                let profilePostList = response.data.map(post => {
                    return {
                        id: post.Idd,
                        imageThumbnailUrl: post.ImageThumbnailUrl,
                        imageUrl: post.ImageUrl,
                        PostId: post.PostId,
                        src: post.ImageThumbnailUrl,
                        videoUrl: post.videoUrl
                    };
                });
                let noMoreItems = false
                if (this.state.profilePostList.length == profilePostList.length) {
                    noMoreItems = true
                }
                this.setState({ ...this.state, profilePostList,isLoading: false, hasMoreItems: false, refreshBottom: false,noMoreItems:noMoreItems });            }
        }).catch((e) => {
            console.log(e);
        });
    }

    _loadMorePosts = (e) => {
            this.setState({ ...this.state, pageNo: this.state.pageNo + 1,refreshBottom:true });
            setTimeout(() => {
                let categoryId = this.props.location.state.categoryId        
                    this.getCategoryProfilePosts(categoryId);                
            }, 200);
      
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    _renderCategoryProfileContent = () => {
        let category = this.props.location.state.category  
        return (     
            <div className="container mt-3 mb-3">
            <div className="row d-flex align-items-center justify-content-center">
                <div style={{ backgroundColor: "white" }}>
                    <div style={{ boarder: "none" }}>
                        <div className="d-flex justify-content-between p-2 px-3">
                            <div className="d-flex flex-row align-items-center"><img onError={(e) => this.imageErrorHandler(e)} alt="" src={`https://source.unsplash.com/weekly?${category.replace(" ", "%20")}`} style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 60, minHeight: 60, width: 60, height: 60, borderRadius: 15, backgroundColor: 'white' }} className="rounded-circle"></img>
                                <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{category}</span></div>
                            </div>                                                   
                        </div>             
                    </div>
                </div>
            </div>
        </div>
        );
    }
    closeProfileModal = () => {
        this.setState({showPostDetails:false})
    }


    _renderHomeContents = () => {
        let postList = this.state.profilePostList;
        const imageRenderer = (
            ({ index, left, top, key, photo }) => (     
              photo.videoUrl == null ? (<img 
                onError={(e) => this.imageErrorHandler(e)}         
                alt="..."       
                {...photo}   
                index={index}
                key={key} 
                style={{margin:3}}       
                className="galleryImages"
                onClick={(e)=>this.openLightbox(e,{index:index, photo:photo})}           
              />):(
                <div style={{position:"relative"}}>
                <img        
                onError={(e) => this.imageErrorHandler(e)} 
                alt="..."       
                {...photo}   
                index={index}
                key={key} 
                style={{margin:3,display:"block"}} 
                className="galleryImages"      
                onClick={(e)=>this.openLightbox(e,{index:index, photo:photo})}                     
              />
              <FontAwesomeIcon icon={faPlay} style={{ position: "absolute", color: "white", marginTop: "11px",marginLeft:'12px', bottom:10, left:0, }} />
                </div>
              )
            ));
        if (this.state.isLoading) {
            return (
                <div style={{ height: "350px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
                // <ActivityIndicator size="large" color="#000000" style={{ position: 'absolute', alignSelf: 'center' }} />
            );
        } else {
                return (
                    <div id="flatList" className="col-md-12 mx-auto" style={{ height: (innerHeight - 260), overflowY:"scroll", width:"100%"}}>                           
                        <Gallery photos={postList} renderImage={imageRenderer} />                           
                        {this._showLoader()}
                    </div>
                );            
        }
    }
    _showLoader  = () => {
        if (this.state.refreshBottom) {
            return (
                <div id="refreshBottom" style={{ height: "100px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>                
            )
        }
    }
    updateCategoriesDetails =(categoryId, category)=>{    
        this.setState({ ...this.state, isLoading: true, pageNo: 1 })
        this.props.location.state.categoryId = categoryId 
        this.props.location.state.category  = category
        this.getCategoryProfilePosts(categoryId);
    }  
 render() {
    if(this.props.location.state == undefined){
        return <Redirect to='/'/>;
    }
    else
    {
        return (
            <div>
                 <Header pageName="discover" {...this.props}/>
                 <section style={{ backgroundColor: "#fafafa" }} className="breadcrumbs">
                    <div className="me-auto">
                        <Search history={this.props.history}  updateCategoriesDetails={this.updateCategoriesDetails} searchFrom={"categorydetail"} />
                    </div>
                </section>
                {this._renderCategoryProfileContent()}
                <div>           
                    {/* <Gallery photos={this.state.profilePostList}  renderImage={imageRenderer} />  */}
                    {this._renderHomeContents()}
                    <Modal show={this.state.lightboxIsOpen} id="singlePostModal">                                         
                        <SinglePost postData={this.state.post} closeLightbox={this.closeLightbox} />                    
                </Modal>
                </div>
            </div>
        );
    }
    }
}