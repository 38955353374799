import * as React from 'react';
import materialTheme from '../../theme/Theme';
import AuthService from '../../services/auth';
import UserService from '../../services/user';
import Header from '../../Components/Header'
import { connect } from 'react-redux';
import { addUserProfile, logout } from '../../store/actions/profile';
import Dropzone from 'react-dropzone';
import { Modal, Button } from 'react-bootstrap';
import { Nav, Tab, } from 'react-bootstrap';
const width = 441;

class ProfileSettingScreen extends React.Component {
    constructor() {
        super();
        this.onDrop = (files) => {
            let temp = files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            this.setState(
                { imageObject: temp,showPreview: true }
            )
        };
        this.state = {
            imageObject: null,
            isFormSubmitting: false,
            
            selectedIndex: "",
            user: {
                Location: "",
                ProfileName: "",
                Gender: "",
                PhotoThumbnailURL: "/images/defaultImage.jpg"
            },
            isDeleteAccountVisible: false, 
            showPreview: false           
        };
    }

    handleIndexChange = index => {
        let user = { ...this.state.user };
        user.Gender = Number(index) + 1;
        this.setState({
            ...this.state,
            selectedIndex: index,
            user
        });
    };
   
    
    _showAlert = (title, message) => {
        //alert(title + "-" + message);        
        window.showAlert(title, message)
    }

    OnPressSave = (event) => {
        event.preventDefault();
        const { ProfileName, Location, Gender } = this.state.user;
        if(this.state.imageObject[0] == undefined){
            this.setState({ ...this.state, isFormSubmitting: true }, () => {
                UserService.saveProfileSettingsWithOutPicture(ProfileName, Location, Gender, this.state.imageObject[0])
                    .then(response => {
                        let updatedUser = {
                            ...this.state.user,
                            Followers: this.props.user.Followers,
                            Points: this.props.user.Points,
                            ProfileId: this.props.user.ProfileId,
                            Views: this.props.user.Views,
                            followStatus: this.props.user.followStatus,
                            saved: this.props.user.saved,
                            shopped: this.props.user.shopped                            
                        }
                        this.setState({ ...this.state, isFormSubmitting: false, user: updatedUser });
                        setTimeout(() => {
                            this.props.addUser(this.state.user);
                            this._showAlert("Success", "Profile Updated");
                        }, 100);
                    }).catch(e => console.log(e));
            });
        }
        else
        {
        this.setState({ ...this.state, isFormSubmitting: true }, () => {
            UserService.saveProfileSettings(ProfileName, Location, Gender, this.state.imageObject[0])
                .then(response => {
                    let updatedUser = {
                        ...this.state.user,
                        Followers: this.props.user.Followers,
                        Points: this.props.user.Points,
                        ProfileId: this.props.user.ProfileId,
                        Views: this.props.user.Views,
                        followStatus: this.props.user.followStatus,
                        saved: this.props.user.saved,
                        shopped: this.props.user.shopped,
                        PhotoThumbnailURL: this.state.imageObject[0].preview
                    }
                    this.setState({ ...this.state, isFormSubmitting: false, user: updatedUser });
                    setTimeout(() => {
                        this.props.addUser(this.state.user);
                        this._showAlert("Success", "Profile Updated");
                    }, 100);
                }).catch(e => console.log(e));
        });
    }
    }

    async componentDidMount(){        
        this._LoadProfileData();
    }

    onNameChange = (value) => {
        let temp = this.state.user;
        temp.ProfileName = value.target.value;
        this.setState({ ...this.state, user: temp });
    }

    onLocationChange = (value) => {
        let temp = this.state.user;
        temp.Location =  value.target.value;
        this.setState({ ...this.state, user: temp });
    }

    _LoadProfileData = () => {
        UserService.getUserProfileSettings().then(response => {
            if (response.status === 200 && !response.data[0].error) {
                const user = response.data[0];
                let selectedIndex;
                user.Gender = parseInt(user.Gender);
                if (user.Gender === 2 || user.Gender === 3)
                    selectedIndex = user.Gender - 1;
                else
                    selectedIndex = 0;
                selectedIndex = selectedIndex + ""
                let localUri = user.PhotoThumbnailURL;
                let filename = localUri.split('/').pop();
                let match = /\.(\w+)$/.exec(filename);
                let type = match ? `image/${match[1]}` : `image`;
                let imageObject = { uri: localUri, name: filename, type };
                this.setState({ ...this.state, user, selectedIndex , imageObject });
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    onPressDeleteAccount = () => {
        this._renderActionSheet()
    }
    deleteUserAccount = () => {
        this.setState({ isDeleteAccountVisible: false })
        UserService.deleteAccount().then(response => {
            AuthService.logout().then(response => {
                window.location.reload();
            });            
        }).catch(e => console.log(e));
    }

    showDeleteActionSheetAndroid = () => {
        this.setState({ isDeleteAccountVisible: true })
    }
    _renderActionSheet = () => {
        this.showDeleteActionSheetAndroid()
    }

    _renderReportActionSheetAndroid = () => {
        if (!this.state.isDeleteAccountVisible) {
            return
        }
        return (
           <div>
                <Modal size="sm" show={this.state.isDeleteAccountVisible} centered>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                    <div className="border-bottom">
                        <p>Are you sure ?</p>
                    </div>
                    <div className="border-bottom" onClick={() => this.showReportTypeDialog()}>
                        <h5 onClick={() => this.deleteUserAccount()} style={{ color: 'red', cursor: "pointer", marginTop: "8px" }}>YES</h5>
                    </div>
                    <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.setState({ isDeleteAccountVisible: false })}>Cancel</Button>
                </Modal.Body>
            </Modal>
           </div>
        );
    }
    renderDropZone = ()=>{
            return (
                <div>
                <Dropzone  onDrop={this.onDrop} multiple={false} acceptedFiles={".jpeg,.jpg,.png"}>
                    {({ getRootProps, getInputProps }) => (
                        <section style={{paddingLeft:0,paddingRight:0,paddingBottom:20}} className="container">
                            <div {...getRootProps({ className: 'dropzoneProfile' })}>
                                <input {...getInputProps()} />
                                <p style={{color:"black", marginTop:10}}>Change Photo</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                </div>
            );
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    renderImage = ()=>{
        if(this.state.showPreview){
            return (
                <div>
                <img
                onError={(e) => this.imageErrorHandler(e)}
                src={this.state.imageObject[0].preview}
                alt="Thumbnail"
                style={{
                    borderWidth: "1px",
                    width: "100px",
                    height: "100px",
                    borderRadius: "105px",
                    borderColor: materialTheme.COLORS.StrikeColor,
                    marginTop: 5,
                    objectFit:"cover"
                }}/>            
                   {this.renderDropZone()}
                </div>
                
            );
        }
        else
        {
            return (
                <div>
                <img
                onError={(e) => this.imageErrorHandler(e)}
                src={this.state.user.PhotoThumbnailURL}
                alt="Thumbnail"
                style={{
                    borderWidth: "1px",
                    width: "100px",
                    height: "100px",
                    borderRadius: "105px",
                    borderColor: materialTheme.COLORS.StrikeColor,
                    marginTop: 5,
                    objectFit:"cover"
                }}/>            
                   {this.renderDropZone()}
                </div>
            );
        }
    }
    render() {
        return (
            <div>
                   <Header pageName="profile" {...this.props} updateProfile={this.updateProfile} />
                   <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="signupdiv" className="contact profilesettingTop" style={{marginTop:85}}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 mx-auto" style={{ maxWidth: "500px" }}>                                              
                                <form className="php-email-form needs-validation" noValidate="" onSubmit={this.OnPressSave} id="RegistrationForm" style={{padding:15}}>
                                <div className="section-title">
                                        <h3>Settings</h3>
                                    </div>    
                                {this.renderImage()}
                                                                 
                                    <div className="form-group">
                                        <input type="text" name="" className="form-control" id="" placeholder="Name" value={this.state.user.ProfileName} onChange={this.onNameChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>                                    
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="" id="" placeholder="This is my style" value={this.state.user.Location} onChange={this.onLocationChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginBottom: "10px", marginLeft: "75px" }}>
                                        <Tab.Container id="genderbuttons" activeKey={this.state.selectedIndex} style={{ marginBottom: "10px" }} onSelect={this.handleIndexChange}>
                                            <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="0">Male</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1">Female</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2">Non-binary</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                    <div className="text-center"><button style={{ background: materialTheme.COLORS.StrikeColor, marginTop:12,width:212 }} className="btn btn-sm" type="submit">Save</button></div>                                    
                                </form>                                                                                      
                                <div className="text-center"><a onClick={()=>this.onPressDeleteAccount()} style={{  marginTop:10,color:"#ff8080" }} className="">Delete Content & Account</a></div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- End Contact Section --> */}
                </main>
                {this._renderReportActionSheetAndroid()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addUser: (user) => dispatch(addUserProfile(user)),
        logoutUser: () => dispatch(logout()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettingScreen);


