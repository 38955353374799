import { DISCOVER_ADD_POST_LIST,DISCOVER_PageCount } from '../actions/types';

const initialState = {
    postList: [],
    pageCount: 0
}

const discoverReducer = (state = initialState, action) => {
    switch (action.type) {  
      case DISCOVER_ADD_POST_LIST:
        return {
          ...state,
          postList: action.data,
        }
      case   DISCOVER_PageCount:
        return {
          ...state,
          pageCount: action.data,
        }
      default:
        return state;
    }
  }
  
  export default discoverReducer;