import React, { Component } from 'react';
import Loader from "react-loader-spinner";
//import { Link } from "react-router-dom";
import { Nav, Tab, } from 'react-bootstrap';
import materialTheme from '../theme/Theme'
import FlatList from 'flatlist-react';
import EmptyHomePlaceholder from './EmptyHomePlaceholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlay, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import "../Pages/Stylesheets/Home.css"
import '../Pages/Stylesheets/common.css'
import DiscoverService from '../services/discover'
import HomeService from '../services/home'
import Header from '../Components/Header'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery'

var width = 441;
var height = 956
var innerHeight = window.innerHeight
var imageHeight = width + 10;
var widthtoSet = (width - 50);
var dataXCorrection = 0
var dataYCorrection = 0
if(window.innerWidth < width){
    width = window.innerWidth;
    innerHeight = window.innerHeight;
    imageHeight = width + 50;
    widthtoSet =  (width - 20);
    dataXCorrection = 20
    dataYCorrection = 20
}
let reportedPost = '';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            posts: [],
            selectedIndex: "0",
            isLoading: false,
            refresh: false,
            PhotoThumbnailURL: "/images/defaultImage.jpg",
            pageNo: 2,
            pageNoBrand: 2,
            showEmptyPlaceHolder: false,
            isModalVisible: false,
            isReportActionSheetVisible: false,
            isReportTypeActionSheetVisible: false,
            refreshBottom: false,
            selectedId: null,
            isRewardModalVisible: false,
            showThumb: false,
            //hasMoreItems: false,  
            noMoreItems: false,
            topUsers: [],
            topBrands: [],            
        };
    }
    async componentDidMount() {

        this.setState({ ...this.state, isLoading: true })
        this._loadPostData(0);
        this.getTopUsers();
        this.getTopBrands();
        document.getElementById("flatList").addEventListener("scroll", () => this.scrollControll())
    }
    componentWillUnmount() {
        if (document.getElementById("flatList") != null && document.getElementById("flatList") != undefined) {
            document.getElementById("flatList").removeEventListener('scroll', () => this.scrollControll());
        }
    }
    getTopUsers = () => {
        DiscoverService.getTopUsers().then(response => {
            if (response.status === 200 && !response.data.error) {
               // console.log("response of topusers", response)
                this.setState({ topUsers: response.data.data.slice(0, 4) })
            }

        }).catch((e) => {
            console.log("error in top users", e);
        });
    }
    getTopBrands = () => {
        DiscoverService.getTrendingBrands().then(response => {
            if (response.status === 200 && !response.data.error) {
                let data = response.data.data.slice(0, 4)
                data.map(brand => {
                    DiscoverService.getBrandLogoAsync(brand.Name).then(logo => {
                        let brandItem = {
                            brandId: brand.Id,
                            Name: brand.Name,
                            urlLogo: logo,
                        }
                        if (brandItem.Name.trim() !== "") {
                            let brandList = [...this.state.topBrands, brandItem];
                            this.setState({ ...this.state, topBrands: brandList });
                        }
                    }).catch(console.log);
                });

                //this.setState({topBrands:response.data.data.slice(0,5)})
            }
        }).catch((e) => {
            console.log("error in top brands", e);
        });
    }
    renderTopUsers = () => {
        if (this.state.topUsers.length == 0) {
            return (<Loader type="Puff"
                color="#00BFFF"
                height={33}
                width={33}
                style={{ margin: "10px" }}
            />)
        }
        else {
            return this.state.topUsers.map((item, index) => {        
                let pieces = item.PhotoURL.split("/");
                let imageSource = "https://mespoke.cloud/api/users/CDN/" + pieces[pieces.length - 1]
                return (
                    <div key={index} className="d-flex justify-content-start">                                             
                        <Link to={ {pathname:"/profile", state:{loggedIn: false,profileId:item.Id} }}>
                                <img
                                    src={imageSource}
                                    alt="Thumbnail"
                                    onError={(e) => this.imageErrorHandler(e)}
                                    style={{
                                        borderWidth: "1px",
                                        width: "35px",
                                        height: "35px",
                                        borderRadius: "35px",                                        
                                        marginBottom: "10px"                                       
                                    }} />
                        </Link>                                                    
                        <div style={{marginLeft:5,marginTop:3}}>{item.Name}</div>                                              
                    </div>
                      
                )
            })
        }
    }
    renderTopBrands() {
        if (this.state.topBrands.length == 0) {
            return (<Loader type="Puff"
                color="#00BFFF"
                height={33}
                width={33}
                style={{ margin: "10px" }}
            />)
        }
        else {
            return this.state.topBrands.map((item, index) => {
                return (
                    <div key={index} className="d-flex justify-content-start">
                         <Link to={ {pathname:"/branddetail", state:{brandName: item.Name, brandId: item.brandId} }}>
                       <img
                            src={item.urlLogo}
                            onError={(e) => this.imageErrorHandler(e)}
                            alt="Thumbnail"
                            style={{
                                borderWidth: "1px",
                                width: "35px",
                                height: "35px",
                                borderRadius: "35px",                              
                                 marginBottom: "10px"
                            }} />
                      </Link>   
                        <div style={{marginLeft:5,marginTop:3}}>{item.Name}</div>

                    </div>)
            })
        }
    }
    scrollControll = () => {
        if (this.state.refreshBottom == false && this.state.noMoreItems == false) {
            if ($("#flatList")[0] != undefined) {
                if ($("#flatList").scrollTop() + $("#flatList").innerHeight() >= $("#flatList")[0].scrollHeight) {
                    this._loadMorePosts()
                }
            }
        }
    }

    _loadMorePosts = () => {

        if (this.state.selectedIndex == 0) {
            this.setState({
                ...this.state,
                pageNo: this.state.pageNo + 1, refreshBottom: true,
            })
        }
        else {
            this.setState({
                ...this.state,
                pageNoBrand: this.state.pageNoBrand + 1, refreshBottom: true,
            })
        }

        this._loadPostData(this.state.selectedIndex)
    }
    _loadPostData = (index) => {

        if (this.state.selectedIndex == 0) {
            this.getAllPosts(this.state.pageNo, index);
        } else {
            this.getBrandFollowedPosts(this.state.pageNoBrand, index);
        }
    }
    fetchPostData = (index) => {

        if (index == 0) {
            this.setState({ ...this.setState, isLoading: true, posts: [] });
            this.getAllPosts(this.state.pageNo, index);
        } else {
            this.setState({ ...this.setState, isLoading: true, posts: [] });
            this.getBrandFollowedPosts(this.state.pageNoBrand, index);
        }
    }
    getLogo = (brandName) => {
        return new Promise((resolve) => {
            DiscoverService.getBrandLogoAsync(brandName).then(logo => {
                resolve(logo);
            }).catch(console.log);
        });
    }
    getAllLogos = async (postData) => {
        let tempPostData = postData;
        for (let postIndex = 0; postIndex < tempPostData.length; postIndex++) {
            let tempPost = tempPostData[postIndex]
            for (let i = 0; i < tempPost.tagsList.length; i++) {
                const logo = await this.getLogo(tempPost.tagsList[i].BrandName)
                tempPost.tagsList[i] = { ...tempPost.tagsList[i], brandLogo: logo };
            }
        }
        return tempPostData
    }
    getAllPosts = (pageNo, index) => {
        if (index == 0) {
            HomeService.getPostList(pageNo).then(async response => {
                if (response.status == 200 && response.data) {
                    let posts = response.data.map(post => {
                        return {
                            imageUrl: post.ImageUrl,
                            videoUrl: post.videoUrl,
                            showVideo: false,
                            userProfile: {
                                userName: post.ProfileName,
                                userImage: post.PhotoThumbnailURL,
                                userID: post.ProfileId
                            },
                            postDate: post.PostingDate,
                            showTags: false,
                            isSaved: post.isSaved,
                            tagsList: [...post.Tags],
                            postId: post.Id,
                        };
                    });
                    let tempPostData = posts;
                    for (let postIndex = 0; postIndex < tempPostData.length; postIndex++) {
                        let tagXPosition = 5
                        let tempPost = tempPostData[postIndex]
                        for (let i = 0; i < tempPost.tagsList.length; i++) {
                            tempPost.tagsList[i] = { ...tempPost.tagsList[i], tagPosition: { x: tagXPosition, y: (imageHeight - 30) } };
                            tagXPosition = tagXPosition + 32
                        }
                    }
                    let finalPostData = await this.getAllLogos(tempPostData)
                    posts = finalPostData
                    let showEmptyPlaceHolder = posts.length > 0 ? false : true;
                    let noMoreItems = false
                    if (posts.length == this.state.posts.length) {
                        noMoreItems = true
                    }
                    this.setState({ ...this.state, showEmptyPlaceHolder, isLoading: false, posts, refresh: false, refreshBottom: false, hasMoreItems: false, noMoreItems: noMoreItems });
                } else {
                    this.setState({ ...this.state, showEmptyPlaceHolder: true, isLoading: false, posts: [], refresh: false, refreshBottom: false });
                }
            }).catch(e => console.log(e));
        }

    }
    getBrandFollowedPosts = (pageNo, index) => {

        if (index == 1) {
            HomeService.getFollowedBrandPostList(pageNo).then(async response => {
                if (response.status == 200 && response.data) {

                    let posts = response.data.map(post => {
                        return {
                            imageUrl: post.ImageUrl,
                            videoUrl: post.videoUrl,
                            showVideo: false,
                            userProfile: {
                                userName: post.ProfileName,
                                userImage: post.PhotoThumbnailURL,
                                userID: post.ProfileId
                            },
                            postDate: post.PostingDate,
                            showTags: false,
                            isSaved: post.isSaved,
                            tagsList: [...post.Tags],
                            postId: post.Id,
                        };
                    });

                    let tempPostData = posts;
                    for (let postIndex = 0; postIndex < tempPostData.length; postIndex++) {
                        let tagXPosition = 5
                        let tempPost = tempPostData[postIndex]
                        for (let i = 0; i < tempPost.tagsList.length; i++) {
                            tempPost.tagsList[i] = { ...tempPost.tagsList[i], tagPosition: { x: tagXPosition, y: (imageHeight - 30) } };
                            tagXPosition = tagXPosition + 32
                        }
                    }
                    let finalPostData = await this.getAllLogos(tempPostData)
                    posts = finalPostData
                    let showEmptyPlaceHolder = posts.length > 0 ? false : true;
                    let noMoreItems = false
                    if (posts.length == this.state.posts.length) {
                        noMoreItems = true
                    }
                    this.setState({ ...this.state, showEmptyPlaceHolder, isLoading: false, posts, refresh: false, refreshBottom: false, hasMoreItems: false, noMoreItems: noMoreItems });
                } else {
                    this.setState({ ...this.state, showEmptyPlaceHolder: true, isLoading: false, posts: [], refresh: false, refreshBottom: false });
                }
            }).catch(e => console.log(e));
        }

    }
    shopBrands = (tagId, brandUrl, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey, e) => {
        e.stopPropagation();
        HomeService.shopBrands(tagId, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey).then(response => {
        }).catch(e => console.log(e));
        window.open(brandUrl, "_blank");
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg"
    }
    renderBrandsOnBottomFixed = (tagsList) => {

        return tagsList.map((data, index) => {
            return (
                <div className="d-flex flex-row transform BrandsOnBottomFixed" style={{ position: 'absolute', top: data.tagPosition.y, left: data.tagPosition.x, backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                    <img onError={(e) => this.imageErrorHandler(e)} className="border" alt='' key={index} onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                        src={data.brandLogo}
                        style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                </div>
            )
        })
    }
    renderBrandsOnSpecifiedPosition = (tagsList) => {
        return tagsList.map((data, index) => {
            let tagTopPos = Number(data.Y);
            if (tagTopPos > (imageHeight - 30)) {
                tagTopPos = imageHeight - 40
            }
            if (width > (Number(data.X) + 120)) {
                return (
                    <div className="d-flex flex-row transform BrandsOnSpecifiedPosition" style={{ position: 'absolute', top: Number(tagTopPos-dataYCorrection) , left: Number(data.X-dataXCorrection), backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                        <img onError={(e) => this.imageErrorHandler(e)} className="border" alt='' key={index} onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                            src={data.brandLogo}
                            style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                        <div style={{ height: 30 }}>
                            <div style={{ margin: 5, marginRight: 15, color: 'black', fontSize: 12,whiteSpace:"nowrap" }}>{data.catName}</div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="d-flex flex-row transform BrandsOnSpecifiedPosition" style={{ position: 'absolute', top: Number(tagTopPos-dataYCorrection), left: (Number(data.X-dataXCorrection) - 120), backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                        <div style={{ height: 30 }}>
                            <div style={{ margin: 5, marginLeft: 15, color: 'black', fontSize: 12,whiteSpace:"nowrap" }}>{data.catName}</div>
                        </div>
                        <img key={index}
                            onError={(e) => this.imageErrorHandler(e)}
                            className="border"
                            alt='...'
                            onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                            src={data.brandLogo}
                            style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                    </div>
                )
            }
        })
    }
    _renderBrands = (tagsList, showTags) => {
        if (showTags) {
            return (
                this.renderBrandsOnSpecifiedPosition(tagsList)
            );
        } else {
            return (
                this.renderBrandsOnBottomFixed(tagsList)
            );
        }
    }
    handleImageTapped = (index) => {
        let postData = this.state.posts[index]
        if (!postData.showTags) {
            for (let i = 0; i < postData.tagsList.length; i++) {
                let data = postData.tagsList[i]
                let tagYPos = Number(data.Y);
                let tagXPos = Number(data.X - dataXCorrection);
                if (tagYPos > (imageHeight - 30)) {
                    tagYPos = imageHeight - 40
                }
                if (width < (tagXPos + 120)) {
                    tagXPos = (Number(data.X) - 120)
                }
                window.hometransformToPostion(tagXPos, Number(tagYPos-dataYCorrection), i, index)
            }
            setTimeout(() => {
                let tempArray = [...this.state.posts];
                tempArray[index] = { ...tempArray[index], showTags: !this.state.posts[index].showTags };
                this.setState({ posts: tempArray, selectedId: index });
            }, 1000);
        } else {
            let tagXPosition = 5
            for (let i = 0; i < postData.tagsList.length; i++) {
                window.hometransformToBottom(tagXPosition, imageHeight - 30, i, index)
                tagXPosition = tagXPosition + 32

            }
            setTimeout(() => {
                let tempArray = [...this.state.posts];
                tempArray[index] = { ...tempArray[index], showTags: !this.state.posts[index].showTags };
                this.setState({ posts: tempArray, selectedId: index });
            }, 1000);
        }
    }
    togglePostSavedStatus = (post, index, e) => {
        e.stopPropagation()
        HomeService.togglePostSavedStatus(post).then(response => {
            if (response.status == 200 && response.data) {
                if (response.data[0].status == 'success') {
                    let tempPostArray = [...this.state.posts];
                    tempPostArray[index] = { ...tempPostArray[index], isSaved: !post.isSaved };
                    this.setState({
                        ...this.state,
                        isModalVisible: !post.isSaved,
                        posts: tempPostArray,
                    });
                }
            }
        }).catch(e => console.log(e));
    }
    _renderPostSavedStatus = (post, index) => {
        if (post.isSaved) {
            return (
                <img onError={(e) => this.imageErrorHandler(e)} onClick={(e) => this.togglePostSavedStatus(post, index, e)} style={{ width: 40, height: 40, borderRadius: 20 }}
                    alt=""
                    src={"/images/Favorite.png"}
                ></img>
            );
        } else {
            return (
                <img onError={(e) => this.imageErrorHandler(e)} onClick={(e) => this.togglePostSavedStatus(post, index, e)}
                    alt=""
                    src={"/images/UnFavorite.png"}
                    style={{ borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 40, height: 40, borderRadius: 20 }}></img>
            );
        }
    }
    _renderData = (item, index) => {
        if (item.videoUrl == null) {
            return (
                <div homepost={index} key={index} className="container" style={{ cursor: "pointer", marginBottom:10 }}>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-md-6" style={{ backgroundColor: "white" }}>
                            <div style={{ boarder: "none" }}>
                                <div className="d-flex justify-content-between p-2 px-3">
                                    <div className="d-flex flex-row align-items-center"><Link to={{ pathname: "/profile", state: { loggedIn: false, profileId: item.userProfile.userID } }}><img onError={(e) => this.imageErrorHandler(e)} alt="" src={item.userProfile.userImage} width="50" height="50" className="rounded-circle"></img></Link>
                                        <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{item.userProfile.userName}</span></div>
                                    </div>
                                    <div className="d-flex flex-row mt-1"><small style={{ marginTop: "10px" }} className="mr-2">{item.postDate}</small></div>
                                </div>
                                <div key={index} style={{
                                    position: "relative",
                                    backgroundImage: `url("${item.imageUrl}")`,
                                    width: widthtoSet, height: imageHeight, backgroundColor: 'rgb(247, 247, 247)', borderRadius: 10, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    display: 'inline-block'
                                }}
                                    onClick={() => this.handleImageTapped(index)}
                                >
                                    {this._renderBrands(item.tagsList, item.showTags)}
                                </div>
                                <div className="d-flex flex-row-reverse">
                                    <div onClick={(e) => this._renderAndroidActioSheet(item, e)} style={{ backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20, marginLeft: 5 }}>
                                        <FontAwesomeIcon icon={faEllipsisH} style={{ color: "white", marginTop: "11px" }} />
                                    </div>
                                    {this._renderPostSavedStatus(item, index)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            if (item.showVideo) {
                return (
                    <div homepost={index} key={index} className="container" style={{ cursor: "pointer", marginBottom:10 }}>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6" style={{ backgroundColor: "white" }}>
                                <div style={{ boarder: "none" }}>
                                    <div className="d-flex justify-content-between p-2 px-3">
                                        <div className="d-flex flex-row align-items-center"><Link to={{ pathname: "/profile", state: { loggedIn: false, profileId: item.userProfile.userID } }}><img onError={(e) => this.imageErrorHandler(e)} alt="" src={item.userProfile.userImage} width="50" height="50" className="rounded-circle"></img></Link>
                                            <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{item.userProfile.userName}</span></div>
                                        </div>
                                        <div className="d-flex flex-row mt-1"><small style={{ marginTop: "10px" }} className="mr-2">{item.postDate}</small></div>
                                    </div>
                                    <div key={index} style={{
                                        position: "relative",
                                        width: widthtoSet, height: imageHeight, backgroundColor: 'white', borderRadius: 10, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        display: 'inline-block'
                                    }}
                                    >
                                        <video id="video" videoindex={index} controls muted autoPlay width={widthtoSet} height={imageHeight} disablePictureInPicture controlsList="nodownload" onLoadedMetadata={(e) => this.videoLoaded(e, index, false)}>
                                            <source src={item.videoUrl}>
                                            </source>
                                        </video>
                                    </div>
                                    <div className="d-flex flex-row-reverse">
                                        <div onClick={(e) => this._renderAndroidActioSheet(item, e)} style={{ backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20, marginLeft: 5 }}>
                                            <FontAwesomeIcon icon={faEllipsisH} style={{ color: "white", marginTop: "11px" }} />
                                        </div>
                                        {this._renderPostSavedStatus(item, index)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div homepost={index} key={index} className="container" style={{ cursor: "pointer" , marginBottom:10}}>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6" style={{ backgroundColor: "white" }}>
                                <div style={{ boarder: "none" }}>
                                    <div className="d-flex justify-content-between p-2 px-3">
                                        <div className="d-flex flex-row align-items-center"><Link to={{ pathname: "/profile", state: { loggedIn: false, profileId: item.userProfile.userID } }}><img onError={(e) => this.imageErrorHandler(e)} alt="" src={item.userProfile.userImage} width="50" height="50" className="rounded-circle"></img></Link>
                                            <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{item.userProfile.userName}</span></div>
                                        </div>
                                        <div className="d-flex flex-row mt-1"><small style={{ marginTop: "10px" }} className="mr-2">{item.postDate}</small></div>
                                    </div>
                                    <div key={index} style={{
                                        position: "relative",
                                        backgroundImage: `url("${item.imageUrl}")`,
                                        width: widthtoSet, height: imageHeight, backgroundColor: 'white', borderRadius: 10, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        display: 'inline-block'
                                    }}
                                        onClick={() => this.handleImageTapped(index)}
                                    >
                                        {this._renderBrands(item.tagsList, item.showTags)}
                                    </div>
                                    <div className="d-flex flex-row-reverse">
                                        <div onClick={(e) => this._renderAndroidActioSheet(item, e)} style={{ backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20 }}>
                                            <FontAwesomeIcon icon={faEllipsisH} style={{ color: "white", marginTop: "11px" }} />
                                        </div>
                                        <div onClick={(e) => this._renderthumb(e, index, true)} style={{ backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20, marginLeft: 5, marginRight: 5 }}>
                                            <FontAwesomeIcon icon={faPlay} style={{ color: "white", marginTop: "11px", marginLeft: '6px' }} />
                                        </div>
                                        {this._renderPostSavedStatus(item, index)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        }

    }
    videoLoaded = (e, index, show) => {
        e.stopPropagation()
        const video = document.querySelector("[videoindex='" + index + "']");
        if (video != null) {
            video.addEventListener('pause', (event) => {
                var vidState = video.readyState
                if (vidState == 4) {
                    let items = [...this.state.posts];
                    let item = { ...items[index] };
                    item.showVideo = show;
                    items[index] = item;
                    this.setState({
                        ...this.state,
                        posts: items,
                    });
                }
            });
        }
    }
    _renderthumb = (e, index, show) => {
        e.stopPropagation()
        let items = [...this.state.posts];
        let item = { ...items[index] };
        item.showVideo = show;
        items[index] = item;
        this.setState({
            ...this.state,
            posts: items,
        });
    }
    _showLoader = () => {
        if (this.state.refreshBottom) {
            return (
                <div id="refreshBottom" style={{ height: "100px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
            )
        }
    }

    _renderHomeContents = () => {
        if (this.state.isLoading) {
            return (
                <div style={{ height: "450px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
            );
        } else {
            if (this.state.showEmptyPlaceHolder) {
                return (
                    <EmptyHomePlaceholder />
                );
            } else {
                let data = this.state.posts
                return (
                    <div id="flatList" style={{ height: (innerHeight - 172), overflowY: "scroll" }}>
                        <FlatList
                            extraData={this.state}
                            renderItem={this._renderData}
                            list={data}
                        />
                        {this._showLoader()}
                    </div>
                );
            }
        }
    }
    _renderSegmentedControl = () => {

        return (
            <section style={{ backgroundColor: "#fafafa" }} className="breadcrumbs">
                <div className="me-auto">
                    <Tab.Container id="homesegment" defaultActiveKey={this.state.selectedIndex} onSelect={this.handleIndexChange}>
                        <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                            <Nav.Item>
                                <Nav.Link eventKey="0">Following</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="1">Brands</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Tab.Container>
                </div>
            </section>
        );
    }

    handleIndexChange = index => {
        var elmnt = document.getElementById("flatList");
        if (elmnt != null && elmnt != undefined) {
            elmnt.scrollTo(0, 0)
        }
        this.setState({
            ...this.state,
            selectedIndex: index,
            pageNo: 2,
            pageNoBrand: 2,
            posts: []
        });
        this.fetchPostData(index);
    }

    _reportPost = (reportType) => {
        this.cancelDialog();
        HomeService.reportPost(reportedPost, reportType).then(response => {
            if (response.status == 200 && response.data) {

            }
        }).catch(e => console.log(e));
    }

    setModalVisible = (isVisible) => {     
        this.setState({
            ...this.state,
            isModalVisible: isVisible,
        });
    }
    toggleReportActionSheetVisibility = (isVisible) => {

        this.setState({
            ...this.state,
            isReportActionSheetVisible: isVisible,
        });
    }
    toggleReportTypeAndroidActionSheetVisibility = (isVisible) => {

        this.setState({
            ...this.state,
            isReportTypeActionSheetVisible: isVisible,
        });
    }
    _renderAndroidActioSheet = (item, e) => {
        e.stopPropagation()
        reportedPost = item;
        this.toggleReportActionSheetVisibility(true)
    }

    showReportTypeDialog = () => {
        this.toggleReportTypeAndroidActionSheetVisibility(true);
    }

    _renderReportActionSheetAndroid = () => {

        return (
            <div>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                    <div className="border-bottom">
                        <p>Choose option</p>
                    </div>
                    <div className="border-bottom" onClick={() => this.showReportTypeDialog()}>
                        <h5 onClick={() => this.showReportTypeDialog()} style={{ color: '#047cff', cursor: "pointer", marginTop: "8px" }}>Report</h5>
                    </div>
                    <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
                </Modal.Body>
            </div>
        );
    }

    cancelDialog = () => {

        this.setState({
            ...this.state,
            isModalVisible: false,
            isReportActionSheetVisible: false,
            isReportTypeActionSheetVisible: false,
        })
    }
    _renderChooseReportTypeActionSheetAndroid = () => {

        return (
            <div>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                    <div className="border-bottom">
                        <p>Report</p>
                    </div>
                    <div className="border-bottom" onClick={() => this._reportPost("Nudity")}>
                        <h5 style={{ color: '#047cff', cursor: "pointer", marginTop: "8px" }}>Nudity</h5>
                    </div>
                    <div className="border-bottom" onClick={() => this._reportPost("Inappropriate")}>
                        <h5 center style={{ color: '#047cff', cursor: "pointer", marginTop: "8px" }}>Inappropriate</h5>
                    </div>
                    <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
                </Modal.Body>
            </div>
        );
    }

    _renderRewardSceenModal = () => {

        return (
            <Modal
                transparent={false}
                animationType="slide"
                presentationStyle="pageSheet"
                visible={this.state.isRewardModalVisible}
                onRequestClose={() => this.setState({ isRewardModalVisible: false })}
            >
                <div>RewardScreen</div>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                <Header pageName="home" {...this.props} />
                {this._renderSegmentedControl()}
                <div id="suggestion" className="absolute-right" style={{ position: "absolute",zIndex:10 }}>
                    <div className="border" style={{borderRadius:"4px",padding:"10px", marginBottom:15, width:"260px",backgroundColor:"white"}}>
                    <div className="row" style={{ color: "grey",marginBottom:10, paddingLeft:"10px",fontSize:20}}>Top Users</div>
                    <div className="topSuggestions" style={{}}>
                        {this.renderTopUsers()}
                    </div>
                    </div>
                    <div className="border" style={{borderRadius:"4px", padding:"10px",backgroundColor:"white"}}>
                    <div className="row" style={{ color: "grey",marginBottom:10,paddingLeft:"10px",fontSize:20 }}>Top Brands </div>
                    <div className="topBrands" style={{}}>
                        {this.renderTopBrands()}
                    </div>
                    </div>
                </div>
                <main id="main" style={{ background: "#fafafa" }}>
                    <section id="homeSection" className="inner-page" style={{paddingTop:0}}>
                        {this._renderHomeContents()}
                    </section>
                    {/* <div style="">
                        Top Brands and users
                    </div> */}
                    <Modal size="sm" show={this.state.isReportActionSheetVisible} centered>
                        {this.state.isReportTypeActionSheetVisible ? (
                            this._renderChooseReportTypeActionSheetAndroid()
                        ) : (
                                this._renderReportActionSheetAndroid()
                            )}
                    </Modal>
                <Modal size="sm" show={this.state.showHideBrands}>
                    {/* {this._renderCollectionConfirmationDialog()}                     */}
                </Modal>                
                {/* this.state.isModalVisible */}
                <Modal id="likedmodel" className={"centerModel"} size="sm" show={this.state.isModalVisible} >
                    <Modal.Header  style={{ backgroundColor: materialTheme.COLORS.PrimaryDark,height:85,paddingTop:0}}>    
                    <p style={{ fontSize: 20, fontWeight: 'bold', color: 'white',marginBottom:0 }}>Added to Your Collection!</p>                   
                        <button style={{ color: 'white'}}  onClick={() => this.setModalVisible(false)} type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{padding:0}}>
                    <div id="imageofHeart">
                    <img
                            style={{paddingBottomL:"-4px",marginTop:"-30px"}}
                            onError={(e) => this.imageErrorHandler(e)}
                            alt=""
                            resizeMode="cover"
                            src={"/images/Favorite.png"}                                                    
                            ></img>                                                                    
                    </div>
                    <div style={{ borderBottomRightRadius: 5, borderBottomLeftRadius: 5, backgroundColor: materialTheme.COLORS.WHITE }}>
                            <p style={{ textAlign: 'center', fontSize: 15, color: materialTheme.COLORS.PrimaryDarkLightColor,marginBottom:10}}>You can view your collections on your profile page.</p>
                        </div>  
                    </Modal.Body>
                </Modal>
                </main>
            </div>
        );
    }
}
export default Home;