import React from 'react'
import UserService from '../services/user'
import SearchField from 'react-search-field';
import materialTheme from '../theme/Theme'
import { Link } from 'react-router-dom';
export default class NameSearchScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            search: '',
            users: [],
            typingTimeout: 0
        }
    }
    async componentDidMount() {
        this.searchUser(this.state.search);
        //await Font.loadAsync({ Rubik_Regular: require('../../../assets/fonts/Rubik_Regular.ttf')})
    }
    searchUser = (searchtext) => {
        UserService.searchUsers(searchtext).then(response => {
            let users = response.data.map(elem => {
                return {
                    key: elem.ProfileId,
                    name: elem.ProfileName,
                    image: elem.PhotoThumbnailURL.length === 0 || elem.PhotoThumbnailURL == "null" ? "/images/defaultImage.jpg" : elem.PhotoThumbnailURL
                };
            });
            this.setState({ ...this.state, users });
        }).catch(e => console.log(e));
    }
    // handleUserTapped = (user) => {
    //onClick={() => this.handleUserTapped(user)}
    //     this.props.closeModal()
    //     const { history } = this.props;
    //     history.push({
    //         pathname: '/dummyprofile',
    //         state: { profileId: user.key, isLoggedInUser: false }
    //     })
    // }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    renderUsers = () => {
        return this.state.users.map((user, index) => {
    
            return (
                <div className="border-bottom changeBackgroundColorOnHover marginTop10" key={index} style={{ borderBottomColor: 'gray', borderBottomWidth: 1, width: "100%", height: 90, cursor: "pointer" }}>
                    <Link to={ {pathname:"/profile", state:{loggedIn: false,profileId:user.key} }}>
                    <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={user.image} className="border border-dark rounded-circle" key={index}
                        style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 70, height: 70, borderRadius: 25 }} />
                    </Link>                    
                    <label style={{ marginLeft: "20px" }}>{user.name}</label>
                </div>
            )
        })
    }
    onChangeSearch = (search) => {

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            ...this.state, search, typingTimeout: setTimeout(() => {
                this.searchUser(search);
            }, 1000)
        });
    };
    _renderSearchBar = () => {
        return (<div>
            <SearchField
                placeholder='Search by Brands'
                onChange={this.onChangeSearch}
                classNames="searchInput rounded"
            />
        </div>)
    }
    render() {
        return (
            <div backgroundColor='white'>
                {this._renderSearchBar()}
                <div>{this.renderUsers()}</div>
            </div>
        );
    }
}