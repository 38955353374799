import React from 'react'
import UserService from '../services/user'
import DiscoverService from '../services/discover'
import HomeService from '../services/home'
import { connect } from 'react-redux';
import { deletePost } from '../store/actions/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import materialTheme from '../theme/Theme'
import { Link } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
const width = 441;
const height = 956;
const imageHeight = width + 10

class SinglePostScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedIndex: 0,
            PhotoThumbnailURL: "/images/defaultImage.jpg",
            postData: null,
            isModalVisible: false,
            isReportActionSheetVisible: false,
            isReportTypeActionSheetVisible: false,
            isDeletePostActionSheetVisible: false,
            currentUser: null,          
            postId: '',
            showUserProfileModal: false,
            selectedUserProfileiD: null
        };
    }
    async componentDidMount() {
          ;
        let postID = ""
        if(this.props.postData != undefined){
            postID = this.props.postData.photo.PostId
        }
        else
        {
            postID = this.props.location.state.postId
        }
        
        // if (!postID) {
        //   const { route } = this.props;
        //   postID = route.params.post.PostId
        // } 
        this.setState({ postId: postID })
        this._getSinglePost(postID);
        this._getCurrentUser();
    }
    _getCurrentUser = () => {
        const promise = new Promise((resolutionFunc, rejectionFunc) => {
            resolutionFunc(UserService.getCurrentUserProfile());
        });
        promise.then((user) => this.setState({
            ...this.state,
            currentUser: user
        }));
    }
    isPostOwner = () => {
        if (this.state.currentUser == null) {
            return false
        }
        if (this.state.currentUser.ProfileId == this.state.postData.userProfile.userID) {
            return true;
        } else {
            return false;
        }
    }
    getLogo = (brandName) => {
        return new Promise((resolve) => {
            DiscoverService.getBrandLogoAsync(brandName).then(logo => {
                resolve(logo);
            }).catch(console.log);
        });
    }
    getAllLogos = async (postData) => {
        let tempPostData = postData;
        for (let i = 0; i < postData.tagsList.length; i++) {
            const logo = await this.getLogo(postData.tagsList[i].BrandName)
            tempPostData.tagsList[i] = { ...tempPostData.tagsList[i], brandLogo: logo };
        }
        return tempPostData
    }
    _getSinglePost = async (postId) => {        
        UserService.getSinglePost(postId).then(async response => {
            if (response.status === 200 && response.data.length > 0) {
                let post = response.data[0];
                let postData = {
                    imageUrl: post.ImageUrl,
                    userProfile: {
                        userName: post.ProfileName,
                        userImage: post.PhotoThumbnailURL,
                        userID: post.ProfileId
                    },
                    postDate: post.PostingDate,
                    showTags: false,
                    isSaved: post.isSaved,
                    tagsList: [...post.Tags],
                    postId: post.Id,
                };
                let tempPostData = postData;               
                let tagXPosition = 5
                for (let i = 0; i < postData.tagsList.length; i++) {
                  tempPostData.tagsList[i] = { ...tempPostData.tagsList[i], tagPosition: { x: tagXPosition, y: (imageHeight - 30) } };
                   tagXPosition = tagXPosition + 32
                }
                let finalPostData = await this.getAllLogos(tempPostData)
                postData = finalPostData
                this.setState({ ...this.state, postData: postData });
            }
        }).catch(console.log);
    }
    deletePost = () => {
        const { route, navigation } = this.props;
        UserService.deletePost(this.state.postId).then(response => {
            if (response.status === 200 && response.data.length > 0) {
                this.cancelDialog();
                this.props.delete(this.state.postId)
                if (!this.props.PostId) {
                    navigation.navigate('ProfileLoggedinUser');
                } else {
                    this.props.closeProfileModal()
                }
            }
        }).catch(console.log);
    }

    shopBrands = (tagId, brandUrl, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey,e) => {
        e.stopPropagation()
        HomeService.shopBrands(tagId, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey).then(response => {
        }).catch(e => console.log(e));        
        window.open(brandUrl, '_blank');
    }
    renderBrandsOnBottomFixed = (tagsList) => {     
        return tagsList.map((data, index) => {
            return (
                <div className="d-flex flex-row transform BrandsOnBottomFixed" style={{ position: 'absolute', top: data.tagPosition.y, left: data.tagPosition.x, backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                    <img className="border" alt='' key={index} onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                        src={data.brandLogo}
                        style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                </div>
            )
        })
    }
    renderBrandsOnSpecifiedPosition = (tagsList) => {
        return tagsList.map((data, index) => {
            let tagTopPos = Number(data.Y);
            if (tagTopPos > (imageHeight - 30)) {
                tagTopPos = imageHeight - 40
            }
            if (width > (Number(data.X) + 120)) {
                return (
                    <div className="d-flex flex-row transform BrandsOnSpecifiedPosition" style={{ position: 'absolute', top: tagTopPos, left: Number(data.X), backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                        <img className="border" alt='' key={index} onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                            src={data.brandLogo}
                            style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                        <div style={{ height: 30 }}>
                            <div style={{ margin: 5, marginRight: 15, color: 'black', fontSize: 12 }}>{data.catName}</div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="d-flex flex-row transform BrandsOnSpecifiedPosition" style={{ position: 'absolute', top: tagTopPos, left: (Number(data.X) - 120), backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                        <div style={{ height: 30 }}>
                            <div style={{ margin: 5, marginLeft: 15, color: 'black', fontSize: 12 }}>{data.catName}</div>
                        </div>
                        <img key={index}
                            className="border"
                            alt='...'
                            onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                            src={data.brandLogo}
                            style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                    </div>
                )
            }
        })
    }
    _renderPostImages = () => {     
        const item = this.state.postData;
        if (!item) return;
        return (                  
            <div post={0} key={0} className="container mt-3 mb-3" style={{ cursor: "pointer" }}>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-6" style={{ backgroundColor: "white" }}>
                        <div style={{ boarder: "none" }}>
                            <div className="d-flex justify-content-between p-2 px-3">
                                <div className="d-flex flex-row align-items-center"><Link to=""><img alt="" src={item.userProfile.userImage} width="50" height="50"  className="rounded-circle"></img></Link>
                                    <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{item.userProfile.userName}</span></div>
                                </div>
                                <div className="d-flex flex-row mt-1"><small style={{ marginTop: "10px" }} className="mr-2">{item.postDate}</small></div>
                            </div>
                            <div key={0} style={{
                                position: "relative",
                                backgroundImage: `url("${item.imageUrl}")`,
                                width: (width - 50), height: imageHeight, backgroundColor: '#F7F7F7', borderRadius: 10, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                display: 'inline-block'
                            }}
                                onClick={() => this.handleImageTapped()}
                            >
                                {this._renderBrands(item.tagsList, item.showTags)}
                                <div style={{ position: "absolute", left: (width - 150), top: imageHeight - 40 }} >
                                    {this._renderPostSavedStatus(item)}
                                </div>
                                <div onClick={(e) => this._renderActionSheet(e)} style={{ position: "absolute", backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20, left: (width - 100), top: imageHeight - 40 }}>
                                    <FontAwesomeIcon icon={faEllipsisH} style={{ color: "white", marginTop: "11px" }} />
                                </div>
                            </div>
                            {/* {this._renderBrands(item.tagsList, item.showTags)} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    _renderBrands = (tagsList, showTags) => {
        if (showTags) {
            return (
                this.renderBrandsOnSpecifiedPosition(tagsList)
            );
        } else {
            return (
                this.renderBrandsOnBottomFixed(tagsList)
            );
        }
    }
    handleImageTapped = () => {
        let postData = this.state.postData
        if(!postData.showTags) {
          for (let i=0;i<postData.tagsList.length;i++){
            let data = postData.tagsList[i]
            let tagYPos = Number(data.Y);
            let tagXPos = Number(data.X);
            if(tagYPos > (imageHeight - 30)){
              tagYPos = imageHeight - 40
            }
            if(width < (tagXPos + 120)) {
              tagXPos = (Number(data.X) - 120)
            }
            window.transformToPostion(tagXPos, tagYPos, i, 0)
          }
          setTimeout(() => {
            let tempObject = { ...this.state.postData, showTags: !this.state.postData.showTags };
            this.setState({ postData: tempObject });
          }, 1000);
        } else {
          let tagXPosition = 5
          for (let i=0;i<postData.tagsList.length;i++){
            window.transformToBottom(tagXPosition, imageHeight - 30, i, 0)
            tagXPosition = tagXPosition + 32
        }
        setTimeout(() => {
          let tempObject = { ...this.state.postData, showTags: !this.state.postData.showTags };
          this.setState({ postData: tempObject });
        }, 1000);

        }
    }
    togglePostSavedStatus = (post,e) => {
        e.stopPropagation()
        HomeService.togglePostSavedStatus(post).then(response => {
            if (response.status == 200 && response.data) {
                if (response.data[0].status == 'success') {
                    const updatedPost = { ...this.state.postData, isSaved: !post.isSaved }

                    this.setState({
                        ...this.state,
                        isModalVisible: !post.isSaved,
                        postData: updatedPost

                    });
                }
            }
        }).catch(e => console.log(e));
    }
    _renderPostSavedStatus = (post) => {
        if (!this.isPostOwner()) {
            if (post.isSaved) {
                return (
                    <img onClick={(e) => this.togglePostSavedStatus(post, e)} style={{ position: 'absolute', width: 40, height: 40, borderRadius: 20 }}
                    alt=""
                    src={"/images/Favorite.png"}
                ></img>
                );
            } else {
                return (
                    <img onClick={(e) => this.togglePostSavedStatus(post, e)}
                    alt=""
                    src={"/images/UnFavorite.png"}
                    style={{ position: 'absolute', borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 40, height: 40, borderRadius: 20 }}></img>
                );
            }
        }
    }
    
    _reportPost = (reportType) => {
        this.cancelDialog();
        const reportedPost = this.state.postData;
        HomeService.reportPost(reportedPost, reportType).then(response => {
            if (response.status == 200 && response.data) {
                console.log(response);
            }
        }).catch(e => console.log(e));
    }

    setModalVisible = (isVisible) => {
        this.setState({
            ...this.state,
            isModalVisible: isVisible,
        });
    }
    toggleDeletePostActionSheetVisibility = (isVisible) => {
        this.setState({
            ...this.state,
            isDeletePostActionSheetVisible: isVisible,
        });
    }
    toggleReportActionSheetVisibility = (isVisible) => {
        this.setState({
            ...this.state,
            isReportActionSheetVisible: isVisible,
        });
    }
    toggleReportTypeAndroidActionSheetVisibility = (isVisible) => {
        this.setState({
            ...this.state,
            isReportTypeActionSheetVisible: isVisible,
        });
    }
    _renderAndroidActioSheet = () => {
         this.toggleReportActionSheetVisibility(true)
    }
    _renderIOSActionSheet = () => {
        // ActionSheetIOS.showActionSheetWithOptions(
        //   {
        //     title: 'Choose option',
        //     options: ["Cancel", "Report"],
        //     cancelButtonIndex: 0
        //   },
        //   buttonIndex => {
        //     if (buttonIndex === 0) {
        //     } else if (buttonIndex === 1) {
        //       this._renderReportTypeActionSheetIOS();
        //     }
        //   }
        // );
    }
    _renderReportTypeActionSheetIOS = () => {
        // ActionSheetIOS.showActionSheetWithOptions(
        //   {
        //     title: 'Report',
        //     options: ["Cancel", "Nudity", "Inappropriate"],
        //     cancelButtonIndex: 0
        //   },
        //   buttonIndex => {
        //     if (buttonIndex === 0) {
        //     } else if (buttonIndex === 1) {
        //       this._reportPost("Nudity")
        //     } else {
        //       this._reportPost("Inappropriate")
        //     }
        //   }
        // );
    }

    _renderDeleteActionSheetIOS = () => {
        // ActionSheetIOS.showActionSheetWithOptions(
        //   {
        //     title: 'Choose option',
        //     options: ["Cancel", "Delete"],
        //     cancelButtonIndex: 0,
        //     destructiveButtonIndex: 1,
        //   },
        //   buttonIndex => {
        //     if (buttonIndex === 0) {
        //     } else if (buttonIndex === 1) {
        //       this.deletePost();
        //     }
        //   }
        // );
    }
    showDeleteActionSheetAndroid = () => {
        this.toggleDeletePostActionSheetVisibility(true);
    }
    _renderDeleteActionSheetAndroid = () => {
        return (          
            <Modal size="sm" show={this.state.isDeletePostActionSheetVisible} centered>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
            <div className="border-bottom">
                <p>Choose option</p>
            </div>
            <div className="border-bottom" onClick={() => this.showReportTypeDialog()}>
                <h5 onClick={() =>this.deletePost()} style={{ color: 'red',cursor:"pointer" ,marginTop:"8px"  }}>Delete</h5>
            </div>            
            <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
        </Modal.Body>
            </Modal>
        );
    }

    _renderActionSheet = (e) => {
        e.stopPropagation()
        if (this.isPostOwner()) {
         this.showDeleteActionSheetAndroid()
        } else {
        this._renderAndroidActioSheet()
        }
      }
    _renderCollectionConfirmationDialog = () => {
        return (
            //   <Block>
            //     <Modal onBackdropPress={() => this.setModalVisible(false)} transparent={true} backgroundColor='transparent' isVisible={this.state.isModalVisible}>
            //       <Block>
            //         <Block middle style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, height: 100, backgroundColor: materialTheme.COLORS.PrimaryDark }}>
            //           <Text center style={{ fontSize: 20, marginBottom: 30, fontWeight: 'bold', color: 'white', fontFamily: 'Rubik_Regular' }}>Added to Your Collection!</Text>
            //         </Block>
            //         <Block middle style={{ borderBottomRightRadius: 5, borderBottomLeftRadius: 5, height: 100, backgroundColor: materialTheme.COLORS.WHITE }}>
            //           <Text center style={{ textAlign: 'center', fontSize: 15, marginTop: 30, marginRight: 40, marginLeft: 40, color: materialTheme.COLORS.PrimaryDarkLightColor, fontFamily: 'Rubik_Regular' }}>You can view your collections on your profile page.</Text>
            //         </Block>
            //         <Image
            //           resizeMode="cover"
            //           source={require('../../../assets/images/Favorite.png')}
            //           style={{ position: 'absolute', top: 65, alignSelf: 'center', width: 70, height: 70, borderRadius: 35 }} />
            //       </Block>
            //     </Modal>
            //   </Block>
            <div></div>
        );
    }

    showReportTypeDialog = () => {
        this.toggleReportTypeAndroidActionSheetVisibility(true);
        //this.toggleReportActionSheetVisibility(false);
        //setTimeout(() => {
        //    this.toggleReportTypeAndroidActionSheetVisibility(true);
        //}, 1000);
    }


    _renderReportActionSheetAndroid = () => {
        return (
            <div>
            <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
            <div className="border-bottom">
                <p>Choose option</p>
            </div>
            <div className="border-bottom" onClick={() => this.showReportTypeDialog()}>
                <h5 onClick={() => this.showReportTypeDialog()} style={{ color: '#047cff',cursor:"pointer" ,marginTop:"8px"  }}>Report</h5>
            </div>            
            <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
        </Modal.Body>
             </div> 
        );
    }

    cancelDialog = () => {
        this.setState({
            ...this.state,
            isModalVisible: false,
            isReportActionSheetVisible: false,
            isReportTypeActionSheetVisible: false,
            isDeletePostActionSheetVisible: false,
        })
    }
    _renderChooseReportTypeActionSheetAndroid = () => {
        return (
            <div>
            <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                <div className="border-bottom">
                    <p>Report</p>
                </div>
                <div className="border-bottom" onClick={() => this._reportPost("Nudity")}>
                    <h5 style={{ color: '#047cff',cursor:"pointer", marginTop:"8px" }}>Nudity</h5>
                </div>
                <div className="border-bottom" onClick={() => this._reportPost("Inappropriate")}>
                    <h5 center style={{ color: '#047cff',cursor:"pointer",  marginTop:"8px" }}>Inappropriate</h5>
                </div>
                <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
            </Modal.Body>
        </div>
        );
    }

    handleIndexChange = index => {
        this.setState({
            ...this.state,
            selectedIndex: index
        });
    }

    _renderUserProfileModal = () => {
        if (!this.state.showUserProfileModal) {
            return
        }
        return (
            // <ReactModal
            // 	transparent={false}
            // 	animationType="slide"
            // 	presentationStyle="pageSheet"
            // 	visible={this.state.showUserProfileModal}
            // 	onRequestClose={() => this.setState({showUserProfileModal:false})}
            // >
            // 	<ProfileScreenModal  profileId= {this.state.selectedUserProfileiD}/>
            // </ReactModal>
            <div></div>
        )
    }
    render() {
        // const { navigation } = this.props
        return (
            //   <Block flex style={{ backgroundColor: materialTheme.COLORS.WHITE }}>
            //     {this._renderUserProfileModal()}
            //     <Block style={{ margin: 10, marginTop: 20, marginBottom: 10 }}>
            //     </Block>
            //     {this._renderCollectionConfirmationDialog()}
            //     {this._renderReportActionSheetAndroid()}
            //     {this._renderChooseReportTypeActionSheetAndroid()}
            //     {this._renderDeleteActionSheetAndroid()}
            //   </Block>
            <div>
                {this._renderPostImages()}
                <Modal size="sm" show={this.state.isReportActionSheetVisible} centered>
                        {this.state.isReportTypeActionSheetVisible ? (
                        this._renderChooseReportTypeActionSheetAndroid()
                        ):(
                            this._renderReportActionSheetAndroid()   
                        )}
                </Modal>
                {this._renderDeleteActionSheetAndroid()}
            </div>
            
        );
    }
}
const mapStateToProps = (state) => {
    return {
        posts: state.profileReducer.userPosts
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        delete: (key) => dispatch(deletePost(key))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SinglePostScreen);