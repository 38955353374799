import React from 'react';
import DiscoverService from '../services/discover'
import Loader from "react-loader-spinner";
import Header from '../Components/Header'
import { Modal } from 'react-bootstrap';
import Gallery from "react-photo-gallery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from "@fortawesome/free-solid-svg-icons";
//import Carousel, { Modal, ModalGateway } from "react-images";
import { connect } from 'react-redux';
import { addAllDiscoverPosts,discoverPageCount } from '../store/actions/discover';
import Search from '../Components/Search'
import SinglePost from '../Pages/SinglePostScreenModal'
import $ from 'jquery'
const width = 441;
const innerHeight = window.innerHeight
class DiscoverScreen extends React.Component {
    constructor() {
        super();
        this.state = { 
            currentImage: 0,
            lightboxIsOpen: false,
            pageNo: 10,
            categoryList: [],
            post: [],
            refreshBottom: false,
            isLoading: false,
            noMoreItems: false 
        
        };         
    }
    async componentDidMount() {

        if(this.props.postList.length > 0){
            let pageNoestimate = this.props.pageCount;
            this.setState({ ...this.state, isLoading: false, pageNo: pageNoestimate })      
            document.getElementById("flatList").addEventListener("scroll", () => this.scrollControll()) 
        }
        else
        {
            this.setState({ ...this.state, isLoading: true })       
            this.getPosts()
            document.getElementById("flatList").addEventListener("scroll", () => this.scrollControll())
        }

        
    }
    componentWillUnmount() {
        if(document.getElementById("flatList") != null && document.getElementById("flatList") !=undefined){
        document.getElementById("flatList").removeEventListener('scroll', () => this.scrollControll());
        }
    }
    scrollControll = () => {
        if (this.state.refreshBottom == false) {// && this.state.noMoreItems == false
            // var scrollHeight = $(document).height();
            // var scrollPosition = $(window).height() + $(window).scrollTop();
            // if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
            //     this._loadMorePosts()               
            // }
            if($("#flatList")[0] != undefined){
                if($("#flatList").scrollTop() + $("#flatList").innerHeight() >= ($("#flatList")[0].scrollHeight) - 5 ) {
                    this.loadMorePosts()     
                    //alert("reached end")
                }
            }    
        }
    }

    getPosts = () => {
        DiscoverService.getPostList(this.state.pageNo)
            .then(response => {
                let postList = response.data.map((el, index) => {                   
                    return { PostId: el.PostId, src: el.ImageThumbnailUrl, width: 0, height: 0, videoUrl:el.videoUrl }
                    // if(index % 2 === 0){
                    //     return { PostId: el.PostId, src: el.ImageThumbnailUrl,width:  4, height:3 }
                    // }
                    // else
                    // {
                    //     return { PostId: el.PostId, src: el.ImageThumbnailUrl,width:  3, height:4 }
                    // }

                });
                this.props.addPosts(postList)
                this.props.addPageCount(this.state.pageNo)                
                // let noMoreItems = false
                // if (this.props.postList.length == postList.length) {
                //     noMoreItems = true
                // }
                this.setState({ ...this.state, isLoading: false, hasMoreItems: false, refreshBottom: false });
            }).catch(e => console.log(e));
    }
    loadMorePosts = () => {
		this.setState({ ...this.state, pageNo: this.state.pageNo + 4,refreshBottom: true, });
		setTimeout(() => {
			this.getPosts();
		}, 1000);
	}    
    openLightbox = (event, obj) => {     
        this.setState({
            ...this.setState,
           currentImage: obj.index,
            post: obj,
            lightboxIsOpen: true,

       });
    }
    closeLightbox = () => {
        this.setState({
            ...this.setState,
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    
    // gotoPrevious = () => {

    //     this.setState({
    //         ...this.setState,
    //         currentImage: this.state.currentImage - 1,
    //     });
    // }
    // gotoNext = () => {

    //     this.setState({
    //         ...this.setState,
    //         currentImage: this.state.currentImage + 1,
    //     });
    // }
    _showLoader = () => {

        if (this.state.refreshBottom) {
            return (
                <div id="refreshBottom" style={{ height: "100px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
                // <ActivityIndicator size="small" color="#000000" style={{ height: 50, alignSelf: 'center' }} />
            )
        }
    }
    // getCategories = () => {
    //     DiscoverService.getMainCategoryList().then(response => {
    //         if (response.status === 200 && !response.data[0].error) {
    //             this.setState({ ...this.state, categoryList: response.data })
    //         }
    //     }).catch((e) => {
    //         console.log(e);
    //     });
    // }
   
    _renderSegmentedControl = () => {     
        return (
            <section style={{ backgroundColor: "#fafafa" }} className="breadcrumbs">
                <div className="me-auto">
                    <Search history={this.props.history} />
                </div>
            </section>
        );
    }
    _renderHomeContents = () => {
        let postList = this.props.postList;
        const imageRenderer = (
            ({ index, left, top, key, photo }) => (     
              photo.videoUrl == null ? (<img          
                alt="..."       
                {...photo}   
                index={index}
                key={key} 
                style={{margin:3}}       
                onClick={(e)=>this.openLightbox(e,{index:index, photo:photo})}
                className={"galleryImages"}           
              />):(
                <div style={{position:"relative"}}>
                <img         
                alt="..."       
                {...photo}   
                index={index}
                key={key} 
                style={{margin:3,display:"block"}}       
                className={"galleryImages"}           
                onClick={(e)=>this.openLightbox(e,{index:index, photo:photo})}                     
              />
              <FontAwesomeIcon icon={faPlay} style={{ position: "absolute", color: "white", marginTop: "11px",marginLeft:'12px', bottom:10, left:0, }} />
                </div>
              )
            ));
        if (this.state.isLoading) {
            return (
                <div style={{ height: "350px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
                // <ActivityIndicator size="large" color="#000000" style={{ position: 'absolute', alignSelf: 'center' }} />
            );
        } else {
                return (
                    <div id="flatList" className="col-md-12 mx-auto" style={{ height: (innerHeight - 172), overflowY:"scroll", width:"99%"}}>                           
                        <Gallery photos={postList} renderImage={imageRenderer} />                           
                        {this._showLoader()}
                    </div>
                );            
        }
    }

    render() {
        
        return (
            <div>
                <Header pageName="discover" {...this.props} />
                {this._renderSegmentedControl()}
                <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="logindiv" className="contact">
                        <div className="row">
                         {this._renderHomeContents()}
                        </div>
                    </section>
                    <Modal show={this.state.lightboxIsOpen} id="singlePostModal">                                         
                        <SinglePost postData={this.state.post} closeLightbox={this.closeLightbox} />                    
                    </Modal>
                </main>
            </div>
        )
    }
}
//export default DiscoverScreen;
const mapStateToProps = (state) => {
    return {
        postList: state.discoverReducer.postList,
        pageCount: state.discoverReducer.pageCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPosts: (allPosts) => dispatch(addAllDiscoverPosts(allPosts)),
        addPageCount: (pageCount) => dispatch(discoverPageCount(pageCount)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverScreen);