import React from 'react'
import UserService from '../services/user'
import DiscoverService from '../services/discover'
import HomeService from '../services/home'
import { connect } from 'react-redux';
import { deletePost } from '../store/actions/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH,faExchangeAlt,faPlay } from "@fortawesome/free-solid-svg-icons";
import materialTheme from '../theme/Theme'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Loader from "react-loader-spinner";
var width = 441;
var height = 956
var imageHeight = width + 10
var calculatedY = imageHeight - 30
var widthBackGroundImage = width - 50
var dataXCorrection = 0
 if(window.innerWidth < width){
     width = window.innerWidth;      
     height = window.innerHeight;
     imageHeight = width + 110
     calculatedY = imageHeight - 30     
     widthBackGroundImage = width - 40
     dataXCorrection = 32
}


class SinglePostScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedIndex: 0,
            PhotoThumbnailURL: "/images/defaultImage.jpg",
            postData: null,
            isModalVisible: false,
            isReportActionSheetVisible: false,
            isReportTypeActionSheetVisible: false,
            isDeletePostActionSheetVisible: false,
            currentUser: null,
            postId: '',
            showUserProfileModal: false,
            selectedUserProfileiD: null,
            showThumb: false
        };
    }
    async componentDidMount() {
        let postID = ""
        if (this.props.postData != undefined) {
            postID = this.props.postData.photo.PostId
        }
        else {
            postID = this.props.location.state.postId
        }

        // if (!postID) {
        //   const { route } = this.props;
        //   postID = route.params.post.PostId
        // } 
        this._getCurrentUser(postID);    
        this._getSinglePost(postID);       
        this.setState({ postId: postID })
    }   
    _getCurrentUser = () => {        
        const promise = new Promise((resolutionFunc, rejectionFunc) => {
            resolutionFunc(UserService.getCurrentUserProfile());
        });
        promise.then((user) => this.setState({
            ...this.state,
            currentUser: user
        }));
    }
    isPostOwner = () => {
       // console.log("isPostOwner")
        if (this.state.currentUser == null) {
            return false
        }
        if (this.state.currentUser.ProfileId == this.state.postData.userProfile.userID) {
            return true;
        } else {
            return false;
        }
    }
    getLogo = (brandName) => {
        return new Promise((resolve) => {
            DiscoverService.getBrandLogoAsync(brandName).then(logo => {
                resolve(logo);
            }).catch(console.log);
        });
    }
    getAllLogos = async (postData) => {
        let tempPostData = postData;
        for (let i = 0; i < postData.tagsList.length; i++) {
            const logo = await this.getLogo(postData.tagsList[i].BrandName)
            tempPostData.tagsList[i] = { ...tempPostData.tagsList[i], brandLogo: logo };
        }
        return tempPostData
    }
    _getSinglePost = async (postId) => {
       // console.log("_getSinglePost")
        UserService.getSinglePost(postId).then(async response => {
            if (response.status === 200 && response.data.length > 0) {
                let post = response.data[0];
                let postData = {
                    imageUrl: post.ImageUrl,
                    userProfile: {
                        userName: post.ProfileName,
                        userImage: post.PhotoThumbnailURL,
                        userID: post.ProfileId
                    },
                    postDate: post.PostingDate,
                    showTags: false,
                    isSaved: post.isSaved,
                    tagsList: [...post.Tags],
                    postId: post.Id,
                    videoUrl: post.videoUrl
                };
                let tempPostData = postData;
                let tagXPosition = 5
                for (let i = 0; i < postData.tagsList.length; i++) {
                    tempPostData.tagsList[i] = { ...tempPostData.tagsList[i], tagPosition: { x: tagXPosition, y: (calculatedY) } };
                    tagXPosition = tagXPosition + 32
                }
                let finalPostData = await this.getAllLogos(tempPostData)
                postData = finalPostData
                this.setState({ ...this.state, postData: postData });
                this.sendViewCount(postId)
            }
        }).catch(console.log);
    }
    sendViewCount(postID){
       // console.log("sendViewCount")
     if(!this.isPostOwner()){
        UserService.increaseViewCount(postID)
     }
    }
    deletePost = () => {
        const { route, navigation } = this.props;
        UserService.deletePost(this.state.postId).then(response => {
            if (response.status === 200 && response.data.length > 0) {
                this.cancelDialog();
                this.props.delete(this.state.postId)
                if (!this.props.PostId) {
                    navigation.navigate('ProfileLoggedinUser');
                } else {
                    this.props.closeProfileModal()
                }
            }
        }).catch(console.log);
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    shopBrands = (tagId, brandUrl, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey, e) => {
        e.stopPropagation()
        HomeService.shopBrands(tagId, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey).then(response => {
        }).catch(e => console.log(e));
        window.open(brandUrl, '_blank');
    }
    renderBrandsOnBottomFixed = (tagsList) => {
        return tagsList.map((data, index) => {
            return (
                <div className="d-flex flex-row transform BrandsOnBottomFixed" style={{ position: 'absolute', top: data.tagPosition.y, left: data.tagPosition.x, backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                    <img onError={(e) => this.imageErrorHandler(e)} className="border" alt='' key={index} onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                        src={data.brandLogo}
                        style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                </div>
            )
        })
    }
    renderBrandsOnSpecifiedPosition = (tagsList) => {
        debugger
        return tagsList.map((data, index) => {

            let tagTopPos = Number(data.Y);
            if (tagTopPos > (imageHeight - 30)) {
                tagTopPos = imageHeight - 40
            }
            if (width > (Number(data.X) + 120)) {
                return (
                    <div className="d-flex flex-row transform BrandsOnSpecifiedPosition" style={{ position: 'absolute', top: tagTopPos, left: Number(data.X - dataXCorrection), backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                        <img onError={(e) => this.imageErrorHandler(e)} className="border" alt='' key={index} onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                            src={data.brandLogo}
                            style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                        <div style={{ height: 30 }}>
                            <div style={{ margin: 5, marginRight: 15, color: 'black', fontSize: 12 ,whiteSpace:"nowrap"}}>{data.catName}</div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="d-flex flex-row transform BrandsOnSpecifiedPosition" style={{ position: 'absolute', top: tagTopPos, left: (Number(data.X- dataXCorrection) - 120), backgroundColor: materialTheme.COLORS.WHITE, borderRadius: 15 }} key={index}>
                        <div style={{ height: 30 }}>
                            <div style={{ margin: 5, marginLeft: 15, color: 'black', fontSize: 12,whiteSpace:"nowrap" }}>{data.catName}</div>
                        </div>
                        <img key={index}
                        onError={(e) => this.imageErrorHandler(e)}
                            className="border"
                            alt='...'
                            onClick={(e) => this.shopBrands(data.Id, data.BrandUrl, data.profile_id, data.dictionariesPrimaryKey, data.tagPrimaryKey, e)}
                            src={data.brandLogo}
                            style={{ borderWidth: 1, borderColor: materialTheme.COLORS.StrikeColor, minWidth: 30, minHeight: 30, width: 30, height: 30, borderRadius: 15, backgroundColor: 'white' }} />
                    </div>
                )
            }
        })
    }
    _renderPostImages = () => {
        const item = this.state.postData;
        if (!item) {
            return (
                <div className="container mt-3 mb-3" style={{ cursor: "pointer" }}>
                    <button style={{ paddingBottom: 0 }} onClick={this.props.closeLightbox} type="button" className="btn-close float-end" aria-label="Close"></button>
                    <div className="d-flex justify-content-center" style={{ height: 550 }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "250px" }} />
                </div>
                </div>
            )
        }       
        if (item.videoUrl == null || this.state.showThumb) {
            let widthCal = 0
            if(item.videoUrl == null){
                widthCal = 145
            }
            else
            {
                widthCal = 190
            }
            return (
                <div post={0} key={0} className="container mt-3 mb-3" style={{ cursor: "pointer" }}>
                    <div className="" style={{ backgroundColor: "white" }}>
                        <button style={{ paddingBottom: 0 }} onClick={this.props.closeLightbox} type="button" className="btn-close float-end" aria-label="Close"></button>
                        <div style={{ boarder: "none" }}>
                            <div className="d-flex justify-content-between p-2 px-3">
                                <div className="d-flex flex-row align-items-center"><Link to={{ pathname: "/profile", state: { loggedIn: false, profileId: item.userProfile.userID } }}><img onError={(e) => this.imageErrorHandler(e)} alt="" src={item.userProfile.userImage} width="50" height="50" className="rounded-circle"></img></Link>
                                    <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{item.userProfile.userName}</span></div>
                                </div>
                                <div className="d-flex flex-row mt-1"><small style={{ marginTop: "10px" }} className="mr-2">{item.postDate}</small></div>
                            </div>
                            <div key={0} style={{
                                position: "relative",
                                backgroundImage: `url("${item.imageUrl}")`,
                                width: (widthBackGroundImage), height: imageHeight, backgroundColor: '#F7F7F7', borderRadius: 10, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                display: 'inline-block'
                            }}
                                onClick={() => this.handleImageTapped()}
                            >
                                {this._renderBrands(item.tagsList, item.showTags)}                                
                              
                               <div style={{ position: "absolute", left: (width - widthCal), top: imageHeight - 30 }} >
                                    {this._renderPostSavedStatus(item)}
                                </div>
                                { item.videoUrl != null?(
                                <div onClick={(e) => this._renderthumb(e)} style={{position: "absolute", backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20,left: (width - 145), top: imageHeight - 30 }}>
                                    <FontAwesomeIcon icon={faPlay} style={{ color: "white", marginTop: "11px", marginLeft: '14px' }} />
                                </div>):(<div></div>)
                               }
                                <div onClick={(e) => this._renderActionSheet(e)} style={{ position: "absolute", backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20, left: (width - 100), top: imageHeight - 30 }}>
                                    <FontAwesomeIcon icon={faEllipsisH} style={{ color: "white", marginTop: "11px", marginLeft: '12px' }} />
                                </div>
                            </div>
                            {/* {this._renderBrands(item.tagsList, item.showTags)} */}
                        </div>
                    </div>
                </div>

            )
        }
        else {
            return (
                <div post={0} key={0} className="container mt-3 mb-3" style={{ cursor: "pointer" }}>
                    <div className="" style={{ backgroundColor: "white" }}>
                        <button style={{ paddingBottom: 0 }} onClick={this.props.closeLightbox} type="button" className="btn-close float-end" aria-label="Close"></button>
                        <div style={{ boarder: "none" }}>
                            <div className="d-flex justify-content-between p-2 px-3">
                                <div className="d-flex flex-row align-items-center"><Link to={{ pathname: "/profile", state: { loggedIn: false, profileId: item.userProfile.userID } }}><img onError={(e) => this.imageErrorHandler(e)} alt="" src={item.userProfile.userImage} width="50" height="50" className="rounded-circle"></img></Link>
                                    <div className="d-flex flex-column ml-2"> <span style={{ marginLeft: "10px" }} className="font-weight-bold">{item.userProfile.userName}</span></div>
                                </div>
                                <div className="d-flex flex-row mt-1"><small style={{ marginTop: "10px" }} className="mr-2">{item.postDate}</small></div>
                            </div>
                            <div key={0} style={{
                                backgroundColor: '#F7F7F7', borderRadius: 10,
                                backgroundPosition: "center",
                                display: 'inline-block'
                            }}>
                                <video id="video" controls muted autoPlay width={(widthBackGroundImage)} height={imageHeight} disablePictureInPicture controlsList="nodownload" onLoadedMetadata={()=> this.videoLoaded()}>
                                    <source src={item.videoUrl}>
                                    </source>
                                </video>
                                <div className="d-flex flex-row-reverse">                                                           
                                <div onClick={(e) => this._renderActionSheet(e)} style={{ backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20 }}>
                                    <FontAwesomeIcon icon={faEllipsisH} style={{ color: "white", marginTop: "11px", marginLeft: '12px' }} />
                                </div>                                
                                {/* <div onClick={(e) => this._renderthumb(e)} style={{ backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20,marginRight:5,marginLeft:5 }}>
                                    <FontAwesomeIcon icon={faExchangeAlt} style={{ color: "white", marginTop: "11px", marginLeft: '12px' }} />
                                </div> */}
                                <div style={{marginRight:5}}>
                                    {this._renderPostSavedStatus(item)}
                                </div>  
                                </div>
                            </div>
                            {/* <video id="video" controls="controls" width={400} height={500} disablePictureInPicture controlsList="nodownload">
                        <source src={item.videoUrl}>
                        </source>
                    </video> */}
                        </div>
                    </div>
                </div>
            )

        }

    }
    videoLoaded = ()=>{
        const item = this.state.postData;     
        if(item != null && item.videoUrl != null && this.state.showThumb == false){
            const video = document.querySelector('video');         
            if(video != null){
                video.addEventListener('pause', (event) => {                      
                    var vidState = video.readyState
                    //console.log("Videopause")
                    console.log(vidState)
                    if(vidState > 2 ){
                        this.setState({
                            ...this.state,
                            showThumb: !this.state.showThumb
                        });
                    }
                  });
            }
        }       
    }

    _renderthumb = (e) =>{    
        e.stopPropagation()
        this.setState({
            ...this.state,
            showThumb: !this.state.showThumb
        });
    }
    _renderBrands = (tagsList, showTags) => {
        if (showTags) {
            return (
                this.renderBrandsOnSpecifiedPosition(tagsList)
            );
        } else {
            return (
                this.renderBrandsOnBottomFixed(tagsList)
            );
        }
    }
    handleImageTapped = () => {
        let postData = this.state.postData
        if (!postData.showTags) {
            for (let i = 0; i < postData.tagsList.length; i++) {
                let data = postData.tagsList[i]
                let tagYPos = Number(data.Y);
                let tagXPos = Number(data.X- dataXCorrection);
                if (tagYPos > (imageHeight - 30)) {
                    tagYPos = imageHeight - 40
                }
                if (width < (tagXPos + 120)) {
                    tagXPos = (Number(data.X) - 120)
                }
                window.transformToPostion(tagXPos, tagYPos, i, 0)
            }
            setTimeout(() => {
                let tempObject = { ...this.state.postData, showTags: !this.state.postData.showTags };
                this.setState({ postData: tempObject });
            }, 1000);
        } else {
            let tagXPosition = 5
            for (let i = 0; i < postData.tagsList.length; i++) {
                window.transformToBottom(tagXPosition, calculatedY, i, 0)
                tagXPosition = tagXPosition + 32
            }
            setTimeout(() => {
                let tempObject = { ...this.state.postData, showTags: !this.state.postData.showTags };
                this.setState({ postData: tempObject });
            }, 1000);

        }
    }
    togglePostSavedStatus = (post, e) => {
        e.stopPropagation()
        HomeService.togglePostSavedStatus(post).then(response => {
            if (response.status == 200 && response.data) {
                if (response.data[0].status == 'success') {
                    const updatedPost = { ...this.state.postData, isSaved: !post.isSaved }

                    this.setState({
                        ...this.state,
                        isModalVisible: !post.isSaved,
                        postData: updatedPost

                    });
                }
            }
        }).catch(e => console.log(e));
    }
    _renderPostSavedStatus = (post) => {
        if (!this.isPostOwner()) {
            if (post.isSaved) {
                return (
                    <div>
                        <img onClick={(e) => this.togglePostSavedStatus(post, e)} style={{ width: 40, height: 40, borderRadius: 20 }}
                        alt=""
                        src={"/images/Favorite.png"}
                        ></img>
                    </div>
                    
                );
            } else {
                return (
                    <div>
                        <img onClick={(e) => this.togglePostSavedStatus(post, e)}
                        alt=""
                        src={"/images/UnFavorite.png"}
                        style={{ borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 40, height: 40, borderRadius: 20 }}></img>
                    </div>
                    
                );
            }
        }
    }

    _reportPost = (reportType) => {
        this.cancelDialog();
        const reportedPost = this.state.postData;
        HomeService.reportPost(reportedPost, reportType).then(response => {
            if (response.status == 200 && response.data) {
                console.log(response);
            }
        }).catch(e => console.log(e));
    }

    setModalVisible = (isVisible) => {
        this.setState({
            ...this.state,
            isModalVisible: isVisible,
        });
    }
    toggleDeletePostActionSheetVisibility = (isVisible) => {
        this.setState({
            ...this.state,
            isDeletePostActionSheetVisible: isVisible,
        });
    }
    toggleReportActionSheetVisibility = (isVisible) => {
        this.setState({
            ...this.state,
            isReportActionSheetVisible: isVisible,
        });
    }
    toggleReportTypeAndroidActionSheetVisibility = (isVisible) => {
        this.setState({
            ...this.state,
            isReportTypeActionSheetVisible: isVisible,
        });
    }
    _renderAndroidActioSheet = () => {
        this.toggleReportActionSheetVisibility(true)
    }
    _renderIOSActionSheet = () => {
        // ActionSheetIOS.showActionSheetWithOptions(
        //   {
        //     title: 'Choose option',
        //     options: ["Cancel", "Report"],
        //     cancelButtonIndex: 0
        //   },
        //   buttonIndex => {
        //     if (buttonIndex === 0) {
        //     } else if (buttonIndex === 1) {
        //       this._renderReportTypeActionSheetIOS();
        //     }
        //   }
        // );
    }
    _renderReportTypeActionSheetIOS = () => {
        // ActionSheetIOS.showActionSheetWithOptions(
        //   {
        //     title: 'Report',
        //     options: ["Cancel", "Nudity", "Inappropriate"],
        //     cancelButtonIndex: 0
        //   },
        //   buttonIndex => {
        //     if (buttonIndex === 0) {
        //     } else if (buttonIndex === 1) {
        //       this._reportPost("Nudity")
        //     } else {
        //       this._reportPost("Inappropriate")
        //     }
        //   }
        // );
    }

    _renderDeleteActionSheetIOS = () => {
        // ActionSheetIOS.showActionSheetWithOptions(
        //   {
        //     title: 'Choose option',
        //     options: ["Cancel", "Delete"],
        //     cancelButtonIndex: 0,
        //     destructiveButtonIndex: 1,
        //   },
        //   buttonIndex => {
        //     if (buttonIndex === 0) {
        //     } else if (buttonIndex === 1) {
        //       this.deletePost();
        //     }
        //   }
        // );
    }
    showDeleteActionSheetAndroid = () => {
        this.toggleDeletePostActionSheetVisibility(true);
    }
    _renderDeleteActionSheetAndroid = () => {
        return (
            <Modal size="sm" show={this.state.isDeletePostActionSheetVisible} centered>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                    <div className="border-bottom">
                        <p>Choose option</p>
                    </div>
                    <div className="border-bottom" onClick={() => this.showReportTypeDialog()}>
                        <h5 onClick={() => this.deletePost()} style={{ color: 'red', cursor: "pointer", marginTop: "8px" }}>Delete</h5>
                    </div>
                    <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
                </Modal.Body>
            </Modal>
        );
    }

    _renderActionSheet = (e) => {
        e.stopPropagation()
        if (this.isPostOwner()) {
            this.showDeleteActionSheetAndroid()
        } else {
            this._renderAndroidActioSheet()
        }
    }

    showReportTypeDialog = () => {
        this.toggleReportTypeAndroidActionSheetVisibility(true);
        //this.toggleReportActionSheetVisibility(false);
        //setTimeout(() => {
        //    this.toggleReportTypeAndroidActionSheetVisibility(true);
        //}, 1000);
    }


    _renderReportActionSheetAndroid = () => {
        return (
            <div>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                    <div className="border-bottom">
                        <p>Choose option</p>
                    </div>
                    <div className="border-bottom" onClick={() => this.showReportTypeDialog()}>
                        <h5 onClick={() => this.showReportTypeDialog()} style={{ color: '#047cff', cursor: "pointer", marginTop: "8px" }}>Report</h5>
                    </div>
                    <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
                </Modal.Body>
            </div>
        );
    }

    cancelDialog = () => {
        this.setState({
            ...this.state,
            isModalVisible: false,
            isReportActionSheetVisible: false,
            isReportTypeActionSheetVisible: false,
            isDeletePostActionSheetVisible: false,
        })
    }
    _renderChooseReportTypeActionSheetAndroid = () => {
        return (
            <div>
                <Modal.Body className="justify-content-center" style={{ textAlign: "center" }}>
                    <div className="border-bottom">
                        <p>Report</p>
                    </div>
                    <div className="border-bottom" onClick={() => this._reportPost("Nudity")}>
                        <h5 style={{ color: '#047cff', cursor: "pointer", marginTop: "8px" }}>Nudity</h5>
                    </div>
                    <div className="border-bottom" onClick={() => this._reportPost("Inappropriate")}>
                        <h5 center style={{ color: '#047cff', cursor: "pointer", marginTop: "8px" }}>Inappropriate</h5>
                    </div>
                    <Button size="sm" style={{ marginTop: "10px" }} onClick={() => this.cancelDialog()}>Cancel</Button>
                </Modal.Body>
            </div>
        );
    }

    handleIndexChange = index => {
        this.setState({
            ...this.state,
            selectedIndex: index
        });
    }   
    _renderUserProfileModal = () => {
        if (!this.state.showUserProfileModal) {
            return
        }
        return (
            // <ReactModal
            // 	transparent={false}
            // 	animationType="slide"
            // 	presentationStyle="pageSheet"
            // 	visible={this.state.showUserProfileModal}
            // 	onRequestClose={() => this.setState({showUserProfileModal:false})}
            // >
            // 	<ProfileScreenModal  profileId= {this.state.selectedUserProfileiD}/>
            // </ReactModal>
            <div></div>
        )
    }
    render() {
        // const { navigation } = this.props
        return (
            //   <Block flex style={{ backgroundColor: materialTheme.COLORS.WHITE }}>
            //     {this._renderUserProfileModal()}
            //     <Block style={{ margin: 10, marginTop: 20, marginBottom: 10 }}>
            //     </Block>
            //     {this._renderCollectionConfirmationDialog()}
            //     {this._renderReportActionSheetAndroid()}
            //     {this._renderChooseReportTypeActionSheetAndroid()}
            //     {this._renderDeleteActionSheetAndroid()}
            //   </Block>
            <div>
                {this._renderPostImages()}               
                <Modal size="sm" show={this.state.isReportActionSheetVisible} centered>
                    {this.state.isReportTypeActionSheetVisible ? (
                        this._renderChooseReportTypeActionSheetAndroid()
                    ) : (
                            this._renderReportActionSheetAndroid()
                        )}
                </Modal>
                {this._renderDeleteActionSheetAndroid()}
                <Modal className={"centerModel"} size="sm" show={this.state.isModalVisible} >
                    <Modal.Header  style={{ backgroundColor: materialTheme.COLORS.PrimaryDark,height:85,paddingTop:0}}>    
                    <p style={{ fontSize: 20, fontWeight: 'bold', color: 'white',marginBottom:0 }}>Added to Your Collection!</p>                   
                        <button style={{ color: 'white'}}  onClick={() => this.setModalVisible(false)} type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{padding:0}}>
                    <div id="imageofHeart">
                    <img
                            style={{paddingBottomL:"-4px",marginTop:"-30px"}}
                            onError={(e) => this.imageErrorHandler(e)}
                            alt=""
                            resizeMode="cover"
                            src={"/images/Favorite.png"}
                            ></img>                                                                    
                    </div>
                    <div style={{ borderBottomRightRadius: 5, borderBottomLeftRadius: 5, backgroundColor: materialTheme.COLORS.WHITE }}>
                            <p style={{ textAlign: 'center', fontSize: 15, color: materialTheme.COLORS.PrimaryDarkLightColor,marginBottom:10}}>You can view your collections on your profile page.</p>
                        </div>  
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        posts: state.profileReducer.userPosts
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        delete: (key) => dispatch(deletePost(key))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SinglePostScreen);