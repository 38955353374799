import axios from 'axios';
import { AsyncStorage }  from 'AsyncStorage';
// import { Platform } from 'react-native';
import BASE_URL from './apipath'
const axiosInstance = axios.create({
    baseURL: BASE_URL
});
axiosInstance.interceptors.request.use((config) => {
    let partner = localStorage.getItem("partner")
    if(partner != null && partner != "web"){
        config.url = config.url + "?partner=" +  partner+"_db"
        // redirect to 404 if title does not exist
        //config.url = config.url + "?partner=" + partner+"_db"
    }
    return config
 });
export default class NewPostService {

    static async uploadImage(uploaded_file) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let localUri = uploaded_file;
            let imageName = `imageManip(${user.ProfileId}).jpg`;
            let filename = localUri.split('/').pop();
            let match = /\.(\w+)$/.exec(filename);
            let type = match ? `image/${match[1]}` : `image`;
            // let type = mime.getType(uploaded_file)
            // console.log(mime.getType(uploaded_file))

            // mimeType: 
            //will be implemented at a later time
           // let imageObject = { uri: Platform.OS=='ios'?localUri.replace("file://", "/private"):localUri , name: imageName, type };
            let imageObject = {}
            let fd = new FormData();
            fd.append("uploaded_file", imageObject);
            return axiosInstance.post(`/google/receiver.php`, fd);
        } catch (e) {
            console.log(e);
        }
    }
    static async uploadImageWeb(uploaded_file) {
        try {            
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);            
            let imageName = `imageManip(${user.ProfileId})`;
            let fd = new FormData();
            fd.append("uploaded_file",uploaded_file);
            fd.append("name",imageName);
            return axiosInstance.post(`/google/receiverWeb_updated.php`, fd);
        } catch (e) {
            console.log(e);
        }
    }
    static async uploadPost(codes) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let arrayValue = '';

            for (let index = 0; index < codes.length; index++) {

                let xCoord = codes[index].currentX;
                let yCoord = codes[index].currentY;
                let categoryId = codes[index].categorySelected;
                let brandSelected = codes[index].brandSelected;
                let brandSelectedUrl = codes[index].brandSelectedurl
                arrayValue = `${arrayValue}${xCoord},${yCoord},${categoryId},${brandSelected} ,${brandSelectedUrl} | `;
            }
            let imageName = `imageManip(${user.ProfileId}).jpg`;
            let fd = new FormData();
            fd.append("img", imageName);
            fd.append("arrayValue", arrayValue);
            fd.append("idProfile", user.ProfileId);
            return axiosInstance.post(`/google/finalize.php`, fd);
        } catch (e) {
            console.log(e);
        }
    }

    static async uploadPostUpdated(codes) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let arrayValue = '';

            for (let index = 0; index < codes.length; index++) {
                let isConfigured = codes[index].isConfigured
                if (isConfigured) {
                    let xCoord = codes[index].currentX;
                    let yCoord = codes[index].currentY;
                    let categoryId = codes[index].categorySelected;
                    let brandSelected = codes[index].brandSelected;
                    let brandSelectedUrl = codes[index].brandSelectedurl
                    arrayValue = `${arrayValue}${xCoord},${yCoord},${categoryId},${brandSelected} ,${brandSelectedUrl} ^|^ `;
                }
            }
            let imageName = `imageManip(${user.ProfileId}).jpg`;
            let fd = new FormData();
            fd.append("img", imageName);
            fd.append("arrayValue", arrayValue);
            fd.append("idProfile", user.ProfileId);
            return axiosInstance.post(`/google/finalize_updated.php`, fd);
        } catch (e) {
            console.log(e);
        }
    }

    static async uploadPostUpdatedVideo(codes, file) {
        try {
            const config = {
                onUploadProgress: progressEvent => {
                    let progress = 0;
                  progress = (progressEvent.loaded / progressEvent.total) * 100;
                  var x = document.getElementsByClassName("progress-bar");
                  x[0].style.width = progress+ "%"		
                }
              }
              
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let arrayValue = '';

            for (let index = 0; index < codes.length; index++) {
                let isConfigured = codes[index].isConfigured
                if (isConfigured) {
                    let xCoord = codes[index].currentX;
                    let yCoord = codes[index].currentY;
                    let categoryId = codes[index].categorySelected;
                    let brandSelected = codes[index].brandSelected;
                    let brandSelectedUrl = codes[index].brandSelectedurl
                    arrayValue = `${arrayValue}${xCoord},${yCoord},${categoryId},${brandSelected} ,${brandSelectedUrl} ^|^ `;
                }
            }
            let imageName = `imageManip(${user.ProfileId}).jpg`;
            let fd = new FormData();
            fd.append("img", imageName);
            fd.append("arrayValue", arrayValue);
            fd.append("idProfile", user.ProfileId);
            fd.append("uploaded_file", file);
            return axiosInstance.post(`/google/finalize_updatedVideo.php`, fd,config);
        } catch (e) {
            console.log(e);
        }
    }

    static async getImageResult() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            const imageName = `imageManip(${user.ProfileId}).jpg`;

            return axiosInstance.get("/google/index.php", {
                params: {
                    img: imageName,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    static async getCategoryList(subCategory) {
        try {
            return axiosInstance.get("/categories/index.php", {
                params: {
                    comp: subCategory,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getBrandList(searchText) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/brands/getclearbitbrand.php", {
                params: {
                    brand_name: searchText,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getUserSuggestedBrands(category_id) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/discover/usersuggestedbrands.php", {
                params: {
                    category_id: category_id,
                    user_id: user.ProfileId
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getGoogleCategories() {
        try {            
            return axiosInstance.get("/google/getGoogleCategories.php")
        } catch (e) {
            console.log(e);
        }
    }
}