import React from 'react'
//import FlatList from 'flatlist-react';
import DiscoverService from '../services/discover'
//import Loader from "react-loader-spinner";
import { connect } from 'react-redux';
import { addAutoDetectedList, resetTagList, addTag, removeTag, updateTagCategory, updateTagBrand } from '../store/actions/newPost'
import { toggleCancelButtonVisibility, toggleNextButtonVisibility, togglePostButtonVisibility, toggleLookUpBtnVisibility } from '../store/actions/newPost';
import { toggleDetectedListVisibility, toggleCatePickerVisibility, toggleShowTitleVisibility, toggleLookUpButtonTitle } from '../store/actions/newPost';
import { toggleIsImageTappable, changeLookUpButtonType, } from '../store/actions/newPost';
import materialTheme from '../theme/Theme'
import SearchField from 'react-search-field';
class BrandSearchScreen extends React.Component {
    _isMounted = false;
    constructor() {
        super();
        this.state = {
            search: '',
            brandList: [],
            typingTimeout: 0,
            brandDefaultLogo: "/images/defaultImage.jpg",
        };
    }
    async componentDidMount() {
        this._isMounted = true;
        this.getAllBrands(this.state.search);
    }
    updateSearch = (search) => {

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            ...this.state, search, typingTimeout: setTimeout(() => {
                this.getAllBrands(search);
            }, 1000)
        });
    };

    handleBrandTapped = (brand) => {    
        this.props.closeModal()
        if(this.props.searchFrom == "branddetail") 
        {
            this.props.updateBandDetails(brand)
        }
        else
        {
        const { history } = this.props;
            history.push({
                pathname: '/branddetail',
                state: { brandName: brand.name, brandId: brand.brandId }
            })
        }
        
        
        //const { navigation } = this.props
        //const { route } = this.props
        //const { from } = route.params
        //if (from === "newPost") {
        //    this._isMounted = false;
        //    this.props.addSelectedBrand({ selectedBrandName: brand.name, selectedBrandUrl: brand.urlLogo });
        //    this.props.toggleCancelBtnVisibility(false);
        //    this.props.toggleNextlBtnVisibility(false);
        //    this.props.togglePostBtnVisibility(true);
        //    this.props.toggleLooksUpBtnVisibility(false);
        //    this.props.toggleAutoDetectedListVisibility(true);
        //   this.props.toggleTitleVisibility(true);
        //  this.props.toggleCategoryPickerVisibility(false);
        // this.props.toggleLookUpBtnTitle(brand.name);
        //   this.props.toggleImageTappable(true);
        //   this.props.toggleLookUpButtonType('brands');
        //   navigation.navigate('NewPostAddTags');
        //} else {
        //navigate to brands detail page
        // navigation.navigate('BrandDetail', { brandName: brand.name, brandId: brand.brandId })
        //}
    }
    renderBrands = () => {
        return this.state.brandList.map((brand, index) => {
            return (
                <div className="border-bottom changeBackgroundColorOnHover marginTop10" onClick={() => this.handleBrandTapped(brand)} key={index} style={{ borderBottomColor: 'gray', borderBottomWidth: 1, width: "100%", height: 90, cursor: "pointer" }}>
                    <img alt="..." src={brand.urlLogo} className="border border-dark rounded-circle" key={index}
                        style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 70, height: 70, borderRadius: 25 }} />
                    <label style={{ marginLeft: "20px" }}>{brand.name}</label>
                </div>
            )
        })
    }
    getAllBrands = (searchText) => {
        DiscoverService.getBrandList(searchText).then(response => {
            if (response.status === 200 && !response.data.error) {
                this.setState({ ...this.state, brandList: [] });
                response.data.map(brand => {
                    DiscoverService.getBrandLogoAsync(brand.name).then(logo => {
                        let brandItem = {
                            brandId: brand.Id,
                            name: brand.name,
                            urlLogo: logo,
                        }
                        if (brandItem.name.trim() !== "") {
                            let brandList = [...this.state.brandList, brandItem];
                            if (this._isMounted) {
                                this.setState({ ...this.state, brandList });
                            }
                        }
                    }).catch(console.log);
                });
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    _renderSearchBar = () => {
        //   const { route } = this.props;
        //   const { from } = route.params;
        //   const { search } = this.state;
        //if (from === "newPost") {
        //  return (<div></div>
        //   <Block style={{ marginTop: 20 }}>
        //       <SearchBar
        //           platform='ios'
        //           round
        //           searchIcon={{ size: 24 }}
        //           placeholder="Search by brand"
        //           onChangeText={this.updateSearch}
        //           value={search}
        //       />
        //   </Block>
        //);
        //} else {
        return (<div>
            <SearchField
                placeholder='Search by Brands'
                onChange={this.updateSearch}
                classNames="searchInput rounded"
            />
        </div>)
        //   <SearchBar
        //       platform='ios'
        //       round
        //       searchIcon={{ size: 24 }}
        //       placeholder="Search by brand"
        //       onChangeText={this.updateSearch}
        //       value={search}

        //   />
        //  );
        //}
    }


    render() {
        return (
            <div>
                {this._renderSearchBar()}
                {this.renderBrands()}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isImageTaggable: state.newPostReducer.isImageTaggable,
        lookUpButtonType: state.newPostReducer.lookUpButtonType,
        autoDetectedList: state.newPostReducer.autoDetectedList,
        tagsData: state.newPostReducer.tagsData,
        showCancelBtn: state.newPostReducer.showCancelBtn,
        showPostBtn: state.newPostReducer.showPostBtn,
        showNextBtn: state.newPostReducer.showNextBtn,
        showLookUpBrandsButton: state.newPostReducer.showLookUpBrandsButton,
        showAutoDetectedList: state.newPostReducer.showAutoDetectedList,
        showCategoriesPicker: state.newPostReducer.showCategoriesPicker,
        showTitle: state.newPostReducer.showTitle,
        lookUpButtonTitle: state.newPostReducer.lookUpButtonTitle,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addSelectedBrand: (brand) => dispatch(updateTagBrand(brand)),
        addAutoDetected: (detectedList) => dispatch(addAutoDetectedList(detectedList)),
        addTagData: (data) => dispatch(addTag(data)),
        resetTagData: () => dispatch(resetTagList()),
        removeTagFromList: () => dispatch(removeTag()),
        toggleCancelBtnVisibility: (isVisible) => dispatch(toggleCancelButtonVisibility(isVisible)),
        toggleNextlBtnVisibility: (isVisible) => dispatch(toggleNextButtonVisibility(isVisible)),
        togglePostBtnVisibility: (isVisible) => dispatch(togglePostButtonVisibility(isVisible)),
        toggleLooksUpBtnVisibility: (isVisible) => dispatch(toggleLookUpBtnVisibility(isVisible)),
        toggleAutoDetectedListVisibility: (isVisible) => dispatch(toggleDetectedListVisibility(isVisible)),
        toggleTitleVisibility: (isVisible) => dispatch(toggleShowTitleVisibility(isVisible)),
        toggleCategoryPickerVisibility: (isVisible) => dispatch(toggleCatePickerVisibility(isVisible)),
        toggleLookUpBtnTitle: (title) => dispatch(toggleLookUpButtonTitle(title)),
        toggleLookUpButtonType: (type) => dispatch(changeLookUpButtonType(type)),
        toggleImageTappable: (isTappable) => dispatch(toggleIsImageTappable(isTappable)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandSearchScreen);
