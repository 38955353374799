/* eslint-disable import/no-anonymous-default-export */
export default {
  COLORS: {
    DEFAULT: '#DCDCDC',
    PRIMARY: '#9C26B0',
    LABEL: '#FE2472',
    INFO: '#00BCD4',
    ERROR: '#CF4747',
    SUCCESS: '#4CAF50',
    WARNING: '#FF9800',
    MUTED: '#979797',
    INPUT: '#DCDCDC',
    ACTIVE: '#9C26B0',
    BUTTON_COLOR: '#6A818B',
    PLACEHOLDER: '#9FA5AA',
    SWITCH_ON: '#9C26B0',
    SWITCH_OFF: '#D4D9DD',
    GRADIENT_START: '#6B24AA',
    GRADIENT_END: '#AC2688',
    PRICE_COLOR: '#EAD5FB',
    BORDER_COLOR: '#E7E7E7',
    BLOCK: '#E7E7E7',
    ICON: '#4A4A4A',
    NewBlue:  "#06058D",
    PrimaryDark: "#052736",
    PrimaryDarkTextColor: "#1D1D1D",
    PrimaryDarkLightColor: "#062D3F",
    SelectedColorPrimary: "#062D3F",
    StrikeColor: "#63828D",
    UnselectedColor: "#DBDDDE",
    TextColor: "#7A7A7A",
    WHITE:'#ffffff',
    TabUnselectedTextColor:'#444444',
    BLACK: '#000000',
    SYSTEM_PINK: '#FF375F',
    STATUS_BAR: '#021b26', 
    NOTIFICATION: '#021b26',    
  }
  // },
  // SIZES: {
  //   BLOCK_SHADOW_RADIUS: 2,
  // }
};