import React from 'react'
import { Nav, Tab, Modal } from 'react-bootstrap';
import DiscoverService from '../services/discover'
import BrandSearchScreen from './BrandSearchScreen'
import NameSearchScren from './NameSearchScreen'
import '../Components/Search.css'
import Categories from './Categories'
class Search extends React.Component {
    constructor() {
        super();
        this.state = {
            showHideCategories: false,
            showHideBrands: false,
            showHideNames: false,
            selectedIndex: "",
            categoryList: []            
        }
    }

    handleIndexChange = (index) => {

        if (index == 1) {
            this.setState({ showHideCategories: !this.state.showHideCategories })
        }
        else if (index == 0) {
            // DiscoverService.getMainCategoryList().then(response => {
            //     if (response.status === 200 && !response.data[0].error) {
            //         this.setState({ ...this.state, categoryList: response.data })
            //     }
            // }).catch((e) => {
            //     console.log(e);
            // });
            this.setState({ showHideBrands: !this.state.showHideBrands })
        }
        else {
            this.setState({ showHideNames: !this.state.showHideNames })
        }

    }

    handleModalShowHideCategories() {
        this.setState({ showHideCategories: !this.state.showHideCategories })
    }
    handleModalShowHideBrands() {
        this.setState({ showHideBrands: !this.state.showHideBrands })
    }
    handleModalShowHideNames() {
        this.setState({ showHideNames: !this.state.showHideNames })
    }
    // rander categories
    render() {
        return (
            <div>
                <Tab.Container id="discoverOptions" defaultActiveKey={this.state.selectedIndex} onSelect={this.handleIndexChange}>
                    <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                        <Nav.Item>
                            <Nav.Link eventKey="0"><b>Brand</b></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="1"><b>Category</b></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2"><b>Name</b></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>
                <Modal size="lg" show={this.state.showHideCategories}>
                    <Modal.Header onClick={() => this.handleModalShowHideCategories()}>
                        <Modal.Title>Categories</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <Categories history={this.props.history} closeModal={() => this.handleModalShowHideCategories()} updateCategoriesDetails={this.props.updateCategoriesDetails} searchFrom={this.props.searchFrom}/>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.showHideBrands}>
                    <Modal.Header onClick={() => this.handleModalShowHideBrands()}>
                        <Modal.Title>Brands</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <BrandSearchScreen history={this.props.history} closeModal={() => this.handleModalShowHideBrands()} updateBandDetails={this.props.updateBandDetails} searchFrom={this.props.searchFrom} />
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.showHideNames}>
                    <Modal.Header onClick={() => this.handleModalShowHideNames()}>
                        <Modal.Title>Names</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <NameSearchScren history={this.props.history} closeModal={() => this.handleModalShowHideNames()}/>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default Search;