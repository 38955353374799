import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import NewPostService from '../services/newPost'
import DiscoverService from '../services/discover'
import materialTheme from '../theme/Theme';
import Header from '../Components/Header'
import Loader from "react-loader-spinner";
import SearchField from 'react-search-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { Icon } from '@iconify/react';
var width = 400
var height = 792
if(window.innerWidth < width){
    width = window.innerWidth;      
    height = window.innerHeight;

}
//const allValidCategories = ["Sunglasses", "Glasses", "Earrings", "Necklace", "Scarf", "Top", "Tie", "Bowtie", "Jacket", "Outerwear", "Coat", "Suit", "Sweater", "Rings", "Luggage & bags", "Purse", "Watch", "Belt", "Skirt", "Sock", "Shoes", "Underwear", "Gloves", "Hat", "Bra", "Wallet", "Socks", "Dress", "Jeans", "Pants", "Shorts", "Shoe", "Swimwear", "Panties", "Top"];
const allValidCategories = []
class ImageNewPostScreen extends Component {
    _isMounted = false;
    _showBrands = false;
    constructor() {
        super();
        this.onDrop = (files) => {     
            if (files.length == 0){
                return
            }
            if(files[0].type == "image/jpeg" || files[0].type == "image/png" || files[0].type == "image/jpg"){
                let temp = files.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
                this.setState(
                    { files: temp, showDragDrop: false }
                )
                this.uploadImageToDetectTags(this.state.files[0]);
            }
            else
            {
                return
            }
          
        };
        this.state = {
            files: [],
            showDragDrop: true,
            showTitle: false,
            titleLabel: 'Looking for items to tag...',
            titleDetected: 'We detected the items below, you can tag more manually!',
            tagTitle: 'We could not detect any items, try tagging manually.',
            isLoading: true, // for check loading or not 
            headerTitle: 'Tag or Finish Upload',
            isUploadReady: false, // for buttons
            showAutoDetectedList: false,
            showSubCategories: false,
            showBrandsList: false,
            showCategoryList: false,
            imageTappable: false,
            isPostSubmitting: false,
            showDetectedCategories: false,
            showManualTaggingMessage: false,
            pickerSelectedValue: '',
            pickerList: [],
            search: '',
            autoDetectedList: [],
            tagsData: [],
            brandList: [],
            brandSearch: '',
            selectedIndex: 0,
            mainCategoryList: [],
            selectedMainCategory: '',
            subCategoryList: [],
        };
    }
    resetAll = (e) =>{
      e.stopPropagation();
      this.setState({
        ...this.state,
        files: [],
        showDragDrop: true,
        showTitle: false,
        titleLabel: 'Looking for items to tag...',
        titleDetected: 'We detected the items below, you can tag more manually!',
        tagTitle: 'We could not detect any items, try tagging manually.',
        isLoading: true, // for check loading or not 
        headerTitle: 'Tag or Finish Upload',
        isUploadReady: false, // for buttons
        showAutoDetectedList: false,
        showSubCategories: false,
        showBrandsList: false,
        showCategoryList: false,
        imageTappable: false,
        isPostSubmitting: false,
        showDetectedCategories: false,
        showManualTaggingMessage: false,
        pickerSelectedValue: '',
        pickerList: [],
        search: '',
        autoDetectedList: [],
        tagsData: [],
        brandList: [],
        brandSearch: '',
        selectedIndex: 0,
        mainCategoryList: [],
        selectedMainCategory: '',
        subCategoryList: [],
      });
    }
    async componentDidMount() {
        this._isMounted = true;
        NewPostService.getGoogleCategories().then(response =>{            
            response.data.map(c => {                
                allValidCategories.push(c.Name)
            })
        } )   
    }
    async componentDidUpdate() {

    }
    updateSearch = (search) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            ...this.state, search,
            typingTimeout: setTimeout(() => {
                this.getSubCatgories(search, this.state.selectedMainCategory);
            }, 1000)
        });
    };
    getSubCatgories = (searchText, selectedCategory) => {
 
        if (selectedCategory != '') {
            DiscoverService.getSubCategoryList(selectedCategory.Id, searchText).then(response => {
                if (response.status === 200 && !response.data.error) {
                    let subCategoryList = response.data.map(category => {
                        return {
                            categoryId: category.Id,
                            name: category.name,
                        };
                    });
                    this.setState({ ...this.state, subCategoryList, isLoading: false, showSubCategories: true });
                }
            }).catch((e) => {
                console.log(e);
            });
        } else {
            this.setState({ ...this.state, subCategoryList: [], isLoading: false, showSubCategories: true });
        }
    }
    handleTappedOnMainCategories = (category) => {
   
        this.setState({
            ...this.state,
            showAutoDetectedList: false,
            showBrandsList: false,
            showTitle: true,
            titleLabel: `Editing Tag: ${category.name}`,
            imageTappable: false,
            showSubCategories: false,
            isLoading: true,
            showCategoryList: false,
            showDetectedCategories: false,
            selectedMainCategory: category,
            headerTitle: 'Search for a Category',
            search: ''
        })
        this.getSubCatgories('', category)
    }
    _renderMainCategoryContents = () => {
        return this.state.mainCategoryList.map((category, index) => {
            return (
                <div onClick={() => this.handleTappedOnMainCategories(category)} key={index} style={{ cursor:"pointer", marginBottom: 20, marginTop: 20 , borderRadius: 5 , backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor,marginLeft:7,marginRight:7 }}>
                    <p style={{color: materialTheme.COLORS.WHITE, padding:10 }}>{category.name}</p>
                </div>
            )
        })
    }
    _renderMainCategory = () => {        
        if (this.state.showCategoryList) {
            return (
                <div>
                    {this._renderMainCategoryContents()}
                    {/* <TouchableOpacity center style={{ borderRadius: 10, borderColor: 'white', borderWidth: 1, height: 50,width: width - 20, backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor, alignItems: 'center', justifyContent: 'center', margin: 10 }}>
                  <Text style={{ fontSize: 16, color: materialTheme.COLORS.WHITE }}>Mens</Text>
              </TouchableOpacity> */}
                </div>
            )
        }
    }
    handleTapOnSubCategories = (category) => {
        
        this._showBrands = true
        let tempCateList = [...this.state.tagsData];
        tempCateList[this.state.selectedIndex] = { ...tempCateList[this.state.selectedIndex], categorySelected: category.categoryId, categoryName: category.name };
        this.setState({
            ...this.state,
            showAutoDetectedList: false,
            isLoading: true,
            showBrandsList: false,
            showTitle: true,
            titleLabel: `Editing Tag: ${category.name}`,
            imageTappable: false,
            showSubCategories: false,
            showCategoryList: false,
            showDetectedCategories: false,
            tagsData: tempCateList,
            headerTitle: 'Search for a Brand',
            brandSearch: ''
        })
        this.getUserSuggestedBrands(category.categoryId)
        // this.getAllBrands("")
    }
    _renderSubCategoriesContent = () => {

        return this.state.subCategoryList.map((category, index) => {
            return (
                <div onClick={() => this.handleTapOnSubCategories(category)} key={index} style={{ cursor:"pointer", marginBottom: 5, marginTop: 5 , borderRadius: 5 , backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor }}>
                    <p style={{color: materialTheme.COLORS.WHITE,padding:10,marginBottom:0 }}>{category.name}</p>
                </div>
            )
        })
    }
    _renderSubCategories = () => {

        if (this.state.showSubCategories) {
            const { search } = this.state;
            return (              
                <div>
                    <div style={{maxHeight:500 , overflowY:"scroll", padding:5}}>
                    <SearchField
                          placeholder='Search for a category'
                          onChange={this.updateSearch}
                          classNames="searchInput searchInputPostIamge rounded"                         
                      />
                      {this._renderSubCategoriesContent()}
                    </div>
                </div>
            )
        }
    }
    handleTappedOnBrand = (brand) => {
        
        this._showBrands = false
        //   this.props.addTagData({ "currentX": addingX, "currentY": addingY, categorySelected: '', brandSelected: '', brandSelectedurl: '' });
        let selectedBrandName = brand.name
        let selectedBrandUrl = brand.domain
        let tempBrandList = [...this.state.tagsData];
        tempBrandList[this.state.selectedIndex] = { ...tempBrandList[this.state.selectedIndex], brandSelected: selectedBrandName, brandSelectedurl: selectedBrandUrl, isConfigured: true };
        this.setState({
            ...this.state,
            showTitle: false,
            isLoading: false,
            isUploadReady: true,
            showAutoDetectedList: true,
            showSubCategories: false,
            showBrandsList: false,
            showCategoryList: false,
            imageTappable: false,
            showDetectedCategories: false,
            tagsData: tempBrandList,
            headerTitle: 'Tag or Finish Upload'
        })
    }
    getAllBrands = (searchText) => {
        
        NewPostService.getBrandList(searchText).then(response => {
            if (response.status === 200 && !response.data.error) {
                this.setState({ ...this.state, brandList: [] });
                response.data.map(brand => {
                    if (this._showBrands) {
                        let brandItem = {
                            brandId: brand.id,
                            name: brand.name,
                            urlLogo: brand.logo,
                            domain: brand.domain
                        }
                        let brandList = [...this.state.brandList, brandItem];
                        if (this._isMounted && this._showBrands) {
                            this.setState({ ...this.state, brandList, showBrandsList: true, isLoading: false });
                        }
                    }
                });
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    getUserSuggestedBrands = (categoryId) => {
        
        NewPostService.getUserSuggestedBrands(categoryId).then(response => {
            if (response.status === 200 && !response.data.error) {
                this.setState({ ...this.state, brandList: [] });
                response.data.map(brand => {
                    if (this._showBrands && brand.Name != null) {
                        DiscoverService.getBrandLogoAsync(brand.Name).then(logo => {
                            let brandItem = {
                                brandId: brand.Id,
                                name: brand.Name,
                                urlLogo: logo,
                                domain: brand.URL
                            }
                            if (brandItem.name.trim() !== "") {
                                let brandList = [...this.state.brandList, brandItem];
                                if (this._isMounted && this._showBrands) {
                                    this.setState({ ...this.state, brandList, showBrandsList: true, isLoading: false });
                                }
                            }
                        }).catch(console.log);
                    } else {
                        if (this._showBrands) {
                            this.setState({ ...this.state, showBrandsList: true, isLoading: false });
                        }
                    }
                });
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    _renderBrandContents = () => {
        
        return this.state.brandList.map((brand, index) => {
            return (               
                <div className="border-bottom changeBackgroundColorOnHover" onClick={() => this.handleTappedOnBrand(brand)} key={index} style={{ borderBottomColor: 'gray', borderBottomWidth: 1, width: "100%", height: 60, cursor: "pointer",padding:5,textAlign:"left" }}>
                <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={brand.urlLogo} className="border border-dark rounded-circle" key={index}
                    style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 50, height: 50, borderRadius: 25}} />
                <label style={{ marginLeft: "20px" }}>{brand.name}</label>
            </div>
            )
        })
    }
    _renderBrandList = () => {
        
        if (this.state.showBrandsList) {
            const { brandSearch } = this.state;
            return (
                <div>
                    <div style={{maxHeight:300,paddingLeft:7,paddingRight:7}}>
                    <SearchField
                          placeholder='Search by Brands'
                          onChange={this.updateBrandSearch}
                          classNames="searchInput searchInputPostIamge rounded"                         
                      />
                      {this._renderBrandContents()}
                    </div>
                </div>
            )
        }
    }
    updateBrandSearch = (brandSearch) => {
        
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            ...this.state, brandSearch,
            typingTimeout: setTimeout(() => {
                this.getAllBrands(brandSearch);
            }, 1000)
        });
    }

    _showAlert = () => {      
        window.showAlert("Failure",  "There was an error uploading the file, please try again with another image!")
    }
    uploadImageToDetectTags = (imageObject) => {
        
        NewPostService.uploadImageWeb(imageObject).then(response => {
            if (response.status === 200 && response.data.includes('error')) {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        tagTitle: 'We could not detect any items, try tagging manually.',
                        isLoading: false,
                        showTitle: false,
                        autoDetectedList: [],
                        tagsData: []
                    });
                    this._showAlert()
                }
            } else if (response.status === 200) {
                this.getProcessedImageResult();
            } else {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        tagTitle: 'We could not detect any items, try tagging manually.',
                        isLoading: false,
                        showTitle: false,
                        autoDetectedList: [],
                        tagsData: []
                    });
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    getProcessedImageResult = () => {
        NewPostService.getImageResult().then(response => {
            if (response.status === 200 && response.data[0] && response.data[0].error) {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        showTitle: false,
                        autoDetectedList: [],
                        showAutoDetectedList: true,
                        tagsData: []
                    });
                }
            } else if (response.status === 200 && response.data.responses[0].localizedObjectAnnotations) {
                if (this._isMounted) {
                    let autoDetectedList = response.data.responses[0].localizedObjectAnnotations;
                    this.parseDetectedData(autoDetectedList)
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        showTitle: false,
                        autoDetectedList: [],
                        showAutoDetectedList: true,
                        tagsData: []
                    });
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    parseDetectedData = (detectedData) => {        
        detectedData.map((val, index) => {
            let categoryName = this.isValidCategory(val.name, val.score)
            if (categoryName != null) {
                let addingX = 0
                let addingY = 0
                let coordinateList = val.boundingPoly.normalizedVertices
                for (let index = 0; index < coordinateList.length; index++) {
                    let leftValue = coordinateList[index].x
                    let topValue = coordinateList[index].y
                    let addingXe = leftValue * width
                    let addingYe = topValue * (height / 1.81)
                    if (index == 1) {
                        addingX = addingXe
                        addingY = addingYe
                    } else if (index == 3) {
                        addingX = addingX + ((addingXe - addingX) / 2)
                        addingY = addingY + ((addingYe - addingY) / 2)
                    }
                }
                let newTaggedData = { "currentX": addingX, "currentY": addingY, categorySelected: '', brandSelected: '', brandSelectedurl: '', categoryName: categoryName, isConfigured: false, showTag: false, auto: true }
                  
                this.setState({
                    ...this.state,
                    tagsData: this.state.tagsData.concat(newTaggedData)
                })
            }            
        })
        console.log(this.state.tagsData)
        this.setState({
            ...this.state,
            isLoading: false,
            showTitle: false,
            autoDetectedList: detectedData,
            showAutoDetectedList: true,
        });
    }
    handleTappedOnAutoDetectedItem = (index) => {
        
        let tempTagsData = [...this.state.tagsData];
        tempTagsData[index] = { ...tempTagsData[index], showTag: true };
        this.setState({
            ...this.state,
            isLoading: true,
            showAutoDetectedList: false,
            showBrandsList: false,
            showTitle: true,
            titleLabel: `Editing Tag: `,
            imageTappable: false,
            showSubCategories: false,
            showCategoryList: false,
            showDetectedCategories: false,
            tagsData: tempTagsData,
            selectedIndex: index,
            headerTitle: 'Select Category',
            search: ''
        })
        if (this.state.tagsData[index].auto) {
            this.loadSubCategoriesForDetectedItem(this.state.tagsData[index].categoryName)
        } else {
            this.getMainCategories()
        }
    }
    getMainCategories = () => {
        
        DiscoverService.getMainCategoryList().then(response => {
            if (response.status === 200 && !response.data[0].error) {
                this.setState({ ...this.state, mainCategoryList: response.data, isLoading: false, showCategoryList: true })
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    loadSubCategoriesForDetectedItem = (subCategory) => {
        
        NewPostService.getCategoryList(subCategory).then(response => {
            if (response.status === 200 && response.data.length > 0) {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    pickerList: response.data,
                    showDetectedCategories: true,
                })
            } else {
                this.setState({
                    ...this.state,
                    pickerList: []
                });
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    handleTappedOnDetectedCategories = (categoryId, categoryName) => {
        
        let tempCateList = [...this.state.tagsData];
        tempCateList[this.state.selectedIndex] = { ...tempCateList[this.state.selectedIndex], categorySelected: categoryId };
        this.setState({
            ...this.state,
            showAutoDetectedList: false,
            isLoading: true,
            showBrandsList: false,        
            showTitle: true,
            titleLabel: `Editing Tag: ${categoryName}`,
            imageTappable: false,
            showSubCategories: false,
            showCategoryList: false,
            showDetectedCategories: false,
            tagsData: tempCateList,
            headerTitle: 'Search for a Brand',
            brandSearch: '',
        })
        this._showBrands = true
        this.getUserSuggestedBrands(categoryId)       
    }
    _renderDetectedCategoryContents = () => {
        
        return this.state.pickerList.map((data, index) => {
            return (
                <div onClick={() => this.handleTappedOnDetectedCategories(data.Id, data.Content)} key={index} style={{ cursor:"pointer", marginBottom: 10, marginTop: 5 , borderRadius: 5 , backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor, marginLeft:7}}>
                    <p style={{color: materialTheme.COLORS.WHITE,padding:10,marginBottom:15 , marginTop:15}}>{data.Content}</p>
                </div>
            )
        })
    }

    _renderDetectedCategoryList = () => {
        
        if (this.state.showDetectedCategories) {
            return (
                <div style={{maxHeight:"550px",overflowY:"scroll"}}>
                    {this._renderDetectedCategoryContents()}
                </div>
            )
        }
    }
    _renderAutoDetectedContents = () => {
        
        return this.state.tagsData.map((val, index) => {
            let categoryName = val.categoryName
            if (categoryName != null) {
                let categoryNameInLower = categoryName.toLowerCase();
                if (val.isConfigured) {
                    return (
                        <div style={{cursor:"pointer"}} key={index} onClick={() => this.handleTappedOnAutoDetectedItem(index)} >
                            <div style={{ backgroundColor: materialTheme.COLORS.STATUS_BAR, borderRadius: 5 }}>
                                <p style={{ color: 'white', padding: 10}}>{categoryName}</p>                               
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div style={{cursor:"pointer"}} key={index} onClick={() => this.handleTappedOnAutoDetectedItem(index)} >
                            <div style={{backgroundColor: materialTheme.COLORS.StrikeColor, borderRadius: 5 }}>
                                <p style={{ color: 'white', padding: 10 }}>{categoryName}</p>                               
                            </div>
                        </div>
                    )
                }
            }
        })
    }

    _renderTags = () => {
        
        return this.state.tagsData.map((data, index) => {
            if (data.showTag) {
                return (
                    <img onError={(e) => this.imageErrorHandler(e)} alt="..." key={index}
                        // resizeMode="contain"
                        src={"/images/tag.png"}
                        style={{ width: 25, height: 25, position: 'absolute', top: data.currentY, left: data.currentX }} />
                )
            }
        })

    }
    _renderAutoDetectedList = () => {
        
        if (this.state.showAutoDetectedList) {
            return (
                <div style={{ marginBottom: 10, marginTop: 5 , paddingLeft:5, paddingRight:5}}>
                    {this._renderAutoDetectedContents()}
                </div>
            );
        }
    }
    _renderSelectedTagsContents = () => {
        
        if (this.state.showAutoDetectedList) {
            return (
                <div>
                    <div>
                        <label style={{ color: materialTheme.COLORS.STATUS_BAR,fontSize:22 , whiteSpace:"nowrap"}}><b>Detected Tags </b>(Click to edit)</label>                     
                    </div>
                    {this._renderAutoDetectedList()}
                </div>
            )
        }
    }
    isValidCategory = (categoryName, scoreConfidence) => {
        let finalCategoryName = categoryName;
        if (scoreConfidence > 0.59 && allValidCategories.includes(categoryName)) {
            if (categoryName == "Sunglasses") {
                finalCategoryName = "Glasses"
            }
            if (categoryName == "Luggage & bags") {
                finalCategoryName = "Bag"
            }
            return finalCategoryName;
        } else {
            return null;
        }
    }
    onAddNewTagPress = () => {
        
        this.setState({
            ...this.state,
            showAutoDetectedList: false,
            showBrandsList: false,
            showCategoryList: false,
            showTitle: true,
            titleLabel: 'Adding a Tag',
            imageTappable: true,
            showManualTaggingMessage: true,
        })
    }
    _renderMessageForManualTagging = () => {
        
        if (this.state.showManualTaggingMessage) {
            return (
                <div style={{ bottom: 100 }}>
                    <p style={{ fontSize: 16, color: materialTheme.COLORS.PrimaryDarkLightColor }}>Please tap anywhere on the image to tag </p>
                </div>
            )
        }
    }
    onUploadTapped = () => {
          
        this.setState({
            ...this.state,
            isPostSubmitting: true
        })
        NewPostService.uploadPostUpdated(this.state.tagsData).then(response => {
            this.setState({
                ...this.state,
                isPostSubmitting: false
            })
            //this.props.closeAll()
            const { history } = this.props;
            history.push({pathname:"/profile", state:{loggedIn: true, newPost: true}})
        }).catch((e) => {
            console.log(e);
        });
    }
    _renderAddATagButtons = () => {
        
        if (!this.state.showTitle && !this.state.isLoading) {
            if (!this.state.isUploadReady) {
                return (                    
                    <button onClick={() => this.onAddNewTagPress()} style={{backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor, color:"white"}} type="button" className="btn">ADD A TAG</button>
                )
            } else {
                return (
                    <div>                                                           
                        <button onClick={() => this.onAddNewTagPress()} style={{backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor, color:"white"}} type="button" className="btn">ADD A TAG</button>
                        <button onClick={() => this.onUploadTapped()} style={{backgroundColor: materialTheme.COLORS.PrimaryDarkLightColor, color:"white", marginLeft:3}} type="button" className="btn">PUBLISH</button>
                    </div>
                )
            }
        }
    }  
    _renderTagListTitle = () => {
        
        if (this.state.showTitle) {
            return (
                <div className="rounded" style={{ backgroundColor: materialTheme.COLORS.ERROR, height: 35,marginTop:12, marginLeft:7,marginRight:7}}>
                    <p style={{ color: 'white', fontWeight: 'bold', fontSize: 22 , display:"inline",whiteSpace:"nowrap"}}>{this.state.titleLabel}</p>
                </div>
            )
        }
    }
    _renderProgressIndicatior = () => {
        
        if (this.state.isLoading) {
            return (                
                <Loader type="Puff"
                color="#00BFFF"
                height={50}
                width={50}
                style={{ marginTop: "50px" }}
            />
            );
        }
    }
    handleTappedOnImage(evt) {
                 
        var rect = evt.target.getBoundingClientRect();
        var x = evt.clientX - rect.left - 10; //x position within the element.
        var y = evt.clientY - rect.top - 10;  //y position within the element
        if (this.state.imageTappable && !this.state.isLoading) {
            let newTaggedData = { "currentX": x, "currentY": y, categorySelected: '', brandSelected: '', brandSelectedurl: '', categoryName: null, isConfigured: false, showTag: true, auto: false }
            let newIndex = this.state.tagsData.length
            if (this.state.mainCategoryList.length == 0) {
                this.setState({
                    ...this.state,
                    selectedIndex: newIndex,
                    tagsData: this.state.tagsData.concat(newTaggedData),
                    imageTappable: false,
                    isLoading: true,
                    showManualTaggingMessage: false,
                    headerTitle: 'Select Category'
                })
                this.getMainCategories()
            } else {
                this.setState({
                    ...this.state,
                    selectedIndex: newIndex,
                    tagsData: this.state.tagsData.concat(newTaggedData),
                    imageTappable: false,
                    showCategoryList: true,
                    showManualTaggingMessage: false,
                    headerTitle: 'Select Category'
                })
            }
        }
    }
    render() {
        
        const image = this.state.files.map(file => (
            <div id="element" key={0} style={{
                position: "relative",
                backgroundImage: `url("${file.preview}")`,
                width: width-2, height: height / 1.81, backgroundColor: '#F7F7F7', borderRadius: 10, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: 'inline-block',
                marginTop: 12
            }}
                onClick={(e) => this.handleTappedOnImage(e)}
            >
                {this._renderTags()}
                <div onClick={(e) => this.resetAll(e)} style={{ position: "absolute", backgroundColor: materialTheme.COLORS.TextColor, width: 40, height: 40, borderRadius: 20, left: (width - 50), top: 370}}>
                <FontAwesomeIcon icon={faRedo} style={{ color: "white", marginTop: "11px" }} />
                </div>
            </div>
        ));
        return (
            <div>
                {/* <Header pageName="newpost" {...this.props} /> */}
                { this.state.showDragDrop ? (
                <section id="uploadimage" className="inner-page" style={{ marginTop: "0px" }}>
                    <Dropzone onDrop={this.onDrop} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p>Drag and drop Image here, or click to select Image <Icon icon="carbon:image-search-alt" width="50" height="50" style={{ marginLeft:0,marginBottom: 0}}  /></p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                {/* <Link style={{backgroundColor:"#0B394F", borderColor:"#0B394F"}} className="btn btn-primary" to="postVideo">Use Video to Capture Image <Icon icon="mdi:video" width="25" height="25" style={{color:"white", marginLeft:0,marginBottom: 0}}  /></Link>                 */}
                </section>
                ):
                (
                <section id="uploadimage" className="inner-page " style={{ marginTop: "0px" }}>
                 <div className="row maxHeightCalculator" style={{}}>
                  <div className="col-md-7">
                  {image}
                  </div>
                  <div className="col-md-3">
                  {this._renderTagListTitle()}
                    {this._renderProgressIndicatior()}
                    {this._renderSelectedTagsContents()}
                    {this._renderAddATagButtons()}
                    {this._renderMainCategory()}
                    {this._renderSubCategories()}
                    {this._renderDetectedCategoryList()}
                    {this._renderBrandList()}
                    {this._renderMessageForManualTagging()}
                  </div>
                 </div>
                </section>
                )}            
            </div>
        );
    }
}

export default ImageNewPostScreen