import React, { Component } from 'react';
import './Stylesheets/Login.css'
import './Stylesheets/common.css'
import AuthService from '../services/auth';
import materialTheme from '../theme/Theme'
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientCode: 0,
            isFormSubmitting: false
        }
        //this.handleChange = this.handleChange.bind(this);
    }
    onPressVerify = (event) => {
        event.preventDefault();    
        const { history } = this.props;
        let querystring = this.props.location.search      
        let partner = querystring.split('$')[1].split('=')[1];
        let componentType = querystring.split('$')[0].split('=')[1];
         if(componentType == ""){
             return
        }               
        if (AuthService.validateClientCode(this.state.clientCode)) {
              
            if (componentType === "forgot_password") {
                history.push({
                    pathname: '/resetpassword'                                                
                });
                //console.log("forgot password component");
                
            } else if (componentType === "register") {
                // navigation.navigate('Registration');
                //console.log("register component");
                this.setState({ ...this.state, isFormSubmitting: true }, () => {
                    AuthService.register(partner).then(response => {
                        if (response.data && response.data[0] && response.data[0].error) {
                            this.setState({ ...this.state, isFormSubmitting: false });
                            setTimeout(() => {
                                this._showAlert("Error", response.data[0].error);
                            }, 200);
                        } else if (response.status !== 200) {
                            this.setState({ ...this.state, isFormSubmitting: false });
                            setTimeout(() => {
                                this._showAlert("Error", "Some error occurred at the server.");
                            }, 200);
                        } else if (response.status === 200 && response.data[0] && response.data[0].AccessToken) {
                            const user = response.data[0]
                            const username = user.phone;
                            AuthService.setUser(user.AccessToken, user.ProfileId, username, user.ProfileName, user.PhotoURL)
                                .then(
                                    ()=>{
                                        AuthService.isDomainPartnerEqual(partner).then(response => {
                                            if(response){
                                                history.push({
                                                    pathname: '/'                                                
                                                })
                                            }
                                            else
                                            {
                                                window.location = "https://" + partner + ".mespoke.com"
                                            }
                                        })
                                    }
                                )//.finally(_ => this.setState({ ...this.state, isFormSubmitting: false }));
                        }
                    }).catch(_ => this._showAlert("Error", "Account creation failed. Please try again."))
                        .finally(_ => this.setState({ ...this.state, isFormSubmitting: false }));
                });
            }
        } else {
            this._showAlert("Hey!", "Verfication code does not match.");
        }
    }

    onPressNotReceiveCode = (e) => {
        e.preventDefault();
        let querystring = this.props.location.search      
        if (querystring.split('$')[0].split('=')[1]== undefined || querystring.split('$')[0].split('=')[1] == "") return;
          let componentType = querystring.split('$')[0].split('=')[1];

        if (componentType === "forgot_password") {
            const { history } = this.props;
            history.push({
                pathname: '/forgotpassword'                                                
            });
        } else if (componentType === "register") {
            const { history } = this.props;
            history.push({
                pathname: '/signup'                                                
            });
        } else {
            return;
        }
    }
    onClientCodeChange = newCode => {
        this.setState({
            ...this.state,
            clientCode: +newCode.target.value
        });
    }
    _showAlert = (title, message) => {
        //alert(title + "-" + message);        
        window.showAlert(title, message)
    }

    render() {       
        return (
            <div>
                <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="verifycodepdiv" className="contact">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 mx-auto" style={{ maxWidth: "500px" }}>
                                <img src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" + localStorage.getItem('logo')} className="img-fluid" alt="" style={{ marginBottom: "10px" }}></img>
                                <form className="php-email-form needs-validation" noValidate="" onSubmit={this.onPressVerify} id="RegistrationForm">
                                    <div className="section-title">
                                        <h3>Verify Code</h3>
                                    </div>
                                    <p style={{ color: "#AAAAAA" }}>We have sent a code to the email address/phone number you provided on the last screen.</p>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="code" id="code" placeholder="Enter the code you received" onChange={this.onClientCodeChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div style={{ padding: "10px" }}>
                                            <a style={{ color: "#AAAAAA" }} id="TermsConditions" onClick={(e)=> this.onPressNotReceiveCode(e)}>I didn't receive a code</a>
                                        </div>
                                        <div>
                                            <button style={{ background: materialTheme.COLORS.StrikeColor }} type="submit">Verify</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    {/* <!-- End Contact Section --> */}
                </main>
            </div>
        );
    }
}
export default Signup;