import { createStore, combineReducers } from 'redux';
import profileReducer from './reducers/profileReducer';
import newPostReducer from './reducers/newPostReducer';
import homeReducer from './reducers/homeReducer';
import discoverReducer from './reducers/discoverReducer';


const rootReducer = combineReducers({
    profileReducer: profileReducer,
    newPostReducer: newPostReducer,
    discoverReducer: discoverReducer,
    homeReducer: homeReducer,
})

export default rootReducer;