import React from 'react'
//import FlatList from 'flatlist-react';
import DiscoverService from '../services/discover'
import Loader from "react-loader-spinner";
//import Loader from "react-loader-spinner";
import { connect } from 'react-redux';
import { addAutoDetectedList, resetTagList, addTag, removeTag, updateTagCategory, updateTagBrand } from '../store/actions/newPost'
import { toggleCancelButtonVisibility, toggleNextButtonVisibility, togglePostButtonVisibility, toggleLookUpBtnVisibility } from '../store/actions/newPost';
import { toggleDetectedListVisibility, toggleCatePickerVisibility, toggleShowTitleVisibility, toggleLookUpButtonTitle } from '../store/actions/newPost';
import { toggleIsImageTappable, changeLookUpButtonType, } from '../store/actions/newPost';
import materialTheme from '../theme/Theme'
import SearchField from 'react-search-field';
import FlatList from 'flatlist-react';
class CategoriesSearchScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            search: '',
            categoryList: [],
            typingTimeout: 0
        }
    }
    updateSearch = (search) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            ...this.state, search, typingTimeout: setTimeout(() => {
                this.getAllCatgories(search);
            }, 1000)
        });
    };
    handleCategoryClick = (category) => {
        this.props.closeModal()
        if(this.props.searchFrom == "categorydetail") 
        {
            this.props.updateCategoriesDetails(category.categoryId,category.name )
        }
        else
        {
            const { history } = this.props;
            history.push({
                pathname: '/categorydetail',
                state: { category: category.name, categoryId: category.categoryId }
            })
        }
    }
    renderCategory = (category, idx) => {    
        return (
            //<li key={idx}>
            //  <b>{person.name} {person.id}</b>
            //</li>
            <div onClick={() => this.handleCategoryClick(category)} key={idx} className="border-bottom changeBackgroundColorOnHover align-self-center" style={{ cursor: "pointer" }}><div className="card-body">{category.name}</div></div>
        );
    }
    renderCategories = (navigation) => {
        //return this.state.categoryList.map((category, index) => {
            return (
                //<TouchableOpacity key={index} onPress={() => this.handleCategoryTapped(category.name, category.categoryId)} style={{ borderBottomColor: 'gray', borderBottomWidth: 1, width: "100%", height: 90, marginTop: 5, marginLeft: "1%", alignItems: 'center', flexDirection: 'row' }}>
                //    <Text style={{ fontSize: 17, color: materialTheme.COLORS.PrimaryDarkTextColor, fontFamily: 'Rubik_Regular' }} >  {category.name}</Text>
                //</TouchableOpacity>
                <FlatList
                    list={this.state.categoryList}
                    renderItem={this.renderCategory}
                    renderWhenEmpty={() => <Loader type="Puff" color="#00BFFF" height={50} width={50} className="d-flex justify-content-center" />}
                />
            )
        //})
    }
    async componentDidMount() {
        this.getAllCatgories(this.state.search);
        //await Font.loadAsync({ Rubik_Regular: require('../../../assets/fonts/Rubik_Regular.ttf') })
    }
    getAllCatgories = (searchText) => {
        //const { route } = this.props
        const categoryID  = this.props.categoryID
        DiscoverService.getSubCategoryList(categoryID, searchText).then(response => {
            if (response.status === 200 && !response.data.error) {
                let categoryList = response.data.map(category => {
                    return {
                        categoryId: category.Id,
                        name: category.name,
                    };
                });
                this.setState({ ...this.state, categoryList });
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    render() {
        return (
            <div>                
                <SearchField
                    placeholder='Search by Categories'
                    onChange={this.updateSearch}
                    classNames="searchInput rounded"
                />
                <div>{this.renderCategories()}</div>
            </div>            
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isImageTaggable: state.newPostReducer.isImageTaggable,
        lookUpButtonType: state.newPostReducer.lookUpButtonType,
        autoDetectedList: state.newPostReducer.autoDetectedList,
        tagsData: state.newPostReducer.tagsData,
        showCancelBtn: state.newPostReducer.showCancelBtn,
        showPostBtn: state.newPostReducer.showPostBtn,
        showNextBtn: state.newPostReducer.showNextBtn,
        showLookUpBrandsButton: state.newPostReducer.showLookUpBrandsButton,
        showAutoDetectedList: state.newPostReducer.showAutoDetectedList,
        showCategoriesPicker: state.newPostReducer.showCategoriesPicker,
        showTitle: state.newPostReducer.showTitle,
        lookUpButtonTitle: state.newPostReducer.lookUpButtonTitle,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addSelectedCategory: (category) => dispatch(updateTagCategory(category)),
        addAutoDetected: (detectedList) => dispatch(addAutoDetectedList(detectedList)),
        addTagData: (data) => dispatch(addTag(data)),
        resetTagData: () => dispatch(resetTagList()),
        removeTagFromList: () => dispatch(removeTag()),
        toggleCancelBtnVisibility: (isVisible) => dispatch(toggleCancelButtonVisibility(isVisible)),
        toggleNextlBtnVisibility: (isVisible) => dispatch(toggleNextButtonVisibility(isVisible)),
        togglePostBtnVisibility: (isVisible) => dispatch(togglePostButtonVisibility(isVisible)),
        toggleLooksUpBtnVisibility: (isVisible) => dispatch(toggleLookUpBtnVisibility(isVisible)),
        toggleAutoDetectedListVisibility: (isVisible) => dispatch(toggleDetectedListVisibility(isVisible)),
        toggleTitleVisibility: (isVisible) => dispatch(toggleShowTitleVisibility(isVisible)),
        toggleCategoryPickerVisibility: (isVisible) => dispatch(toggleCatePickerVisibility(isVisible)),
        toggleLookUpBtnTitle: (title) => dispatch(toggleLookUpButtonTitle(title)),
        toggleLookUpButtonType: (type) => dispatch(changeLookUpButtonType(type)),
        toggleImageTappable: (isTappable) => dispatch(toggleIsImageTappable(isTappable)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSearchScreen);