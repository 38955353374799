import React, { Component } from 'react';
import NotificationService from '../services/notification'
import Loader from "react-loader-spinner";
import materialTheme from '../theme/Theme'
import SinglePost from '../Pages/SinglePostScreen'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Notifications extends React.Component {
    constructor() {
        super();
        this.state = {
            notificationList: []           
        };
    }

    async componentDidMount() {
        this._getNotifications()
    }
    _getNotifications = () => {
        this.setState({ ...this.state, refreshing: true }, () => {
            NotificationService.getNotifications().then(response => {
                if (response.data && response.data[0] && response.data[0].error) {
                  //  console.log("Error", response.data[0].error);
                } else if (response.status !== 200) {
                  //  console.log("Error", "Some error occurred at the server.");
                } else if (response.status === 200 && response.data.length > 0) {
                    let notificationList = response.data.map(notif => {
                        return {
                            key: notif.idnotifications,
                            name: notif.Name,
                            uri: notif.PhotoThumbnailURL,
                            date: notif.PostingDate,
                            type: notif.type,
                            postId: notif.idpost,
                        }
                    });
                    this.setState({ ...this.state, notificationList, refreshing: false });
                } else {
                    this.setState({ ...this.state, notificationList: [], refreshing: false });
                }
            }).catch(console.log);

        })
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg"
    }
    renderPosts = () => {


        // <li className="changeBackgroundColorOnHover">
        // {/* <div className="border-bottom changeBackgroundColorOnHover" key={index} style={{cursor: "pointer" }}>                   
        //     <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={notification.uri} className="border border-dark rounded-circle" key={index}
        //         style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 70, height: 70, borderRadius: 25 }} />
        //     <label style={{ marginLeft: "20px" }}>{notification.name + " " + notification.type} {notification.date} </label>
        // </div> */}
        // {index}


        return (
            this.state.notificationList.map((notification, index) => {                
                return (
                    <li className="changeBackgroundColorOnHover">
                        <div onClick={()=>this.props.openNotificationPost(notification.postId)} className="d-flex border-bottom changeBackgroundColorOnHover" key={index} style={{ cursor: "pointer",marginTop:5,paddingBottom:5,marginLeft:5 }}>
                            <img onError={(e) => this.imageErrorHandler(e)} alt="..." src={notification.uri} className="border border-dark rounded-circle" key={index}
                                style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 42, height: 42, borderRadius: 20 }} />
                            <label style={{marginTop:10,marginLeft:5,fontSize:11}}>{notification.name + " " + notification.type} {notification.date} </label>
                        </div>
                    </li>
                )
            })

        )
    }
    render() {
        if (this.state.notificationList.length == 0) {
            return (
                <ul id="notificationsdiv" style={{ opacity: 1, top: "100%", visibility: "visible" }}>
                    <li className="changeBackgroundColorOnHover">  <Loader type="Puff"
                        color="#00BFFF"
                        height={33}
                        width={33}
                        style={{ margin: "10px" }}
                    /></li>
                </ul>
            )
        }
        else {
            return (
                <ul id="notificationsdiv" style={{ opacity: 1, top: "100%", visibility: "visible",maxHeight:500,overflowY:"scroll",paddingTop:0,width:"300px" }}>
                    {this.renderPosts()}
                </ul>
            )

        }

    }
}


export default Notifications