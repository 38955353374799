import { ADD_USER,ADD_POST, ADD_ALL_PROFILE_POSTS,DELETE_POST,EDIT_PROFILE, ADD_BRANDS, RESET_BRANDS, USER_LOGOUT, ADD_BRANDS_LIST, ADD_PAGE_SIZE } from './types';

export const addUserFollowedBrands = (brands) => (
  {
    type: ADD_BRANDS,
    data: brands
  }
);

export const addPageSize = (pageSize) => (
  {
    type: ADD_PAGE_SIZE,
    data: pageSize
  }
);

export const logout = () => (
  {
    type: USER_LOGOUT,
  }
);

export const resetBrands = (brands) => (
  {
    type: RESET_BRANDS,
    data: brands
  }
);

export const updateUserProfile = (user) => (
  {
    type: EDIT_PROFILE,
    data: user
  }
);
export const addUserProfile = (user) => (
  {
    type: ADD_USER,
    data: user
  }
);

export const addPost = (post) => (
  {
    type: ADD_POST,
    data: post
  }
);

export const addBrandList = (brandList) => (
  {
    type: ADD_BRANDS_LIST,
    data: brandList
  }
);

export const deletePost = (key) => (
  {
    type: DELETE_POST,
    key: key
  }
);
export const addUserPosts = (userPosts) => (
    {
      type: ADD_ALL_PROFILE_POSTS,
      data: userPosts
    }
  );
  