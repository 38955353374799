import axios from 'axios';
import BASE_URL from './apipath'
import { AsyncStorage } from 'AsyncStorage';


const axiosInstance = axios.create({
    baseURL: BASE_URL
});
axiosInstance.interceptors.request.use((config) => {
    let partner = localStorage.getItem("partner")
    if(partner != null && partner != "web"){
        config.url = config.url + "?partner=" + partner+"_db"
        // redirect to 404 if title does not exist
        //config.url = config.url + "?partner=" + partner+"_db"
    }
    return config
 });
export default class HomeService {

    static async getNotificationBadge() {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/notifications/getbadgecount.php", {
                params: {
                    user_id: user.ProfileId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async getPostList(pageNo) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            return axiosInstance.get("/posts/following_updated.php", {
                params: {
                    page: pageNo,
                    ratingProfileId: user.ProfileId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    static async getFollowedBrandPostList(pageNo) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);           
            return axiosInstance.get("/posts/tags_updated.php", {
                params: {
                    page: pageNo,
                    ratingProfileId: user.ProfileId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    static async togglePostSavedStatus(post) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);
            let subUrl = '/closet/save_updated.php';
            if (post.isSaved) {
                subUrl = '/closet/unsave.php';
            }

            return axiosInstance.get(subUrl, {
                params: {
                    postId: post.postId,
                    ratingProfileId: user.ProfileId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }
    static async reportPost(post, reportType) {
        try {
            return axiosInstance.get('/users/posts/report.php', {
                params: {
                    type: reportType,
                    idPost: post.postId,
                    requestingProfileId: post.userProfile.userID,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async shopBrands(postID, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey) {
        try {
            const userString = await AsyncStorage.getItem("user");
            const user = JSON.parse(userString);

            this.registerShopBrands(user.ProfileId, post_owner_id, dictionaryPrimaryKey, tagPrimaryKey).then(_ => {});

            let subUrl = '/closet/shopped.php';

            return axiosInstance.get(subUrl, {
                params: {
                    postId: postID,
                    ratingProfileId: user.ProfileId,
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    static async registerShopBrands(shopper_id, post_owner_id, brand_id, tag_id) {
        try {
            const subUrl = '/closet/registershopped.php';

            let fd = new FormData();
            fd.append("shopper_id", shopper_id);
            fd.append("post_owner_id", post_owner_id);
            fd.append("brand_id", brand_id);
            fd.append("tag_id", tag_id);

            return axiosInstance.post(subUrl, fd);
        } catch (e) {
            console.log(e);
        }
    }
}
