import React from 'react'
import FlatList from 'flatlist-react';
import DiscoverService from '../services/discover'
import Loader from "react-loader-spinner";
import CategorySearch from './CategorySearch'
export default class CategoriesScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            categoryList: [],
            categorySearch: false,
            categoryName: "",
            categoryID: ""

        };
    }
    handleCategoryClick = (category) => {
        this.setState({
            ...this.state,
            categoryName: category.name,
            categoryID: category.Id,
            categorySearch: true
        });
    }
    renderCategory = (category, idx) => {
        return (
            //<li key={idx}>
            //  <b>{person.name} {person.id}</b>
            //</li>
            <div onClick={() => this.handleCategoryClick(category)} key={idx} className="border-bottom changeBackgroundColorOnHover align-self-center" style={{ cursor: "pointer" }}><div className="card-body">{category.name}</div></div>
        );
    }

    async componentDidMount() {
        DiscoverService.getMainCategoryList().then(response => {
            if (response.status === 200 && !response.data[0].error) {
                this.setState({ ...this.state, categoryList: response.data })
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    render() {
        if (this.state.categorySearch) {
            return (
                <div>
                    <CategorySearch categoryName={this.state.categoryName} categoryID={this.state.categoryID} history={this.props.history} closeModal={this.props.closeModal} updateCategoriesDetails={this.props.updateCategoriesDetails} searchFrom={this.props.searchFrom}/>
                </div>
            )
        }
        else {
            return (
                <FlatList
                    list={this.state.categoryList}
                    renderItem={this.renderCategory}
                    renderWhenEmpty={() => <Loader type="Puff" color="#00BFFF" height={50} width={50} className="d-flex justify-content-center" />}
                />
            );
        }
    }
}