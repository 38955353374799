import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AuthService from '../services/auth'
import RewardScreen from './RewardScreen';
import Notifications from './Notifications';
import { getAllBadge, incrementBadgeNo } from '../store/actions/home';
import { connect } from 'react-redux';
import SinglePost from '../Pages/SinglePostScreenModal'
import { Modal } from 'react-bootstrap';
import $ from 'jquery'
import { Icon } from '@iconify/react';
import { Button, Row, Col, Toast } from 'react-bootstrap';
import { onMessageListener } from '../firebase'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigate: false,
            showNotifications: false,
            notficationPostOpen: false,
            notificationPost: [],
            showRewardScreen: false,
            openSubMenue: false
        }
        //let temp = this.props.badgeCount
        //debugger
        this.logout = this.logout.bind(this);
        // onMessageListener().then(payload => {
        //     //console.log("onMessageListener App.js")
        //     debugger
        //     alert(JSON.stringify(payload))            
        //    this.props.addBadgeCount((Number(this.props.badgeCount) + 1))
        //     //console.log(payload);
        //   }).catch(err => console.log('failed: ', err));
        //navigator.serviceWorker.addEventListener("message", (message) => alert(JSON.stringify(message)));      
    }
    componentDidMount() {
        const fthis = this;
        $(document).click(function (e) {
            if ($(e.target).parents(".dropdown").length === 0) {
                fthis.setState({
                    showNotifications: false,                    
                })
            }
        });
    }
    logout() {
        AuthService.logout().then(() => {
            this.setState({
                navigate: true
            })
        });
    }
    navigateProfile() {
        this.setState({
            navigateProfile: true
        })
    };
    toggleNotifications = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        })
        this.props.addBadgeCount(0)
    }
    closeNotificationPost = () => {
        this.setState({
            ...this.setState,
            currentImage: 0,
            notficationPostOpen: false,
        });
    }
    openNotificationPost = (PostId) => {
        let obj = { photo: { PostId: PostId } };
        this.setState({
            ...this.setState,
            notificationPost: obj,
            notficationPostOpen: true,
        });
    }
    toggleRewardScreen = (e) => {
        e.preventDefault()
        this.setState({
            ...this.setState,
            showRewardScreen: !this.state.showRewardScreen,
        });
    }
    toggleSettings = (e) => {
        e.preventDefault()
        this.setState({
            ...this.setState,
            openSubMenue: !this.state.openSubMenue,
        });
    }
    
    onClickMobileView = () => {
        window.openMobileView()
    }
    render() {

        const { navigate } = this.state;
        if (navigate) {
            window.location.href = "/";
        }

        const pageName = this.props.pageName
        let homeicon = ""
        let disconvericon = ""
        let profileicon = ""
        let newposticon = ""
        let notificationIcon = ""
        pageName == "home" ? homeicon = "/images/homeSelected.png" : homeicon = "/images/homeDeselected.png"
        pageName == "discover" ? disconvericon = "/images/discoverSelected.png" : disconvericon = "/images/disoverDeselected.png"
        pageName == "profile" ? profileicon = "/images/profileSelected.png" : profileicon = "/images/profileDeselected.png"
        pageName == "newpost" ? newposticon = "/images/cameraSelected.png" : newposticon = "/images/cameraDeselected.png"
        pageName == "notifications" ? notificationIcon = "/images/notificationsSelected.png" : notificationIcon = "/images/notificationsDeselected.png"
        return (
            <div>
                <div id="headerDesktop">
                    <header style={{ backgroundColor: localStorage.getItem("color") }} id="header" className="fixed-top d-flex align-items-center is-fixed">
                        <div className="container d-flex align-items-center">
                            <Link to="home" id="mainlogo" className="logo me-auto"><img src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" + localStorage.getItem('logo')} alt=""></img></Link>
                            <nav id="navbar" className="navbar order-last order-lg-0">
                                <ul>
                                    <li><Link to="home" className="nav-link scrollto"><img src={homeicon} className="img-fluid iconSize" alt="..." id="homeicon"></img></Link></li>
                                    <li><Link to="discover" className="nav-link scrollto"><img src={disconvericon} className="img-fluid iconSize" alt="..." id="discovericon"></img></Link></li>
                                    <li><Link to="newPost" className="nav-link scrollto" href="#demo"><img src={newposticon} style={{ width: 23 }} className="img-fluid iconSize" alt="..." id="cameraicon"></img></Link></li>
                                    <li><a onClick={(e) => this.toggleRewardScreen(e)}><Icon icon="ri:qr-code-line" width="25" height="25" style={{ color: "#5F7B89", marginBottom: 2 }} /></a></li>
                                    <li onClick={() => this.toggleNotifications()} className="dropdown scrollto openOnClick">
                                        <a>
                                            <span>
                                                <img style={{ marginBottom: "3px" }} src={"/images/notificationsDeselected.png"} className="img-fluid iconSize" alt="..." id="notifications">
                                                </img>
                                            </span>
                                            {this.props.badgeCount > 0 ? (
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ marginTop: 8, marginLeft: 1 }}>
                                                    {this.props.badgeCount}
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            ) : (<div></div>)
                                            }
                                        </a>
                                        {this.state.showNotifications ? (<Notifications openNotificationPost={this.openNotificationPost} />) : (<div></div>)}
                                    </li>
                                    <li className="dropdown scrollto openOnhover"><a><span><img style={{ marginBottom: "3px" }} src={profileicon} className="img-fluid iconSize" alt="..." id="profileIcon"></img></span></a>
                                        <ul>
                                            <li className="changeBackgroundColorOnHover"><Link style={{ textDecoration: "none" }} to={{ pathname: "/profile", state: { loggedIn: true } }}>Profile</Link></li>
                                            <li className="changeBackgroundColorOnHover"><Link style={{ textDecoration: "none" }} to={{ pathname: "/profilesetting" }}>Setting</Link></li>
                                            <li className="changeBackgroundColorOnHover" style={{ cursor: "pointer" }} onClick={() => this.logout()}><a>Logout</a></li>
                                        </ul>
                                    </li>
                                </ul>
                                <i className="bi bi-list mobile-nav-toggle" onClick={() => this.onClickMobileView()}></i>
                            </nav>
                        </div>
                    </header>
                </div>
                <div id="headerMobile">
                    <header style={{ backgroundColor: localStorage.getItem("color") }} id="" className="fixed-top is-fixed">
                        <div className="container">
                            <Link to="home"><img id="mobileLogo" src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" + localStorage.getItem('logo')} alt=""></img></Link>
                        </div>
                    </header>
                    <nav id="" className="fixed-bottom navbar order-last order-lg-0" style={{ backgroundColor: "white", justifyContent: "center" }}>
                        <ul>
                            <li><Link to="home" className="nav-link scrollto" style={{ paddingLeft: 0 }}><img src={homeicon} className="img-fluid iconSizeMobile" alt="..." id="homeicon"></img></Link></li>
                            <li><Link to="discover" className="nav-link scrollto"><img src={disconvericon} className="img-fluid iconSizeMobile" alt="..." id="discovericon"></img></Link></li>
                            <li><Link to="newPost" className="nav-link scrollto" href="#demo"><img src={newposticon} style={{ width: 32 }} className="img-fluid iconSizeMobile" alt="..." id="cameraicon"></img></Link></li>
                            <li><a onClick={(e) => this.toggleRewardScreen(e)}><Icon icon="ri:qr-code-line" width="35" height="32" style={{ color: "#5F7B89", marginBottom: 2 }} /></a></li>
                            <li onClick={() => this.toggleNotifications()} className="scrollto">
                                <Link to="notifications">
                                    <span>
                                        <img style={{ marginBottom: "3px" }} src={notificationIcon} className="img-fluid iconSizeMobile" alt="..." id="notifications">
                                        </img>
                                    </span>
                                    {this.props.badgeCount > 0 ? (
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ marginTop: 8, marginLeft: 1 }}>
                                            {this.props.badgeCount}
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                    ) : (<div></div>)
                                    }
                                </Link>
                                {/* {this.state.showNotifications ? (<Notifications openNotificationPost={this.openNotificationPost} />) : (<div></div>)} */}
                            </li>
                            <li className=""><a onClick={(e) => this.toggleSettings(e)}><span><img style={{ marginBottom: "3px" }} src={profileicon} className="img-fluid iconSizeMobile" alt="..." id="profileIcon"></img></span></a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={() => this.onClickMobileView()}></i>
                    </nav>
                </div>
                <Modal show={this.state.notficationPostOpen} id="singlePostModal">
                    <SinglePost postData={this.state.notificationPost} closeLightbox={this.closeNotificationPost} />
                </Modal>
                <Modal size="" show={this.state.showRewardScreen}>
                    <Modal.Header onClick={(e) => this.toggleRewardScreen(e)}>
                        <Modal.Title>Mespoke Rewards</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll", textAlign: "center" }}>
                        <RewardScreen />
                    </Modal.Body>
                </Modal>
                {
                    this.state.openSubMenue == true ? (
                        <nav id="Subnavbar" className="navbar order-last order-lg-0 navbar-mobile" style={{zIndex:10000}}>
                        <ul>
                            <li><button onClick={(e) => this.toggleSettings(e)} type="button" className="btn-close" data-bs-dismiss="Subnavbar" aria-label="Close"></button></li>
                            <li className="changeBackgroundColorOnHover" onClick={(e) => this.toggleSettings(e)} ><Link style={{ textDecoration: "none" }} to={{ pathname: "/profile", state: { loggedIn: true } }}>Profile</Link></li>
                            <li className="changeBackgroundColorOnHover" onClick={(e) => this.toggleSettings(e)}><Link style={{ textDecoration: "none" }} to={{ pathname: "/profilesetting" }}>Setting</Link></li>
                            <li className="changeBackgroundColorOnHover" style={{ cursor: "pointer" }} onClick={() => this.logout()}><a>Logout</a></li>
                        </ul>
                    </nav>
                    ) : (
                            <div></div>
                    )
                }

            </div>
        )
    }
}
//export default Header;
const mapStateToProps = (state) => {
    return {
        badgeCount: state.homeReducer.badgeCount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBadgeCount: (badgeCount) => dispatch(getAllBadge(badgeCount)),
        incrementBadgeNoByOne: (badgeCount) => dispatch(incrementBadgeNo()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);