import React from 'react'
import UserService from '../../services/user'
import materialTheme from '../../theme/Theme'
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
export default class NameSearchScreen extends React.Component {
    constructor() {
        super();
        this.state = {           
            users: []           
        }
    }

    async componentDidMount() {
         if(this.props.loggedIn) {             
            this.loadFollowingUsers();
         } else {
            this.loadFollowingUsersByID(this.props.userID);
         }       
    }

    loadFollowingUsers = () => {
        UserService.getFollowingUsers().then(response => {
            let users = response.data.map(elem => {
                return {
                    key: elem.ProfileId,
                    name: elem.ProfileName,
                    image: elem.PhotoThumbnailURL.length === 0 || elem.PhotoThumbnailURL == "null" ? "/images/defaultImage.jpg" : elem.PhotoThumbnailURL
                };
            });
            this.setState({ ...this.state, users });
        }).catch(e => console.log(e));
    }
    imageErrorHandler(e) {
        //onError={(e) => this.imageErrorHandler(e)}
        e.currentTarget.src = "/images/defaultImage.jpg" 
      } 
    loadFollowingUsersByID = (userID) => {
        UserService.getFollowingUsersByID(userID).then(response => {
            let users = response.data.map(elem => {
                return {
                    key: elem.ProfileId,
                    name: elem.ProfileName,
                    image: elem.PhotoThumbnailURL.length === 0 || elem.PhotoThumbnailURL == "null" ? "/images/defaultImage.jpg" : elem.PhotoThumbnailURL
                };
            });
            this.setState({ ...this.state, users });
        }).catch(e => console.log(e));
    }
    toProfile=(user)=>{       
        this.props.updateProfile(user.key)        
    }
    renderUsers = () => {
        if(this.state.users.length == 0){
            return ( 
                <div className="d-flex justify-content-center">
<Loader type="Puff"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ marginTop: "10px" }}
        />
                </div>
                           )
        }
        else
        {
        return this.state.users.map((user, index) => {    
            return (
                <div className="border-bottom changeBackgroundColorOnHover" key={index} style={{ borderBottomColor: 'gray', borderBottomWidth: 1, width: "100%", marginBottom: 13, paddingBottom:13, cursor: "pointer" }}>                  
                    <img onError={(e) => this.imageErrorHandler(e)} onClick={()=> this.toProfile(user)} alt="..." src={user.image} className="border border-dark rounded-circle" key={index}
                        style={{ marginHorizontal: 10, borderWidth: 2, borderColor: materialTheme.COLORS.StrikeColor, width: 70, height: 70, borderRadius: 25 }} />                    
                    <label style={{ marginLeft: "20px" }}>{user.name}</label>
                </div>
            )
        })
    }
    }   
   
    render() {
        return (
            <div backgroundColor='white'>              
                <div>{this.renderUsers()}</div>
            </div>
        );
    }
}