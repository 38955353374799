import React, { Component } from 'react';
import './Stylesheets/RegistrationScreen.css'
import { Nav, Tab, } from 'react-bootstrap';
import PhoneNoComponent from '../Components/PhoneNoComponent'
import EmailComponent from '../Components/EmailComponent'
import materialTheme from '../theme/Theme'
import AuthService from '../services/auth'
import './Stylesheets/common.css'
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            fullName: "",
            email: "",
            phoneNumber: "",
            password: "",
            isFormSubmitting: false,
            allPartners:[],
            partner: ""
        }
        //this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        let partner = localStorage.getItem("partner") 
        if(partner == "web"){
            AuthService.getAllPartners().then(response => {
                this.setState({ ...this.state, isFormSubmitting: false });
                setTimeout(() => {
                    if (response.data && response.data[0] && response.data[0].error) {
                        this._showAlert("Error", response.data[0].error);                        
                    } else if (response.status !== 200) {                        
                        this._showAlert("Error", "Some error occured. Pleae try again.");
                    } else if (response.status === 200 && response.data.length > 0) {                        
                        debugger
                        this.setState({
                            ...this.state,
                            partner : response.data[0].Name,
                            allPartners : response.data
                        });
                    }
                }, 100);
            }).catch(error => console.log(error))            
        }
        else
        {
            this.setState({
                ...this.state,
                partner : partner             
            });
        }
    }
    onPressSendCode = (event) => {
        event.preventDefault();
        // console.log(this.state.email)
        // console.log(this.state.fullName)
        // console.log(this.state.password)
        let partner = localStorage.getItem("partner")
        if(partner == "web"){
            if (this.state.partner.localeCompare('') === 0) {
                window.triggerInputValidation("partner", "select Partner")
                //this._showAlert("Hey!", "")
                return;
            }
            else {
                window.triggerInputValidationSuccess("partner")
            }
        }
        if (this.state.fullName.localeCompare('') === 0) {
            window.triggerInputValidation("fullName", "Enter your name first.")
            //this._showAlert("Hey!", "")
            return;
        }
        else {
            window.triggerInputValidationSuccess("fullName")
        }

        if (this.state.selectedIndex === 0 && !this._validateEmail(this.state.email)) {
            //this._showAlert("Hey!", "Enter a valid email address first.")
            window.triggerInputValidation("emailaddress", "Enter a valid email address first.")
            return;
        }
        else {
            window.triggerInputValidationSuccess("emailaddress")
        }

        if (this.state.selectedIndex === 1 && this.state.phoneNumber.localeCompare('') === 0) {
            //this._showAlert("Hey!", "Enter a valid phone number first.")
            window.triggerInputValidationPhone("Enter a valid phone number first.")
            return;
        }
        else {
            window.ttriggerInputValidationSuccessPhone()
        }
        if (this.state.password.localeCompare('') === 0) {
            //this._showAlert("Hey!", "Enter password first.")
            window.triggerInputValidation("password", "Enter password first.")
            return;
        }
        if (this.state.password.trim().length < 6) {
            //this._showAlert("Hey!", "A password must be at least 6 characters long.")
            window.triggerInputValidation("password", "A password must be at least 6 characters long.")
            return;
        }
        else {
            window.triggerInputValidationSuccess("password")
        }
        this.setState({ ...this.state, isFormSubmitting: true }, () => {
            const username = this.state.selectedIndex === 0 ? this.state.email : this.state.phoneNumber;
            //console.log(username)
           // console.log(this.state.fullName)
           // console.log(this.state.password)
            AuthService.verifyRegister(username, this.state.fullName, this.state.password).then(response => {
                this.setState({ ...this.state, isFormSubmitting: false });
                setTimeout(() => {
                    if (response.data && response.data[0] && response.data[0].error) {
                        this._showAlert("Error", response.data[0].error);                        
                    } else if (response.status !== 200) {                        
                        this._showAlert("Error", "Some error occured. Pleae try again.");
                    } else if (response.status === 200 && response.data[0].code) {                        
                        //console.log(response.data[0].code)
                        // this.props.navigation.navigate('VerifyCode', {
                        //     type: "register"
                        // });

                        // const { history } = this.props;
                        // history.push("/verifycode");
                        if(this.state.partner == "Mespoke"){
                            const { history } = this.props;
                            history.push({
                                pathname: '/verifycode',                            
                                search: '?componentType=register'                               
                            });
                        }
                        else
                        {
                            const { history } = this.props;
                            history.push({
                                pathname: '/verifycode',                            
                                search: '?componentType=register$partner=' + this.state.partner                     
                            });
                        }
                        
                    }
                }, 100);
            }).catch(error => console.log(error))
                .finally(_ => this.setState({ ...this.state, isFormSubmitting: false }));
        });
    }
    handleIndexChange = index => {
        this.setState({
            ...this.state,
            selectedIndex: Number(index)
        });
    };
    onNameTextChange = nameText => {
        this.setState({
            ...this.state,
            fullName: nameText.target.value
        });
    }
    onPartnerChange = partner => {
        // chagne is local storage
        this.setState({
            ...this.state,
            partner: partner.target.value
        });
    }
    onEmailTextChange = (emailText) => {
        this.setState({
            ...this.state,
            email: emailText.target.value
        });
    }
    onPhoneTextChange = (value, data, event) => {        
        if (event.type == "click") {
            window.hidePhoneValidation()
        }
        if (value === data.dialCode && event.type != "click") {
            window.triggerInputValidationPhone("Enter a valid phone number first.")
            return
        }
        else {
            this.setState({
                ...this.state,
                //phoneNumber: dialCode.slice(1, dialCode.length) + unmaskedPhoneNumber
                phoneNumber: value
            });
        }

    }
    onPasswordTextChange = (passwordText) => {
        this.setState({
            ...this.state,
            password: passwordText.target.value
        });
    }
    _renderComponent = () => {
        if (this.state.selectedIndex == 0)
            return <EmailComponent onInputChange={this.onEmailTextChange} />
        else
            return <PhoneNoComponent onInputChange={this.onPhoneTextChange} />
    }
    _validateEmail = (inputText) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailformat)) {            
            return true;
        }
        else {
            return false;
        }
    }
    partnerDropdown = () => {
        debugger
        if(this.state.allPartners.length > 0){
            return (
                <div className="form-group">
                <select className="form-select" aria-label="Partner" name="partner" id="partner" value={this.state.partner} onChange={this.onPartnerChange}>     
                {this.state.allPartners.map((option) => (
                  <option value={option.Name}>{option.Name}</option>
                ))}          
                </select>
                    <div className="invalid-feedback">
                    </div>
                </div>            
            )
        }
    }
    _showAlert = (title, message) => {
        //alert(title + "-" + message);        
        window.showAlert(title, message)
    }
    toHome =()=>{
        const { history } = this.props;
        history.push("/");
    }
    render() {
        return (
            <div>
                <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="signupdiv" className="contact">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 mx-auto" style={{ maxWidth: "500px" }}>
                                <img src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" + localStorage.getItem('logo')} onClick={()=>this.toHome()} className="img-fluid" alt="" style={{marginTop:"20px",marginBottom: "20px" , maxHeight:40}}></img>
                                <form className="php-email-form needs-validation" noValidate="" onSubmit={this.onPressSendCode} id="RegistrationForm" autoComplete="off">
                                    <div className="section-title">
                                        <h3>Create An Account</h3>
                                    </div>
                                    <div className="row" id="registrationScreen" style={{ marginBottom: "20px", marginLeft: "50px" }}>
                                        <Tab.Container id="" defaultActiveKey={this.state.selectedIndex} style={{ marginBottom: "10px" }} onSelect={this.handleIndexChange}>
                                            <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="0">Using Email</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1">Using Phone Number</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                    {this.partnerDropdown()}
                                    <div className="form-group">
                                        <input autoComplete="none" type="text" name="fullName" className="form-control" id="fullName" placeholder="Full Name" value={this.state.fullName} onChange={this.onNameTextChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>
                                    {this._renderComponent()}
                                    <div className="form-group">
                                        <input autoComplete="none"  type="password" className="form-control" name="password" id="password" placeholder="Password" onChange={this.onPasswordTextChange}></input>
                                        <div className="invalid-feedback">
                                        </div>
                                    </div>
                                    <div className="text-center" style={{padding:"5px"}}><button style={{ background: materialTheme.COLORS.StrikeColor }} type="submit">SEND CONFORMATION CODE</button></div>
                                </form>
                                <div className="row" >
                                    <div id="ihaveaccount" className="col-md-4" style={{marginLeft:"50px"}}>
                                        <a style={{ color: materialTheme.COLORS.PrimaryDark, whiteSpace:"nowrap" }} id="haveaccount" href="/">I have an account</a>
                                    </div>
                                    <div className="col-md-1">
                                        <p style={{ color: "#ced0d2" }}>|</p>
                                    </div>
                                    <div className="col-md-5">
                                        <a style={{ color: "#AAAAAA" }} id="TermsConditions" href="https://mespoke.cloud/api/termsConditions" target="blank">Terms & Conditions</a>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: "10px", marginTop: "30px" }}>
                                    <div className="col-lg-4 col-md-4 mx-auto">
                                        Get the app.
                                        </div>
                                </div>
                                <div className="row" style={{marginLeft:"40px", marginRight:"40px"}}>
                                    <div className="col-md-6">
                                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.mespoke.app" ><img src={"/images/PlayStore.png"} className="img-fluid getApp" alt="" style={{ marginBottom: "10px" }}></img></a>
                                    </div>
                                    <div className="col-md-6">
                                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/mespoke/id1133070227" ><img src={"/images/AppStore.png"} className="img-fluid getApp" alt="" style={{ marginBottom: "10px" }}></img></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- End Contact Section --> */}
                </main>
            </div>
        );
    }
}
export default Signup;