import { ADD_USER, ADD_POST, ADD_BRANDS_LIST, ADD_ALL_PROFILE_POSTS, DELETE_POST, EDIT_PROFILE, ADD_BRANDS, RESET_BRANDS, USER_LOGOUT, ADD_PAGE_SIZE } from '../actions/types';

const initialState = {
  user: {
    Followers: "-",
    Location: null,
    PhotoThumbnailURL: "/images/defaultImage.jpg",
    Points: "-",
    ProfileId: "-",
    ProfileName: "-",
    Views: "-",
    followStatus: "-",
    saved: "-",
    shopped: "-"
  },
  userPosts: [],
  brandsImages: [],
  pageSize: 21,
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BRANDS_LIST:
      return {
        ...state,
        brandsImages: action.data,
      };
      case ADD_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.data,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        user: action.data,
      };
    case ADD_USER:
      return {
        ...state,
        user: action.data,
      };
    case ADD_POST:
      return {
        ...state,
        userPosts: state.userPosts.concat({
          key: Math.random(),
          name: action.data
        })
      };
    case DELETE_POST:
      return {
        ...state,
        userPosts: state.userPosts.filter((item) =>
          item.PostId !== action.key)
      };
    case ADD_ALL_PROFILE_POSTS:
      return {
        ...state,
        userPosts: action.data,
      }
    case ADD_BRANDS:
      return {
        ...state,
        brandsImages: state.brandsImages.concat({
          image: action.data
        })
      }
    case RESET_BRANDS:
      return {
        ...state,
        brandsImages: action.data,
      }
    case USER_LOGOUT:
      return {
        ...state,
        brandsImages: [],
        userPosts: [],
        user: {},
      }
    default:
      return state;
  }
}

export default profileReducer;