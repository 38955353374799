import { createStore, combineReducers } from 'redux';
import profileReducer from './reducers/profileReducer';
import newPostReducer from './reducers/newPostReducer';
import homeReducer from './reducers/homeReducer';
import discoverReducer from './reducers/discoverReducer';


const rootReducer = combineReducers({
    profileReducer: profileReducer,
    newPostReducer: newPostReducer,
    discoverReducer: discoverReducer,
    homeReducer: homeReducer,
})

var store = undefined;

export default {
    init(configureStore){
        store = configureStore();
    },
    getStore(){
        return store;
    }
};

// export default configureStore;