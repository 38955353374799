import React from 'react';
import materialTheme from '../theme/Theme';
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { Modal } from 'react-bootstrap';
import Onboarding from '../Components/onBoarding';
class EmptyHomePlaceholder extends React.Component {
    constructor() {
        super();
        this.state = {
            onBoarding: true,
            lightboxIsOpen: true,
        };
    }
    closeLightbox = () => {
        this.setState({
            ...this.setState,          
            lightboxIsOpen: false,
        });
    }
    openLightbox = () => {
        this.setState({
            ...this.setState,          
            lightboxIsOpen: true,
        });
    }
    renderonBoarding(){
        return (
            <Modal  size="lg" className="" show={this.state.lightboxIsOpen} id="singlePostModal">                                         
            <Onboarding closeLightbox={this.closeLightbox} />                    
        </Modal>
        );
    }
    render() {
        return (
            <section id="logindiv" className="inner-page">
                <div><label style={{ color: materialTheme.COLORS.TextColor, fontSize: 17, textAlign: 'center', marginTop: 25 }}>You're not following anyone yet, so your feed is empty.</label></div>
                <div><img src={"/images/emptyhome.png"} className="img-fluid emptyhome" alt="..."></img></div>
                <div><label style={{ color: materialTheme.COLORS.SelectedColorPrimary, fontSize: 17 }}>FIND PEOPLE AND BRANDS ON</label></div>
                <div><Link to="discover"><label style={{ color: materialTheme.COLORS.SelectedColorPrimary, fontSize: 15, fontWeight: 'bold', marginTop: 5 }}>OUR DISCOVER PAGE!</label></Link></div>
                <div onClick={()=>this.openLightbox()} style={{float:"left",color: materialTheme.COLORS.PrimaryDark}}><Icon icon="bx:bxs-bulb" width="50" height="50" /></div>
                <div><Link to="discover" style={{ color: materialTheme.COLORS.PrimaryDark,paddingRight: "45px" }}><Icon icon="bx:bxs-search" width="50" height="50" /></Link></div>               
                {this.renderonBoarding()}
            </section>
        );
    }
}
export default EmptyHomePlaceholder;