import {
  ADD_AUTO_DETECTED_LIST, ADD_TAG, UPDATE_TAG_BRAND, UPDATE_TAG_CATE, REMOVE_TAG, RESET_TAG_LIST,
  TOGGLE_CANCEL_BUTTON_VISIBILITY, TOGGLE_NEXT_BUTTON_VISIBILITY, TOGGLE_POST_BUTTON_VISIBILITY,
  TOGGLE_DETECTED_LIST_VISIBILITY, TOGGLE_CATEGORY_PCIKER_VISIBILITY, TOGGLE_LOOKS_UP_BUTTON_VISIBILITY, TOGGLE_SHOW_TITLE_VISIBILITY, CHANGE_LOOK_UP_BUTTON_TITLE,
  TOGGLE_IS_IMAGE_TAPPABLE, CHANGE_LOOK_UP_BUTTON_TYPE, RESET_STATE
} from '../actions/types';

const initialState = {
  autoDetectedList: [],
  tagsData: [],
  isImageTaggable: true,
  lookUpButtonType: 'category',
  showCancelBtn: false,
  showNextBtn: false,
  showPostBtn: false,
  showAutoDetectedList: false,
  showCategoriesPicker: false,
  showLookUpBrandsButton: false,
  showTitle: true,
  lookUpButtonTitle: 'Look up brands',
  tagTitle: 'Looking for items to tag...',
  titleDetected: 'We detected the items below, you can tag more manually!',
  pickerSelectedValue: '',

}

const newPostReducer = (state = initialState, action) => {
  switch (action.type) {

    case RESET_STATE:
      return {
        ...state,
        autoDetectedList: [],
        tagsData: [],
        isImageTaggable: true,
        lookUpButtonType: 'category',
        showCancelBtn: false,
        showNextBtn: false,
        showPostBtn: false,
        showAutoDetectedList: false,
        showCategoriesPicker: false,
        showLookUpBrandsButton: false,
        showTitle: true,
        lookUpButtonTitle: 'Look up brands',
        tagTitle: 'Looking for items to tag...',
        titleDetected: 'We detected the items below, you can tag more manually!',
        pickerSelectedValue: '',
      }

    case TOGGLE_IS_IMAGE_TAPPABLE:
      return {
        ...state,
        isImageTaggable: action.data,
      }

    case CHANGE_LOOK_UP_BUTTON_TYPE:
      return {
        ...state,
        lookUpButtonType: action.data,
      }

    case CHANGE_LOOK_UP_BUTTON_TITLE:
      return {
        ...state,
        lookUpButtonTitle: action.data,
      }

    case TOGGLE_CANCEL_BUTTON_VISIBILITY:
      return {
        ...state,
        showCancelBtn: action.data,
      }

    case TOGGLE_NEXT_BUTTON_VISIBILITY:
      return {
        ...state,
        showNextBtn: action.data,
      }

    case TOGGLE_POST_BUTTON_VISIBILITY:
      return {
        ...state,
        showPostBtn: action.data,
      }

    case TOGGLE_DETECTED_LIST_VISIBILITY:
      return {
        ...state,
        showAutoDetectedList: action.data,
      }

    case TOGGLE_CATEGORY_PCIKER_VISIBILITY:
      return {
        ...state,
        showCategoriesPicker: action.data,
      }

    case TOGGLE_LOOKS_UP_BUTTON_VISIBILITY:
      return {
        ...state,
        showLookUpBrandsButton: action.data,
      }

    case TOGGLE_SHOW_TITLE_VISIBILITY:
      return {
        ...state,
        showTitle: action.data,
      }


    case ADD_AUTO_DETECTED_LIST:
      return {
        ...state,
        autoDetectedList: action.data,
      }
    case ADD_TAG:
      return {
        ...state,
        tagsData: state.tagsData.concat(action.data)
      }
    case REMOVE_TAG:
      return {
        ...state,
        tagsData: state.tagsData.slice(0, -1)
      }
    case RESET_TAG_LIST:
      return {
        ...state,
        tagsData: [],
      }
    case UPDATE_TAG_BRAND:
      let tempBrandList = [...state.tagsData];
      let sizeBrandList = tempBrandList.length
      let indexBrand = sizeBrandList == 0 ? 0 : (sizeBrandList - 1)
      tempBrandList[indexBrand] = { ...tempBrandList[indexBrand], brandSelected: action.data.selectedBrandName, brandSelectedurl: action.data.selectedBrandUrl };
      return {
        ...state,
        tagsData: tempBrandList,
      }
    case UPDATE_TAG_CATE:
      let tempCateList = [...state.tagsData];
      let listSize = tempCateList.length
      let index = listSize == 0 ? 0 : (listSize - 1)
      tempCateList[index] = { ...tempCateList[index], categorySelected: action.data.categoryId };
      return {
        ...state,
        tagsData: tempCateList,
      }
    default:
      return state;
  }
}

export default newPostReducer;