import {
  ADD_AUTO_DETECTED_LIST, ADD_TAG, UPDATE_TAG_BRAND, UPDATE_TAG_CATE, REMOVE_TAG, RESET_TAG_LIST,
  TOGGLE_CANCEL_BUTTON_VISIBILITY, TOGGLE_NEXT_BUTTON_VISIBILITY, TOGGLE_POST_BUTTON_VISIBILITY,
  TOGGLE_DETECTED_LIST_VISIBILITY, TOGGLE_CATEGORY_PCIKER_VISIBILITY, TOGGLE_LOOKS_UP_BUTTON_VISIBILITY,
  TOGGLE_SHOW_TITLE_VISIBILITY, CHANGE_LOOK_UP_BUTTON_TITLE, TOGGLE_IS_IMAGE_TAPPABLE, CHANGE_LOOK_UP_BUTTON_TYPE, RESET_STATE
} from './types';

export const resetState = () => (
  {
    type: RESET_STATE,
  }
);

export const toggleIsImageTappable = (isTappable) => (
  {
    type: TOGGLE_IS_IMAGE_TAPPABLE,
    data: isTappable
  }
);
export const changeLookUpButtonType = (type) => (
  {
    type: CHANGE_LOOK_UP_BUTTON_TYPE,
    data: type
  }
);

export const toggleLookUpButtonTitle = (title) => (
  {
    type: CHANGE_LOOK_UP_BUTTON_TITLE,
    data: title
  }
);
export const toggleCancelButtonVisibility = (isVisible) => (
  {
    type: TOGGLE_CANCEL_BUTTON_VISIBILITY,
    data: isVisible
  }
);
export const toggleNextButtonVisibility = (isVisible) => (
  {
    type: TOGGLE_NEXT_BUTTON_VISIBILITY,
    data: isVisible
  }
);
export const togglePostButtonVisibility = (isVisible) => (
  {
    type: TOGGLE_POST_BUTTON_VISIBILITY,
    data: isVisible
  }
);
export const toggleDetectedListVisibility = (isVisible) => (
  {
    type: TOGGLE_DETECTED_LIST_VISIBILITY,
    data: isVisible
  }
);
export const toggleCatePickerVisibility = (isVisible) => (
  {
    type: TOGGLE_CATEGORY_PCIKER_VISIBILITY,
    data: isVisible
  }
);
export const toggleLookUpBtnVisibility = (isVisible) => (
  {
    type: TOGGLE_LOOKS_UP_BUTTON_VISIBILITY,
    data: isVisible
  }
);
export const toggleShowTitleVisibility = (isVisible) => (
  {
    type: TOGGLE_SHOW_TITLE_VISIBILITY,
    data: isVisible
  }
);

export const addAutoDetectedList = (detectedList) => (
  {
    type: ADD_AUTO_DETECTED_LIST,
    data: detectedList
  }
);
export const addTag = (tag) => (
  {
    type: ADD_TAG,
    data: tag
  }
);
export const updateTagBrand = (brand) => (
  {
    type: UPDATE_TAG_BRAND,
    data: brand
  }
);
export const updateTagCategory = (category) => (
  {
    type: UPDATE_TAG_CATE,
    data: category
  }
);

export const removeTag = () => (
  {
    type: REMOVE_TAG,
  }
);
export const resetTagList = () => (
  {
    type: RESET_TAG_LIST,
  }
);