import React, { Component } from 'react';
import './Stylesheets/common.css'
import { Nav, Tab, } from 'react-bootstrap';
import PhoneNoComponent from '../Components/PhoneNoComponent'
import EmailComponent from '../Components/EmailComponent'
import materialTheme from '../theme/Theme'
import AuthService from '../services/auth'
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            email: "",
            phoneNumber: "",
            isFormSubmitting: false
        }
        //this.handleChange = this.handleChange.bind(this);
    }
    handleIndexChange = index => {
        this.setState({
            ...this.state,
            selectedIndex: index
        });
    };
    onEmailTextChange = (emailText) => {
        this.setState({
            ...this.state,
            email: emailText.target.value
        });
    }

    onPhoneTextChange = (value, data, event) => {        
        if (event.type == "click") {
            window.hidePhoneValidation()
        }
        if (value === data.dialCode && event.type != "click") {
            window.triggerInputValidationPhone("Enter a valid phone number first.")
            return
        }
        else {
            this.setState({
                ...this.state,
                //phoneNumber: dialCode.slice(1, dialCode.length) + unmaskedPhoneNumber
                phoneNumber: value
            });
        }

    }
    

    _renderComponent = () => {
        if (this.state.selectedIndex == 0)
            return <EmailComponent onInputChange={this.onEmailTextChange} />
        else
            return <PhoneNoComponent onInputChange={this.onPhoneTextChange} />
    }

    onPressNext = (event) => {
        event.preventDefault();
       
        var test = this.state.email
        if (this.state.selectedIndex === 0 && !this._validateEmail(this.state.email)) {
            //this._showAlert("Hey!", "Enter a valid email address first.")
            window.triggerInputValidation("emailaddress", "Enter a valid email address first.")
            return;
        }
        else
        {
            window.triggerInputValidationSuccess("emailaddress")
        }

        if (this.state.selectedIndex === 1 && this.state.phoneNumber.localeCompare('') === 0) {
            //this._showAlert("Hey!", "Enter a valid phone number first.")
            window.triggerInputValidationPhone("Enter a valid phone number first.")
            return;
        }
        else
        {
            window.ttriggerInputValidationSuccessPhone()
        }
        this.setState({ ...this.state, isFormSubmitting: true }, () => {

            const username = this.state.selectedIndex === 0 ? this.state.email : this.state.phoneNumber;
            AuthService.forgotPassword(username).then(response => {
                this.setState({ ...this.state, isFormSubmitting: false });
                setTimeout(() => {
                    if (response.data && response.data[0] && response.data[0].error) {
                        this._showAlert("Error", response.data[0].error);
                    } else if (response.status !== 200) {
                        this._showAlert("Error", "Some error occurred at the server.");
                    } else if (response.status === 200 && response.data[0].code) {
                        const { history } = this.props;
                        history.push({
                            pathname: '/verifycode',                            
                            search: '?componentType=forgot_password',
                        });
                    }
                }, 100);

            }).catch(error => console.log(error));
        });
    }
    _validateEmail = (inputText) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailformat)) {
            return true;
        }
        else {
            return false;
        }
    }
    _showAlert = (title, message) => {
        //alert(title + "-" + message);        
        window.showAlert(title, message)
    }
    toHome =()=>{
        const { history } = this.props;
        history.push("/");
    }

    render() {
        return (
            <div>
                <main id="main">
                    {/* <!-- ======= Contact Section ======= --> */}
                    <section id="logindiv" className="contact">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 mx-auto" style={{ maxWidth: "500px" }}>
                                <img src={"https://mespoke.cloud/api/partneradmin/getLogo.php?urlImg=" + localStorage.getItem('logo')} onClick={()=>this.toHome()} className="img-fluid" alt="" style={{ marginBottom: "10px", maxHeight:40, marginTop:"20px" }}></img>
                                <form className="php-email-form needs-validation" noValidate="" onSubmit={this.onPressNext} id="RegistrationForm">
                                    <div className="section-title">
                                        <h3>Forgot Password</h3>
                                    </div>
                                    <div id="forgotPasswordButtons" className="row" style={{ marginBottom: "20px", marginLeft: "50px" }}>
                                        <Tab.Container id="" defaultActiveKey={this.state.selectedIndex} style={{ marginBottom: "10px" }} onSelect={this.handleIndexChange}>
                                            <Nav variant="pills" className="" style={{ paddingRight: "0px" }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="0">Using Email</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1">Using Phone Number</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                    {this._renderComponent()}
                                    <div className="text-center">
                                        <button style={{ background: materialTheme.COLORS.StrikeColor }} type="submit">Next</button></div>
                                </form>
                            </div>
                        </div>
                    </section>
                    {/* <!-- End Contact Section --> */}
                </main>
            </div>
        );
    }
}
export default Signup;