import * as React from 'react';
import materialTheme from "../../theme/Theme";
import UserService from "../../services/user";
import SinglePost from '../SinglePostScreenModal'
import Loader from "react-loader-spinner";
import Header from "../../Components/Header";
import Modal from "react-bootstrap/Modal";
import Gallery from "react-photo-gallery";
import Categories from './Categories'
import BrandSearchScreen from './BrandSearchScreen'

const width = 441;
const height = 956;
const innerHeight = window.innerHeight
const imageHeight = width + 10

export default class ProfileMyCollectionScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            savedPosts: [],
            shoppedBrands: '-',
            taggedBrand: '-',
            taggedCat: '-',
            isTaggedModalVisible: false,
            selectedType: 'brands',
            showPostDetails: false,
            selectedPostID: '',
            isLoading: false,
            lightboxIsOpen: false,
            post: [],
            showHideCategories: false,
            showHideBrands: false,
        };
    }

    async componentDidMount() {
        this.setState({ ...this.state, isLoading: true })
        this._loadCollectionStats(); 
        this._loadMyCollections();
        // 
    }

    _loadMyCollections = () => {
        UserService.getCollections().then(response => {
            if (response.status === 200 && response.data[0] && response.data[0].error) {
                console.log(response.data[0].error)
            } else if (response.status === 200 && response.data.length > 0) {              
                let postList = response.data.map((el, index) => {                   
                    return { PostId: el.postId, src: el.ImageThumbnailUrl, tagsNumber:el.tagsNumber ,height:7, width:6 }                    
                });
                this.setState({ ...this.state, savedPosts: postList ,isLoading:false});
            }
            else
            {
                this.setState({ ...this.state, isLoading:false});
            }
        }).catch(console.log);
    }

    _loadCollectionStats = () => {
        UserService.getCollectionStats().then(response => {
            if (response.status === 200 && response.data[0] && response.data[0].error) {
                console.log(response.data[0].error)
            } else if (response.status === 200 && response.data.length > 0) {               
                response.data.forEach(element => {
                    if (element.shoppedBrands) {
                        let shoppedBrands = element.shoppedBrands;
                        this.setState({ ...this.state, shoppedBrands });
                    } else if (element.taggedBrand) {
                        let taggedBrand = element.taggedBrand;
                        this.setState({ ...this.state, taggedBrand });
                    } else if (element.taggedCat) {
                        let taggedCat = element.taggedCat;
                        this.setState({ ...this.state, taggedCat });
                    }
                });
            }
        }).catch(console.log);
    }

    _renderTaggedItemModal = () => {
        return (
            <Modal
                transparent={false}
                animationType="slide"
                presentationStyle="pageSheet"
                visible={this.state.isTaggedModalVisible}
                onRequestClose={() => this.setState({ isTaggedModalVisible: false })}
            >
                {/* <TaggedItemsScreen  type={this.state.selectedType}/> */}
                {/* <ProfileMyCollectionScreen /> */}
            </Modal>
        )
    }

    _renderShowPostModal = () => {
        if (!this.state.showPostDetails) {
            return
        }
        return (
            <div>
            </div>
        )
    }
    handleModalShowHideCategories() {
        this.setState({ showHideCategories: !this.state.showHideCategories })
    }
    handleModalShowHideBrands() {
        this.setState({ showHideBrands: !this.state.showHideBrands })
    }
    _renderProfileDetail() {
        return (
            <section style={{ backgroundColor: "#fafafa",padding:0 }} className="breadcrumbs">
                <div className="me-auto" style={{overflowX:"hidden"}}>
                    <div className="border-bottom border-danger" style={{ color: "red",fontSize:33 }}>My Collection</div>
                    <div className="row" style={{marginTop:8}}>
                        <div className="col-md-4">
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor, fontSize:20,marginBottom:10 }}>{this.state.shoppedBrands}</p>
                        <p style={{  color: materialTheme.COLORS.PrimaryDarkLightColor }}>Shopped Items</p>
                        </div>
                        <div className="col-md-4" onClick={()=> this.handleModalShowHideBrands()} style={{cursor:"pointer"}}>
                        <p style={{  color: materialTheme.COLORS.PrimaryDarkLightColor,fontSize:20,marginBottom:10 }}>{this.state.taggedBrand}</p>
                        <p style={{  color: materialTheme.COLORS.PrimaryDarkLightColor }}>Tagged Brands</p>
                        </div>
                        <div className="col-md-4"  onClick={()=> this.handleModalShowHideCategories()}  style={{cursor:"pointer"}}>
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor,fontSize:20,marginBottom:10 }}>{this.state.taggedCat}</p>
                        <p style={{ color: materialTheme.COLORS.PrimaryDarkLightColor }} >Tagged Categories</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    openLightbox = (event, obj) => {     
        this.setState({
            ...this.setState,
           currentImage: obj.index,
            post: obj,
            lightboxIsOpen: true,

       });
    }
    closeLightbox = () => {
        this.setState({
            ...this.setState,
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    _renderHomeContents = () => {   
        let postList = this.state.savedPosts;
        const imageRenderer = (
            ({ index, left, top, key, photo }) => (                 
            <div className="rounded border border-dark" style={{margin:5}}>
                 <img          
                 alt="..."       
                 src={photo.src}                
                 index={index}
                 key={key} 
                 style={{maxHeight:250,maxWidth:250}}                                       
                 onClick={(e)=>this.openLightbox(e,{index:index, photo:photo})}           
               />
               <div id="tagscount" style={{height:30,color:"white"}}><p style={{color:"black"}}>{photo.tagsNumber} Tagged items</p></div> 
            </div>   
             
            ));
        if (this.state.isLoading) {
            return (
                <div style={{ height: "350px" }}>
                    <Loader type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        style={{ marginTop: "50px" }}
                    />
                </div>
                // <ActivityIndicator size="large" color="#000000" style={{ position: 'absolute', alignSelf: 'center' }} />
            );
        } else {
                return (                                           
                        <Gallery photos={postList} renderImage={imageRenderer} />                                                                     
                );            
        }
    }
    render() {
        return (
            <div>
                <Header pageName="profile" {...this.props} />
                <div id="collectionScreen" style={{overflowX: "hidden"}} className="contact">
                        <div className="row">
                            {this._renderProfileDetail()}
                        </div>
                    </div>
                <main id="main">                    
                <section id="logindiv" className="contact">
                        <div className="row">
                         {this._renderHomeContents()}
                        </div>
                    </section>
                    <Modal show={this.state.lightboxIsOpen} id="singlePostModal">                                         
                        <SinglePost postData={this.state.post} closeLightbox={this.closeLightbox} />                    
                    </Modal>
                    <Modal size="lg" show={this.state.showHideCategories}>
                    <Modal.Header onClick={() => this.handleModalShowHideCategories()}>
                        <Modal.Title style={{color:"red"}}>Categories</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <Categories history={this.props.history} closeModal={() => this.handleModalShowHideCategories()} updateCategoriesDetails={this.props.updateCategoriesDetails} searchFrom={this.props.searchFrom}/>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.showHideBrands}>
                    <Modal.Header onClick={() => this.handleModalShowHideBrands()}>
                        <Modal.Title style={{color:"red"}}>Brands</Modal.Title>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <BrandSearchScreen history={this.props.history} closeModal={() => this.handleModalShowHideBrands()} updateBandDetails={this.props.updateBandDetails} searchFrom={this.props.searchFrom} />
                    </Modal.Body>
                </Modal>
                </main>
            </div>
        );
    }
}

