import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
class Onboarding extends React.Component {
    constructor() {
        super();
        this.state = {
          user:"",
          userpost:"",
          brandsImages:[]
        };
    }
    async componentDidMount() {
    
    }
    closeModel = () => {
        this.props.closeLightbox()
    }
    render() {
        const responsive = {
            desktop: {
              breakpoint: { max: 2048, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
          }
        return (
            <Carousel            
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              customButtonGroup={<CustomButtonGroup props={this.props}  />}
              containerClass="carousel-container"
              arrows={false}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              >
             <div style={{marginTop:"20px", marginBottom:"60px",marginLeft:"65px"}}>   
              <img className="onBoard" 
                 src={`${process.env.PUBLIC_URL}/images/onBoarding1.png`} 
                  alt="..."
                 style={{height:"550px"}}/>
              </div>
             <div style={{marginTop:"20px",marginBottom:"60px",marginLeft:"65px"}}> 
              <img className="onBoard" 
                 src={`${process.env.PUBLIC_URL}/images/onBoarding2.png`} 
                  alt="..."
                 style={{height:"550px"}}/>
              </div>
              <div style={{marginTop:"20px",marginBottom:"60px",marginLeft:"65px"}}><img className="onBoard" 
                 src={`${process.env.PUBLIC_URL}/images/onBoarding3.png`} 
                  alt="..."
                 style={{height:"550px"}}/>
              </div>
            </Carousel>

        )
    }
} 

const CustomButtonGroup = ({ next, previous, carouselState,props }) => {  
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group" style={{  position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent:"center",
    marginBottom:"10px",
    right:0}} >
      <div style={{marginRight:"20px"}}>
      <button className="btn" onClick={()=>props.closeLightbox()} style={{backgroundColor:"lightgrey"}}>Skip</button>
      </div>
      <div>
          {
              currentSlide != 2 ? <button className="btn" onClick={() => next()} style={{backgroundColor:"lightgrey"}} >Next</button>
              :
              <button className="btn" onClick={()=>props.closeLightbox()} style={{backgroundColor:"lightgrey"}} >Close</button>
          }
      </div>
    </div>
  );
};


export default Onboarding