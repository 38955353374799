// import { exp } from "react-native-reanimated";
// Profile Types Constant
export const ADD_POST = 'ADD_FOOD';
export const DELETE_POST = 'DELETE_FOOD';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const ADD_ALL_PROFILE_POSTS = 'ADD_ALL_PROFILE_POSTS';
export const ADD_USER = 'ADD_USER';
export const ADD_BRANDS = 'ADD_BRANDS';
export const RESET_BRANDS = 'RESET_BRANDS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ADD_BRANDS_LIST = 'ADD_BRANDS_LIST';
export const ADD_PAGE_SIZE = 'ADD_PAGE_SIZE';

// New Post Types Constant
export const ADD_AUTO_DETECTED_LIST = 'ADD_AUTO_DETECTED_LIST';
export const ADD_TAG = 'ADD_TAG';
export const UPDATE_TAG_BRAND = 'UPDATE_TAG_BRAND';
export const UPDATE_TAG_CATE = 'UPDATE_TAG_CATE';
export const REMOVE_TAG = 'REMOVE_TAG';
export const RESET_TAG_LIST = 'RESET_TAG_LIST';
export const TOGGLE_CANCEL_BUTTON_VISIBILITY = 'TOGGLE_CANCEL_BUTTON_VISIBILITY';
export const TOGGLE_NEXT_BUTTON_VISIBILITY = 'TOGGLE_NEXT_BUTTON_VISIBILITY';
export const TOGGLE_POST_BUTTON_VISIBILITY = 'TOGGLE_POST_BUTTON_VISIBILITY';
export const TOGGLE_DETECTED_LIST_VISIBILITY = 'TOGGLE_DETECTED_LIST_VISIBILITY';
export const TOGGLE_CATEGORY_PCIKER_VISIBILITY = 'TOGGLE_CATEGORY_PCIKER_VISIBILITY';
export const TOGGLE_LOOKS_UP_BUTTON_VISIBILITY = 'TOGGLE_LOOKS_UP_BUTTON_VISIBILITY';
export const TOGGLE_SHOW_TITLE_VISIBILITY = 'TOGGLE_SHOW_TITLE_VISIBILITY';
export const CHANGE_LOOK_UP_BUTTON_TITLE = 'CHANGE_LOOK_UP_BUTTON_TITLE';
export const TOGGLE_IS_IMAGE_TAPPABLE = 'TOGGLE_IS_IMAGE_TAPPABLE';
export const CHANGE_LOOK_UP_BUTTON_TYPE = 'CHANGE_LOOK_UP_BUTTON_TYPE';
export const RESET_STATE = 'RESET_STATE';

// New Post Types Constant
export const HOME_ADD_POST_LIST = 'ADD_AUTO_DETECTED_LIST';
export const HOME_ADD_BADGE_COUNT = 'HOME_ADD_BADGE_COUNT';
export const INCREMENT_BADGE_NO = 'INCREMENT_BADGE_NO';

// Discover Screen's Types Constant
export const DISCOVER_ADD_POST_LIST = 'DISCOVER_ADD_POST_LIST';
export const DISCOVER_PageCount = 'DISCOVER_PageCount'
